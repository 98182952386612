import { useField } from 'formik';
import {useEffect, useRef, useState} from 'react';
import cloud from 'images/svg/cloud.svg';
import { toast } from 'react-toastify';


export const DragToUpload = ({
    onChange,
    fileType, // pdf
    message,
    ...props // name
}) => {
    const [field, meta] = useField(props);
    const [active, setActive] = useState(false);
    const drop = useRef(null);
    console.log(field, active)

    const inputAccept = fileType==="pdf" ? "application/pdf"
        : ""

    useEffect(() => {
        drop?.current?.addEventListener('dragover', handleDragOver);
        drop?.current?.addEventListener('drop', handleDrop);
      
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            drop?.current?.removeEventListener('dragover', handleDragOver);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            drop?.current?.removeEventListener('drop', handleDrop);
        };
    });
    
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const {files} = e.dataTransfer;
      
        if (files && files.length===1) {
            checkFile(files[0])
        } else {
            toast.error("You can't upload more than one file!")
        }
    };

    const checkFile = (file) => {
        if(fileType==="pdf"){
            if(file.type==="application/pdf"){
                onChange(file)
            } else {
                toast.error("Only PDF files are allowed!")
            }
        } else {
            onChange(file)
        }
    }

    return(
        <div className='relative'>
            <input 
                name={props.name}
                type="file" 
                id={'input_file'+props.name}
                onChange={(e)=>{
                    checkFile(e.currentTarget.files[0])
                }} 
                accept={inputAccept}
                hidden 
                onFocus={()=>setActive(true)}
            />
            <div 
                className="flex flex-col items-center justify-center border-[1px] border-[#EAECF0] rounded-md p-[20px] cursor-pointer" 
                ref={drop} 
                onClick={()=>document.getElementById('input_file'+props.name).click()}
            >
                <img src={cloud} alt="" className='w-12' />
                <p><span className="font-bold text-[#0046E6] text-base">Click to upload</span> or drag and drop</p>
                <p className="text-sm">{message}</p>
            </div>
            <div className='h-6'/>
            {
                (meta.touched && meta.error) &&
                <p className='text-cc-red-1 text-xs absolute bottom-[7px]'>{meta.error}</p>
            }
        </div>
    )
}