import {Row, Col} from "react-bootstrap";
import Back from "components/shared/back";
import { useNavigate } from "react-router-dom";

import info from "../../../../images/svg/identity/info.svg";
import user_null from "../../../../images/user_null.png";
import { useBilledPageExit } from "hooks/feedback.hook";


export default function DriversLicenseResult () {
    const navigate = useNavigate();
    
    let licenseRes = {};
    try {
        licenseRes = JSON.parse(sessionStorage.getItem('identity'));
    } catch (err) {
        //err
    } 

    useBilledPageExit("Driver's license Verification")

    return(
        <div className="py-[30px] px-10 sm:pl-2.5 sm:pr-[30px]">
            <div style={{margin:"-60px 0 0px"}}>
                <Back to={()=> navigate(-1)} />
            </div>
            <div className="identity_result">
                <div className="identity_result-top">
                    <div
                        style={{
                            backgroundImage: !!user_null ? `url(${user_null})` : "url('')"
                        }}
                        className="w-[130px] h-[130px] mr-[20px] border-[2px] border-white shrink-0 overflow-hidden rounded-full bg-no-repeat bg-center bg-cover md:w-[60px] md:h-[60px]"
                    />
                    <div>
                        <h5>{licenseRes?.name}</h5>
                        <p>License Number: {licenseRes?.drivers_license}</p>
                    </div>
                    <img src={info} alt="" className="identity_result-top_button" />
                </div>
                <hr className="my-[30px]"/>
                <Row>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Date Issued</p>
                            <p className="text2">{licenseRes?.issued_date==="" || licenseRes?.issued_date===null ?"Not available":licenseRes.issued_date}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Date of Birth</p>
                            <p className="text2">{licenseRes?.dob==="" || licenseRes?.dob===null ?"Not available":licenseRes.dob}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Gender</p>
                            <p className="text2">{licenseRes?.gender===""?"Not available":licenseRes.gender}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Phone Number</p>
                            <p className="text2">{licenseRes?.phone==="" || licenseRes?.phone===null ?"Not available":licenseRes.phone}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Email</p>
                            <p className="text3" style={{textTransform:"lowercase"}}>{licenseRes?.email===""||licenseRes?.email===null?"Not available":licenseRes.email}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Nationality</p>
                            <p className="text2">{licenseRes?.nationality===""?"Not available":licenseRes.nationality}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">State Of Origin</p>
                            <p className="text2">{licenseRes?.state_of_origin==="" || licenseRes?.state_of_origin===null ?"Not available":licenseRes.state_of_origin}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">LGA Of Origin</p>
                            <p className="text2">{licenseRes?.lga_of_origin==="" || licenseRes?.lga_of_origin===null ?"Not available":licenseRes.lga_of_origin}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Mother's Name</p>
                            <p className="text2">{licenseRes?.mother_name==="" || licenseRes?.mother_name===null ?"Not available":licenseRes.mother_name}</p>
                        </div>
                    </Col>
                </Row>
                <hr className="my-[30px]"/>
                <Row>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">NIN</p>
                            <p className="text2">{licenseRes?.nin==="" || licenseRes?.nin===null ?"Not available":licenseRes.nin}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Validity</p>
                            <p className="text2">{licenseRes?.validity==="" || licenseRes?.validity===null ?"Not available":licenseRes.validity}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Verification Status</p>
                            <p className="text2">{licenseRes?.verification?.status==="" || licenseRes?.verification?.status===null ?"Not available":licenseRes.verification?.status}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Next Of Kin Phone</p>
                            <p className="text2">{licenseRes?.nok_phone==="" || licenseRes?.nok_phone===null ?"Not available":licenseRes.nok_phone}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Postal Code</p>
                            <p className="text2">{licenseRes?.postal_code==="" || licenseRes?.postal_code===null ?"Not available":licenseRes.postal_code}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Blood Group</p>
                            <p className="text2">{licenseRes?.blood_group==="" || licenseRes?.blood_group===null ?"Not available":licenseRes.blood_group}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Disability</p>
                            <p className="text2">{licenseRes?.disability==="" || licenseRes?.disability===null ?"Not available":licenseRes.disability}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Glasses</p>
                            <p className="text2">{licenseRes?.glasses==="" || licenseRes?.glasses===null ?"Not available":licenseRes.glasses}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Facemark</p>
                            <p className="text2">{licenseRes?.face_mark==="" || licenseRes?.face_mark===null ?"Not available":licenseRes.face_mark}</p>
                        </div>
                    </Col>
                </Row>
                <hr className="my-[30px]"/>
                <Row>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">City</p>
                            <p className="text2">{licenseRes?.city==="" || licenseRes?.city===null ?"Not available":licenseRes.city}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">State</p>
                            <p className="text2">{licenseRes?.state==="" || licenseRes?.state===null ?"Not available":licenseRes.state}</p>
                        </div>
                    </Col>
                    <Col lg={6} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Address</p>
                            <p className="text2">{licenseRes?.address_line1==="" || licenseRes?.address_line1===null ?"Not available":licenseRes.address_line1}</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}