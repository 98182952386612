import clsx from "clsx";
import file from "images/svg/identity/file.svg";
import dwnld from "images/svg/identity/download.svg";

export default function FilePreview ({
    name, additionalName, hideDownload, downloadLink,
    icon=file
}) {

    return (
        <div className="flex items-center gap-1.5 border-[1px] border-[#D0D5DD] rounded p-2.5 mt-[15px]">
            <img src={icon} alt="" />
            <div className="overflow-hidden">
                <h6 className="text-sm">{name}</h6>
                <p className="capitalize text-xs">{additionalName}</p>
            </div>
            <div 
                className={clsx(
                    "ml-auto rounded border-[1px] border-[#D0D5DD] p-1 cursor-pointer shrink-0",
                    hideDownload && "hidden"
                )}
            >
                <a href={downloadLink} download target="_blank" rel="noreferrer">
                    <img src={dwnld} alt="" />
                </a>
            </div>
        </div>
    )
}