import {useNavigate} from "react-router-dom";
import { useState } from "react";
import Profile from "./Profile";
import Contact from "./Contact";
import Document from "./Document";

import initial from "../../../images/svg/compliance/initial.svg";
import current from "../../../images/svg/compliance/current.svg";
import completed from "../../../images/svg/compliance/completed.svg";
import alldone from "../../../images/svg/compliance/alldone.svg";
import { useDispatch, useSelector } from "react-redux";
import { setBusinessData } from "redux/business";


function Compliance ({from}) {
    let history = useNavigate();
    const dispatch = useDispatch();
    const compliance_data = useSelector(state => state?.businessData);
    const isComplied = useSelector(state => state?.businessData?.isComplied);

    const steps = ["contact", "document", "profile"]
    const [currentStep, setCurrentStep] = useState("contact")

    return(
        <div className="mt-[30px]">
            <div className="flex m-auto justify-center">
                <div className="w-[300px] relative flex justify-between">
                    {
                        steps.map((item, idx)=>(
                            <div className="flex flex-col items-center w-[50px]" key={idx}>
                                <img 
                                    onClick={()=>setCurrentStep(item)}
                                    src={
                                        item===currentStep ? current
                                        : (isComplied || false ) ? completed
                                        : initial
                                    } 
                                    alt="compliance"
                                    className="w-[20px] bg-white" 
                                />
                                <p className="capitalize">{item}</p>
                            </div>
                        ))
                    }
                    <div className="border-t-[2px] border-dashed border-t-[#629C66] absolute w-[calc(100%-60px)] top-2.5 left-[30px] -z-[3]" />
                </div>
            </div>
            {
                isComplied ? 
                <div>
                    <div className="mt-[30px] mr-[20px]">
                        <div className="compliance_main text-center">
                            <div>
                                <h2 className="text-lg font-medium">Business is already compliant</h2>
                            </div>
                        </div>
                        { <div className="compliance_alldone"> <img src={alldone} alt="icon" className="block mx-auto" /></div>}
                    </div>
                </div>
                :
                <div className="max-w-[500px] block mx-auto my-10 px-[20px]">
                    {
                        currentStep === "contact" &&
                        <Contact 
                            compliance_data={compliance_data}
                            nextButton={()=>{
                                setCurrentStep("document")
                            }}
                        />
                    }
                    {
                        currentStep === "document" &&
                        <Document
                            compliance_data={compliance_data}
                            prevButton={()=>{
                                setCurrentStep("contact")
                            }} 
                            nextButton={()=>{
                                setCurrentStep("profile")
                            }}
                        />
                    }
                    {
                        currentStep === "profile" &&
                        <Profile 
                            compliance_data={compliance_data}
                            prevButton={()=>{
                                setCurrentStep("document")
                            }} 
                            nextButton={()=>{
                                dispatch(setBusinessData({isComplied: true}))
                                if(from==="pending"){
                                    setTimeout( function (){
                                        history("/")
                                    }, 1500);
                                }
                            }}
                        />
                    }
                </div>
            }
        </div>
    )
    
}

export default Compliance;