import React, { useState } from "react";
import { Steps, StepsProvider } from "react-step-builder";
import AccountDetails from "./AccountDetails";
import SuccessModal from "../SuccessModal";
import ModalLayout from "../../../layout/ModalLayout";
import { currencyFormatter } from "utils/helpers";
import { useFeedbackModal } from "hooks/feedback.hook";

const WithdrawModal = ({ payout_balance, modalOpen, setModalOpen }) => {
  const [amount, setAmount] = useState(0)
  const { showFeedback } = useFeedbackModal()

  return (
    <ModalLayout show={modalOpen} handleClose={setModalOpen} >
      <StepsProvider>
        <Steps>
            <AccountDetails
              payout_balance={payout_balance}
              setAmountRequested={(val)=>setAmount(val)}
            />
            <SuccessModal
              title="Withdrawal successful!"
              message={`${currencyFormatter(amount)} will be transferred into your bank account`}
              onClose={()=>{
                setModalOpen()
                showFeedback("RecovaPRO Payout")
              }}
            />
        </Steps>
      </StepsProvider>
    </ModalLayout>
  );
};

export default React.memo(WithdrawModal);
