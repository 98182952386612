import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Mixpanel } from "lib/mixpanel";
import { RadarService } from "services/radar.services";
import { BillingService } from "services/billing.service";
import ConfirmAction from "components/Modal/ConfirmAction";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { TextField } from "components/Form/TextField";
import { Formik, Form } from 'formik';
import { radarSchema } from "utils/validationSchemas";
import Button from "components/shared/Button";
import {radarDetails} from "redux/radar";
import Table from "components/Table";
import view from "images/svg/income/view.svg";
import ShareConsentLink from "./components/ShareConsentLink";
import { currencyFormatter, handleRequestErrors } from "utils/helpers";


function Radar ({permissionValues}) {
    let history = useNavigate();
    const [radarPrice, setRadarPrice] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [type, setType] = useState("bvn");
    const [formValues, setFormValues] = useState(null);
    const [disableAction, setDisbleAction] = useState(false);
    const b2b_data = useSelector(state => state?.loginData?.data);
    const dispatch = useDispatch();
    const [displayData, setDisplayData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [consentLink, setConsentLink] = useState("");
    const [showConsentModal, setShowConsentModal] = useState(false);
    const [loadingResult, setLoadingResult] = useState(false)


    useEffect(() => {
        let payload = {
            action: type==="mobile_number"?"get borrower connected banks":"radar bvn"
        }
        BillingService.getBillingPricing(payload)
            .then(response=> response.data)
            .then(res => {
                if(res.success === true){
                    setRadarPrice(res.data.price)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                handleRequestErrors(err, true)
            });
    }, [type])

    useEffect(() => {
        setDataLoading(true)
        RadarService.getAllBusinessRecords()
        .then((response) => response.data)
        .then((res) => {
            setDataLoading(false)
            if (res.success === true) {
                const items = res?.data?.sort(function(a,b){
                    return new Date(b.created_at) - new Date(a.created_at);
                })
                setDisplayData(items)
            } else if(res.success === false){
                toast.error(res.message)
            }else {
                toast.error(res.message)
            }
        })
        .catch((err) => {
            setDataLoading(false)
            handleRequestErrors(err)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  
    const radarSearch = () => {
        let payload = {
            type: type,
            [`${type}`]: formValues.radarNumber,
            name: formValues.name,
        }
        RadarService.getConnectedBanks(payload)
            .then((response) => response.data)
            .then((res) => {
                setDisbleAction(false);
                setShowModal(false)
                if (res.success === true) {
                    if(!!res.data.url){
                        setShowConsentModal(true);
                        setConsentLink(res.data.url)
                    } else {
                        toast.success("Radar search successful!!")
                        dispatch(
                            radarDetails({
                                type: type,
                                [`${type}`]: formValues.radarNumber,
                                name: formValues.name,
                                banks: type==="bvn" ? res.data:res.data.banks
                            })
                        )
                        history(`/identity/radar`);
                    }
                }else if (res.status === false) {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setDisbleAction(false);
                setShowModal(false)
                handleRequestErrors(err)
            });
    }
    
    const radarConsentReceived = () => {
        setLoadingResult(true)
        let payload = {
            [`${type}`]: formValues.radarNumber,
        }
        RadarService.getConsentedRadarBanks(payload)
            .then((response) => response.data)
            .then((res) => {
                setShowConsentModal(true);
                if (res.success === true) {
                    toast.success("Radar search successful!!")
                    dispatch(
                        radarDetails({
                            type: type,
                            [`${type}`]: formValues.radarNumber,
                            name: formValues.name,
                            banks: res.data
                        })
                    )
                    history(`/identity/radar`);
                }else if (res.status === false) {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setLoadingResult(false)
                handleRequestErrors(err)
            });
    }

    const initialValues ={
        name: '',
        radarNumber: '',
    }

    return(
        <div>
            <div>
                <p className="text-base font-semibold">See a list of all NUBAN bank accounts linked to your customers’ mobile number or BVN <br/>to boost their loan application</p>
                <div className="max-w-[400px] mt-10" >
                    <div>
                        <div className="flex justify-around">
                            <p 
                                className={clsx(
                                    "w-full text-center text-[1.3em] font-semibold py-2.5 cursor-pointer rounded-tr-2xl rounded-tl-2xl",
                                    type==="bvn" ? "bg-cc-primary text-white relative":"bg-white",
                                )}
                                onClick={()=>setType("bvn")}
                            >
                                BVN
                            </p>
                            {/* <p 
                                className={clsx(
                                    "w-1/2 text-center text-[1.1em] font-semibold py-2.5 cursor-pointer rounded-tr-2xl rounded-tl-2xl",
                                    type==="mobile_number" ? "bg-cc-primary text-white relative":"bg-white",
                                )}
                                onClick={()=>setType("mobile_number")}
                            >
                                Phone number
                            </p> */}
                        </div>
                        <div className="py-[30px] px-[20px] box-border mb-10 shadow-lg rounded-br-[10px] rounded-bl-[10px]">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={radarSchema}
                                onSubmit={(values) => {
                                    if(permissionValues?.includes("edit")){
                                        Mixpanel.track(`Identity Insight (Radar Search) button was clicked`, {
                                            name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
                                            bussinessName: b2b_data?.businessName
                                        });
                                        setShowModal(true)
                                        setFormValues(values)
                                    }else{
                                        toast.error("You are not authorized to perform this action!!!")
                                    }
                                }}
                            >  
                                {(props) => (
                                    <Form>
                                        <TextField
                                            label="Full Name"
                                            name='name'
                                            type='text'
                                            required={true}
                                        />
                                        <TextField
                                            label={type==="mobile_number" ? "Mobile Number":"BVN"}
                                            name='radarNumber'
                                            type='text'
                                            required={true}
                                        />
                                        <Button
                                            isValid={props.isValid && permissionValues?.includes("edit")}
                                            // isLoading={loginLoading}
                                            type="submit"
                                            text="Proceed"
                                            className="mb-[30px] mt-2.5"
                                        />
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
                <div>
                    <h5 className="font-bold text-xl">Radar History</h5>
                    <p>List of previous Radar Checks</p>
                </div>
                <Table
                    tableTitle={"Radar History"}
                    data={displayData}
                    loading={dataLoading}
                    hideSearch={true}
                    tableClassName="xl:min-w-[900px]"
                    rows={[
                        {
                            header:'CUSTOMER NAME',
                            view: (item) => (
                                <p className="py-[10px] pl-[10px] pr-[5px]">
                                    {item?.name || "Not Available"}
                                </p>
                            ),
                        },
                        {
                            header:'TYPE',
                            view: (item) => (
                                <p className="py-[10px] pl-[10px] pr-[5px]">
                                    {item?.type || "Not Available"}
                                </p>
                            ),
                        },
                        {
                            header:'BVN',
                            view: (item) => (
                                <p className="py-[10px] pl-[10px] pr-[5px]">
                                    {item?.bvn || "Not Available"}
                                </p>
                            ),
                        },
                        {
                            header:'PHONE NUMBER',
                            view: (item) => (
                                <p className="py-[10px] pl-[10px] pr-[5px]">
                                    {item?.mobile_number || "Not Available"}
                                </p>
                            ),
                        },
                        {
                            header:'ACTION',
                            view: (item) => (
                                <span>
                                    <button 
                                        className={`${permissionValues?.includes("view")?"bg-cc-green-4":"bg-cc-grey-3"} text-[0.8em] m-2.5 py-[3px] px-2.5 text-white rounded-[4px] flex items-center`}
                                        onClick={()=>{
                                            Mixpanel.track(`View radar identity button was clicked`, {
                                                name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
                                                bussinessName: b2b_data?.businessName
                                            });
                                            dispatch(
                                                radarDetails({
                                                    type: item.type==="BVN"?"bvn":'mobile_number',
                                                    [`${item.type==="BVN"?"bvn":'mobile_number'}`]: item.type==="BVN"? item.bvn:item.mobile_number,
                                                    name: item?.name || "Not Available",
                                                    banks: item.banks
                                                })
                                            );
                                            history(`/identity/radar`);
                                            console.log(item)
                                        }}
                                    >
                                        <img src={view} alt="view" className="w-[15px] mr-[5px]" />
                                        <p>View</p>
                                    </button>
                                </span>
                            ),
                        },
                    ]}
                />
            </div>
            <ShareConsentLink 
                show={showConsentModal}
                onCancel={()=>setShowConsentModal(false)}
                shareLink={consentLink}
                onAction={radarConsentReceived}
                isLoading={loadingResult}
            />
            <ConfirmAction
                show={showModal}
                onCancel={()=>setShowModal(false)}
                onAction={()=>{
                    radarSearch()
                    setDisbleAction(true);
                }}
                loading={disableAction}
                title={`Use Radar?`}
                content={`Using the radar service would attract a charge of ${currencyFormatter(radarPrice)}.`}
            />
        </div>
    )
}

export default Radar;