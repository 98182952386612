import { useField } from 'formik';
import clsx from 'clsx';
import {useState} from 'react';

import notvisible from "images/svg/not-visible.svg";
import visible from "images/svg/visible.svg";


export const TextField = ({
    label, 
    required, 
    floating, 
    extraClassName, 
    labelColor="text-cc-black-4", 
    ...props
}) => {
   const [field, meta] = useField(props);
   const [showPassword, setShowPassword] = useState(false)
   const [active, setActive] = useState(false);
   
    return(
        <div className='relative'>
            <label 
                htmlFor={props.name}
                className={clsx( 
                    "text-base",
                    (active && floating) ? "text-xs top-[4px]":"top-[12px]",
                    floating ? "absolute left-[16px] transition-all" : "font-semibold",
                    labelColor,
                )}
            >
                {label}
                {
                    required &&
                    <span className='text-cc-red-4'>*</span>
                }
            </label>
            <input 
                {...field} 
                {...props} 
                className={clsx(
                    `w-full border-[1px] text-cc-grey-3 mt-1 border-solid py-2 px-3 rounded-[5px] focus:outline-none focus:border-cc-primary mb-6`,
                     meta.touched && meta.error ? 'border-cc-red-4':'border-cc-grey-12',
                     extraClassName,
                )}
                type={
                    props.type === "password" && showPassword
                        ? "text"
                        : props.type
                }
                onFocus={()=>setActive(true)}
                placeholder={floating ? "":props.placeholder }
            />
            {
                props.type==="password" &&
                <img 
                    src={(props.type==="password" && showPassword) ? notvisible:visible} 
                    alt="toggle password" 
                    onClick={()=>setShowPassword(!showPassword)} 
                    className='w-[20px] h-5 absolute bottom-[34px] right-[15px]'
                />
            }
            {
                (meta.touched && meta.error) &&
                <p className='text-cc-red-1 text-xs absolute bottom-[7px]'>{meta.error}</p>
            }
        </div>
    )
}