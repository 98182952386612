import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Table from "components/Table";
import GenerateModal from "components/Modal/GenerateReport";
import ConfirmAction from "components/Modal/ConfirmAction";
import { BillingService } from "services/billing.service";
import { logToBin } from "lib/logs";
import { Mixpanel } from "lib/mixpanel";
import NoCreditData from "components/Modal/NoCreditData";
import { CreditService } from "services/credit.service";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { currencyFormatter, dateFormatter, handleRequestErrors, timeFormatter } from "utils/helpers";

import notvisible from "images/svg/not-visible.svg";
import visible from "images/svg/visible.svg";
import view from "images/svg/income/view.svg";
import reload from "images/svg/income/reload.svg";
import deleteIcon from "images/svg/income/delete.svg";
import deleteIconInactive from "images/svg/income/deleteInactive.svg";


export default function Premium ({regNo, doRegNo, path, setPath, rolePermissions}) {
    let history = useNavigate();
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isReload, setIsReload] = useState("");
    const [searchTerm, setSearchTerm] = useState('');
    const [showNoCredit, setShowNoCredit] = useState(false);
    const [disableAction, setDisbleAction] = useState(false);
    const [insightLoading, setInsightLoading] = useState(true);
    const [price, setPrice] = useState("");
    const [permissionValues, setPermissionValues] = useState([]);
    const b2b_data = useSelector(state => state?.loginData?.data);

    useEffect(() => {
        rolePermissions?.map((item) => {
            let key = Object.keys(item)
            if(key[0] === "home"){
                let values = Object.values(item)
                setPermissionValues(values[0])
            }
            return key
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rolePermissions])

    useEffect(()=>{
        setInsightLoading(true);
        doHistoryFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[path, searchTerm])

    const doHistoryFetch = () => {
        CreditService.getCreditSMEHistory(path)
            .then((response) => response.data)
            .then((res) => {
                setInsightLoading(false);
                if (res.status === true) {
                    const filteredData = res.data?.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
                    filteredData?.sort(function(a,b){
                        return new Date(b.searchedDate) - new Date(a.searchedDate);
                    })
                    setData(filteredData)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setInsightLoading(false)
                setData([])
                if(err.response){
                    toast.error(err.response.data.message);
                    logToBin(err.response.data, window.location.href);
                } else{
                    if(err.message === "timeout of 120000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const removeEmptySpaces = stringVal => {
        return /\s/g.test(stringVal);
      };

    const doSMESubmit = () => {
        if(regNo.toLowerCase().includes('rc') || regNo.toLowerCase().includes('bn')){
            setIsReload('');
            generateAction();
        } else {
            toast.error('Please include the prefix RC or BN in your Registration Number.')
        }
    }

    const generateAction = () => {
        Mixpanel.track(`Credit Insight ${path} generate button was clicked`, {
            name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
            bussinessName: b2b_data?.businessName
        });
        if(permissionValues?.includes("edit")){
            if(removeEmptySpaces(regNo) === true){
                toast.error('remove white space')
            }else{
                setShowModal(true);
            }
        }else{
            toast.error("You are not authorized to perform this action!!!")
        }
    }

    const callCreditService = () => {
        const query = `?business_reg_no=${isReload!==""?isReload:regNo.toUpperCase()}`
        CreditService.getSMEReport(path , query)
            .then((response) => response.data)
            .then((res) => {
                setShowModal(false);
                setDisbleAction(false);
                if (res.status === true) {
                    sessionStorage.setItem( "report", JSON.stringify({ 
                        data: res?.data?.score,
                        name: res?.data?.name,
                        phone: res.data.phone,
                        address: res?.data?.address,
                        dor: res?.data?.dateOfRegistration,
                        businessType: res?.data?.businessType,
                        taxIdentificationNumber: res?.data?.taxIdentificationNumber,
                        email: res?.data?.email,
                        nod: res?.data?.noOfDirectors,
                        regNo: isReload!==""?isReload:regNo
                    })); 
                    toast.success("Request Successful!!!")
                    setTimeout( function (){
                        history(`/credit-insight/business/details/${path}`)
                    }, 100);
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setShowModal(false);
                setDisbleAction(false);
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    if(err.response.status===404){
                        setShowNoCredit(true)
                    } else {
                        toast.error(err.response.data.message);
                    }
                } else{
                    if(err.message === "timeout of 120000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            }); 
    }

    const showCreditFromHistory = (item) => {
        Mixpanel.track(`View credit insight button was clicked`, {
            name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
            bussinessName: b2b_data?.businessName
        });
        if(item?.score){
            sessionStorage.setItem( "report", JSON.stringify({ 
                data: item?.score,
                name: item?.name,
                dor: item?.dateOfRegistration,
                phone: item?.phone,
                address: item?.address,
                dob: item?.dateOfBirth,
                businessType: item?.businessType,
                taxIdentificationNumber: item?.taxIdentificationNumber,
                email: item?.email,
                nod: item?.noOfDirectors,
                regNo: regNo
            })); 
            history(`/credit-insight/business/details/${path}`)
        } else {
            toast.info("Report hasn't been generated for this user")
        }
    }

    const deleteChange = () => {
        let payload = {
            type: path.toLowerCase(),
            business_reg_no: isReload
        }
        CreditService.deleteCreditSMEData(payload)
            .then(response =>response.data)
            .then(res => {
                if(res.status === true){
                    setShowDeleteModal(false);
                    setDisbleAction(false);
                    toast.success("Deleted Successfully!")
                    doHistoryFetch()
                }
            })
            .catch((err) => {
                setShowDeleteModal(false);
                setDisbleAction(false);
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 120000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
      }

    const handleChange = (e) => {
        setSearchTerm(e.target.value)
    }

    useEffect(() => {
        let payload = {
            action: "credit sme premium"
        }
        BillingService.getBillingPricing(payload)
        .then(response=> response.data)
        .then(res => {
            if(res.success === true){
                setPrice(res.data.price)
            } else {
                toast.error(res.message)
            }
        })
        .catch((err) => {
            handleRequestErrors(err, true)
        });
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path])
    
    return (
        <div>
            <div>
                <div>
                    <div className="credit-bvn">
                        <label className="mt-[50px] mb-2.5 text-cc-grey-3">Type in Registration Number</label><br/>
                        <div className="credit-bvn-form">
                            <div>
                                <input type={showPassword?"text":"password"} placeholder="e.g RC123456" defaultValue={regNo} className="credit-bvn-input" onChange={(e)=>doRegNo(e.target.value)} />
                                <img src={showPassword?visible:notvisible} alt="visible" className={showPassword?"visible credit-bvn-input-show":"credit-bvn-input-show notvisible"} onClick={()=>setShowPassword(!showPassword)} />
                            </div>
                            <button className={permissionValues?.includes("edit")?"credit-bvn-btn":'credit-bvn-btnInactive'} onClick={()=>doSMESubmit()}>Generate</button>
                        </div>
                    </div>
                </div>
                <div className="home_main-topText">
                    <h5>History</h5>
                    <div className="home_main-topText-search" >
                        <p>List of previous Credit Insights</p>
                    </div>
                </div>
                <Table
                    tableTitle={"Credit Insights"}
                    searchPlaceHolder={"Search by business name"}
                    onSearchChange={handleChange}
                    data={data}
                    loading={insightLoading}
                    hideSearch={false}
                    trClassName="!border-none"
                    theadClassName="!border-none"
                    tableClassName="bg-[#F9FAFB] 2xl:min-w-[1200px]"
                    rows={[
                        {
                            header:'BUSINESS NAME',
                            view: (item) => (
                                <span className="uppercase py-[10px] pl-[10px] pr-[5px]">
                                    {item?.name}
                                </span>
                            ),
                        },
                        {
                            header:'RC NUMBER',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px] pr-[5px]">
                                    {item?.business_reg_no}
                                </span>
                            ),
                        },
                        {
                            header:'DATE',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px] pr-[5px]">
                                    {item.searchedDate===undefined ? `${dateFormatter(item?.score?.lastUpdatedAt, 'dd/mm/yyyy')}, ${timeFormatter(item?.score?.lastUpdatedAt)}`:`${dateFormatter(item?.searchedDate, 'dd/mm/yyyy')}, ${timeFormatter(item?.searchedDate)}`}
                                </span>
                            ),
                        },
                        {
                            header:'ACTION',
                            view: (item) => (
                                <div className="flex py-2.5">
                                    <button 
                                        className="text-[0.8em] px-1.5 py-2.5 text-white border rounded-[4px] flex items-center justify-center bg-cc-green-4 mr-2.5 w-[80px] h-8" 
                                        onClick={()=>{
                                            showCreditFromHistory(item)
                                        }}
                                    >
                                        <img src={view} alt="view" className="w-[16px] mr-1" />
                                        <p>View</p>
                                    </button>
                                    <button 
                                        className={clsx(
                                            "text-[0.8em] px-1.5 py-2.5 text-white border rounded-[4px] flex items-center justify-center mr-2.5 w-[120px] h-8",
                                            permissionValues?.includes("edit") ? 'bg-cc-primary':'bg-cc-grey-3', 
                                        )}
                                        onClick={()=>{
                                            setIsReload(item.business_reg_no)
                                            setPath(path);
                                            permissionValues?.includes("edit") ? setShowModal(true) : toast.error("You are not authorized to perform this action!!!")
                                        }}
                                    >
                                        <img src={reload} alt="reload" className="w-[16px] mr-1" />
                                        <p>Reload Data</p>
                                    </button>
                                    <img 
                                        className="cursor-pointer"
                                        onClick={()=>{
                                            setIsReload(item.business_reg_no)
                                            setPath(path);
                                            permissionValues?.includes("edit") ? setShowDeleteModal(true) : toast.error("You are not authorized to perform this action!!!")
                                            Mixpanel.track(`Delete credit insight button was clicked`, {
                                                name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
                                                bussinessName: b2b_data?.businessName
                                            });
                                        }} 
                                        src={permissionValues?.includes("edit")?deleteIcon:deleteIconInactive} 
                                        alt="delete" 
                                    />

                                </div>
                            ),
                        },
                    ]}
                />
                <GenerateModal 
                    show={showModal}
                    type={"generate-report"} 
                    cancel={()=>setShowModal(false)} 
                    data={price}
                    doAction={()=>{
                        callCreditService()
                        setDisbleAction(true);
                    }} 
                    reportType={path}
                    message={`Please be informed that this action attracts a charge of ${currencyFormatter(price, 2, "NGN")} from your eWallet. This also include if the assessment is successful or not`}
                    disableAction={disableAction}
                />
                <ConfirmAction
                    show={showDeleteModal}
                    onCancel={()=>setShowDeleteModal(false)}
                    onAction={()=>{
                        deleteChange()
                        setDisbleAction(true);
                    }}
                    type="delete"
                    loading={disableAction}
                    title="Delete Credit Report?"
                    content="Are you sure you want to delete this borrower’s credit record? This is an irreversible action, and would require you to perform a fresh assessment for this borrower."
                />
                <NoCreditData 
                    type={'business'}
                    show={showNoCredit}
                    cancel={()=>setShowNoCredit(false)}
                />
            </div>
        </div>
    )
}