import { APIKit } from "services";
import { getPublicKey } from "utils/helpers";


const timeout = 15000;

export const FeedbackService = {
    getFeedbackLog: (businessId) => {
        return APIKit(timeout).get(`/auth/feedback/${businessId}`, {
            headers: {
                Authorization: `Bearer ${getPublicKey()}`
            }
        })
    },
    
    sendFeedback: (payload) => {
        return APIKit(timeout).post(`/auth/feedback/create`, payload, {
            headers: {
                Authorization: `Bearer ${getPublicKey()}`
            }
        })
    },
}