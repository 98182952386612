import clsx from "clsx";
import { TextArea } from "components/Form/TextArea";
import Button from "components/shared/Button";
import { FormikProvider, useFormik } from "formik";
import ModalLayout from "pages/Dashboard/Recova/layout/ModalLayout";
import { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setTriggerModal, setShow } from "redux/feedback";
import { FeedbackService } from "services/feedback.service";


export default function FeedbackModal () {
    const business_id = useSelector(state => state?.loginData?.data?.user?.businessId);
    const feedback = useSelector(state => state?.feedbackData);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        FeedbackService.getFeedbackLog(business_id)
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true) {
                    if(!!res?.data?.logDate){
                        dispatch(setTriggerModal(
                            calculateDuration(res?.data?.logDate, new Date()) > 3
                        ))
                    } else {
                        dispatch(setTriggerModal(true))
                    }
                }
            })
            .catch(() => {}); 
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function calculateDuration (start, end) {
        const startDate = new Date(start).getTime();
        const endDate = new Date(end).getTime();
        
        const diffTime = Math.abs(endDate - startDate);
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        const diffMonths = diffDays / 30.44;
    
        return diffMonths
    }

    const form = useFormik({
        initialValues: {
            rating: 0,
            comment: "",
        },
        onSubmit: (values) => {
            let payload = JSON.stringify({
                rating: values.rating,
                comment: values.comment,
                businessId: business_id,
                source: feedback?.source,
            })
            setLoading(true)
            FeedbackService.sendFeedback(payload)
                .then((response) => response.data)
                .then((res) => {
                    setLoading(false)
                    dispatch(setShow(false))
                    dispatch(setTriggerModal(false))
                    if (res.success === true) {
                        toast.success("Your feedback has been recorded!")
                    }
                })
                .catch(() => {
                    setLoading(false)
                    dispatch(setShow(false))
                }); 
        }
    })

    return (
        <ModalLayout
            show={feedback?.show}
            handleClose={()=>dispatch(setShow(false))}
        >
            <div>
                <h4 className="text-[24px] font-medium mt-10">Feedback</h4>
                <p>Based on your experience using our Platform, how likely are you to recommend CreditChek to others?</p>
                <FormikProvider value={form}>
                    <form onSubmit={form.handleSubmit} className="box-border mb-8">
                        <div className="flex items-center justify-between mt-6">
                            {
                                [1,2,3,4,5,6,7,8,9,10].map(item => (
                                    <div 
                                        key={item}
                                        className={clsx(
                                            "flex justify-center items-center w-8 h-8 cursor-pointer text-base border-[1px] rounded-md",
                                            form.values.rating===item ? "bg-[#54BD95] border-[#54BD95] text-white":"text-[#1D2939] border-[#1D2939]"
                                        )}
                                        onClick={()=>form.setFieldValue("rating", item)}
                                    >
                                        <p>{item}</p>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="text-xs flex justify-between text-[#7E7E7E]">
                            <p>Very<br/>Unlikely</p>
                            <p className="text-right">Extremely<br/>Likely</p>
                        </div>
                        {
                            !!form.values.rating &&
                            <div>
                                <p className="mt-6 mb-2.5">Thank you for your choice! Please kindly specify reasons for giving us these ratings.</p>
                                <TextArea
                                    name='comment'
                                    placeholder="Comments"
                                />
                                <div className="flex justify-between">
                                    <Button
                                        isValid={true}
                                        type="button"
                                        text="Back"
                                        iconPosition="left"
                                        icon={<FaArrowLeft className="w-[13px] h-[13px] mr-2" />}
                                        className="!w-[60px] bg-white !text-black !px-0"
                                        onClick={()=>dispatch(setShow(false))}
                                    />
                                    <Button
                                        isValid={true}
                                        type="submit"
                                        isLoading={loading}
                                        text="Submit"
                                        className="!w-[120px]"
                                    />
                                </div>
                            </div>
                        }
                    </form>
                </FormikProvider>
            </div>
        </ModalLayout>
    )
}