import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Back from "../../../components/shared/back";
import { Modal } from 'react-bootstrap';
import { WalletService } from "services/wallet.service";
import { useSelector, useDispatch } from "react-redux";
import { setWalletAlert, setWallet } from "redux/wallet";
import { Mixpanel } from "../../../lib/mixpanel";
import { copyText, currencyFormatter, dateFormatter, handleRequestErrors } from "utils/helpers";

import copy from "../../../images/svg/home/copy.svg";
import info from "../../../images/svg/income/info.svg";
import ToggleButton from "components/shared/ToggleButton";
import SetWalletThreshold from "components/Modal/SetWalletThreshold";
import { FaCirclePlus } from "react-icons/fa6";
import { BsXLg } from "react-icons/bs";
import { FormikProvider, useFormik } from "formik";
import { TextField } from "components/Form/TextField";
import Button from "components/shared/Button";
import { DateField } from "components/Form/DateField";
import { LuCalendarDays } from "react-icons/lu";
import { newWalletCreation } from "utils/validationSchemas";
import WalletBox from "./components/WalletBox";
import Status from "components/shared/Status";
import clsx from "clsx";
import TransferInfoModal from "./components/TransferInfoModal";


function Wallet () {
    const [showThresholdModal, setShowThresholdmodal] = useState(false);
    const b2b_data = useSelector(state => state?.loginData?.data)
    const wallet = useSelector(state => state?.walletData?.wallet);
    const dispatch = useDispatch();
    const [showAddAccount, setShowAddAccount] = useState(false)
    const [createLoading, setCreateLoading] = useState(false)
    const [showOneTimeAcc, setShowOneTimeAcc] = useState(false);
    const [dynamicAccNumber, setDynamicAccNumber] = useState("")
    const [showTransferInfo, setShowTransferInfo] = useState(false);

    useEffect(()=>{
        WalletService.getWallet()
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true && res.data !== null ){
                    dispatch(setWallet(res.data))
                }else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                handleRequestErrors(err, true)
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeWalletNotif = () => {
        Mixpanel.track(`Wallet threshold toggle button was clicked`, {
            name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
            bussinessName: b2b_data?.businessName
        })
        dispatch(setWalletAlert(!wallet?.alert))
        WalletService.walletNotifications()
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true) {
                    toast.success(res.message);
                    dispatch(setWalletAlert(res.data.alert))
                } else {
                    toast.error(res.message)
                    dispatch(setWalletAlert(!wallet?.alert))
                }
            })
            .catch((err) => {
                dispatch(setWalletAlert(!wallet?.alert))
                handleRequestErrors(err)
            });
    }

    const form = useFormik({
        initialValues: {
            CAC: '',
            incorporationDate: "",
            directorBVN: "",
        },
        validationSchema:  newWalletCreation, 
        onSubmit: (values) => {
            setCreateLoading(true)
            WalletService.createWallet(JSON.stringify(values))
                .then((response) => response.data)
                .then((res) => {
                    setCreateLoading(false)
                    if (res.success === true){
                        toast.info(res.message)
                        setShowAddAccount(false)
                        window.open(res?.data?.url, "_blank").focus();
                    }else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    setCreateLoading(false)
                    handleRequestErrors(err, true)
                });
        }
    })

    const createDynamicAccount = () => {
        setCreateLoading(true)
        WalletService.createDynamic()
            .then((response) => response.data)
            .then((res) => {
                setCreateLoading(false)
                if (res.success === true){
                    setDynamicAccNumber(res.data.accountNumber)
                    setShowOneTimeAcc(true)
                }else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setCreateLoading(false)
                handleRequestErrors(err, true)
            });
    }

    return(
        <div className="mb-10 mx-10 md:mx-[20px] md:my-4 box-border" >
            <div className="flex justify-between mb-10">
                <div>
                    <Back to={()=>window.history.back()} />
                </div>
                <div className="min-w-[320px] sm:w-full">
                    <WalletBox hideActions hideButton />
                </div>
            </div>
            <div className="grid grid-cols-3 gap-6 mb-10 xl:grid-cols-2 md:!grid-cols-1">
                {
                    wallet?.currency==="naira" ? 
                    <>
                        {
                            !!wallet.vfd_account_number &&
                            <div className="border-[1px] border-solid border-[#D8D8D8] rounded-[10px] p-[20px]">
                                <div className="flex justify-between">
                                    <p className="text-[#667085] m-0">Account Number</p>
                                    {
                                        !wallet?.vfd_released &&
                                        <Status type="fail" text="Inactive" />
                                    }
                                </div>
                                <div className="flex gap-x-[20px] items-center">
                                    <p className="text-[#323232] text-[1.6em] font-semibold m-0">{wallet.vfd_account_number}</p>
                                    {
                                        wallet?.vfd_released &&   
                                        <img src={copy} alt="" onClick={() => copyText(wallet.vfd_account_number, "VFD account number copied!")} className='cursor-pointer' />
                                    }
                                </div>
                                <p className="text-[#667085] m-0">VFD MFB: {wallet.vfd_account_name}</p>
                                {
                                    !wallet?.vfd_released &&
                                    <Button
                                        isValid={true}
                                        type="button"
                                        onClick={()=>{
                                            window.open("https://services.vfdtech.ng/", "_blank").focus();
                                        }}
                                        text="Verify Account"
                                        className="!bg-cc-primary mt-2.5"
                                    />
                                }
                            </div>
                        }
                        {
                            wallet?.create_static &&
                            <div 
                                onClick={()=>setShowAddAccount(true)}
                                className="flex items-center justify-center  min-h-[150px] cursor-pointer gap-x-2.5 border-[1px] border-solid border-[#D8D8D8] rounded-[10px] p-[20px]"
                            >
                                <FaCirclePlus className="text-[#12B76A] w-8 scale-[1.5]" />
                                <p className="text-base">Create New Wallet</p>
                            </div>
                        }
                        {
                            !wallet?.vfd_released &&
                            <div 
                                onClick={()=>{
                                    if(!createLoading){
                                        createDynamicAccount()
                                    }
                                }}
                                className={clsx(
                                    "flex items-center justify-center  min-h-[150px] gap-x-2.5 border-[1px] border-solid border-[#D8D8D8] rounded-[10px] p-[20px]",
                                    createLoading ? "cursor-wait":"cursor-pointer"
                                )}
                            >
                                <FaCirclePlus className="text-[#12B76A] w-8 scale-[1.5]" />
                                <p className="text-base">Generate one-time account</p>
                            </div>
                        }
                    </>
                    :
                    <>
                        <div className="border-[1px] border-solid border-[#D8D8D8] rounded-[10px] p-[20px]">
                            <p className="text-[#667085] m-0">Account Number</p>
                            <div className="flex gap-x-[20px] items-center">
                                <p className="text-[#323232] text-[1.6em] font-semibold m-0">1234567890{wallet.account_number}</p>
                                <img src={copy} alt="" onClick={() => copyText(wallet.account_number, "Account number copied!")} className='cursor-pointer' />
                            </div>
                            <p className="text-[#667085] m-0">VFD MFB: TEST ACCOUNT{wallet.account_name}</p>
                            <Button
                                isValid={true}
                                type="button"
                                onClick={()=>{
                                    setShowTransferInfo(true)
                                }}
                                text="Transfer Information"
                                className="!bg-cc-primary mt-2.5"
                            />
                        </div>
                    </>   
                }
                <div className="border-[1px] border-solid border-[#D8D8D8] rounded-[10px] p-[20px]">
                    <div className="flex justify-between">
                        <div>
                            <p className="text-[#667085] m-0">Wallet Threshold</p>
                            <p className="text-[#0051FF] text-[2em] font-semibold mb-[20px]">{currencyFormatter(wallet.alert_limit)}</p>
                        </div>
                        <ToggleButton
                            checked={wallet.alert}
                            onChange={()=>changeWalletNotif()}
                        />
                    </div>
                    <button className="border-[1px] border-solid border-[#D0D5DD] text-[#344054] py-[7px] px-4 rounded-[7px] bg-white -mt-4" onClick={()=>setShowThresholdmodal(true)}>Set Threshold Amount</button>
                </div>
            </div>
            <div className="mb-[30px] border-[1px] border-solid border-[#D8D8D8] rounded-[10px] p-[20px] bg-[#F4F8FF] font-medium">
                <div className="flex items-center gap-x-1">
                    <img src={info} alt="" className="w-[20px]" />
                    <p className="text-[#667085] m-0">
                        {
                            wallet?.currency==="naira" ? "Wallet Guide"
                            : "Transfer Instructions"
                        }
                    </p>
                </div>
                <p className="text-[#667085] mt-2.5">Minimum fundable amount is {" "}
                    <span style={{color:'#182D56 !important'}}>
                    </span>{currencyFormatter(wallet?.currency==="naira"?10000:500)}.</p>
                {
                    (
                        wallet?.currency==="naira" ?
                        [
                            `Wallet funding from ${currencyFormatter(10000)} will incur a stamp duty charge of ${currencyFormatter(50)}.`,
                            "Please note that your business eWallet fund is a non-refundable, non-transferable deposit.",
                            "Make a bank transfer to the account number above. Your account will be credited once the transfer is successful",
                        ]
                        :
                        [
                            "Payments are typically processed and credited within 24 hours of receipt.",
                            "When making the transfer, include your unique CreditChek Business ID in the transfer details or instructions section to help us match the transfer to your account promptly.",
                            "No additional fees apply to this transaction.",
                            <span>If you need any assistance please reach us at <a href="mailto:hi@creditchek.africa" className="text-cc-primary underline">hi@creditchek.africa</a></span>
                        ]
                    ).map((item, idx)=>(
                        <div className="mt-[10px] flex" key={idx}>
                            <div className="w-2.5 h-2.5 rounded-full bg-[#2BBD35] mt-[5px] mr-[5px] mb-0 ml-0 shrink-0"></div>
                            <p>{item}</p>
                        </div>
                    ))
                }
            </div>
            <SetWalletThreshold
                show={showThresholdModal}
                onClose={()=>setShowThresholdmodal(false)}
                value={wallet?.alert_limit}
            />
            <Modal show={showAddAccount} onHide={()=>setShowAddAccount(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="p-[30px] box-border relative">
                    <BsXLg className="absolute top-4 right-3 w-6 scale-[1.3] cursor-pointer text-[#7E7E7E]" onClick={()=>setShowAddAccount(false)}/>
                    <p className="text-[24px] font-bold text-[#101828] mb-6">Create New Wallet</p>
                    <FormikProvider value={form}>
                        <form onSubmit={form.handleSubmit}>
                            <TextField
                                name='CAC'
                                label="RC/BN"
                                required
                                type='text'
                                placeholder="Enter RC/BN"
                            />
                            <DateField
                                name='incorporationDate'
                                label="Date of Incorporation"
                                required
                                placeholder="dd/mm/yy"
                                calendarIcon={
                                    <LuCalendarDays className="w-[20px] scale-[1.3] text-[#0A0A0A]" />
                                }
                                onChange={(value)=>{
                                    form.setFieldValue("incorporationDate", dateFormatter(value, 'yyyy-mm-dd'))
                                }}
                                value={form?.values?.incorporationDate}
                            />
                            <TextField
                                name='directorBVN'
                                label="BVN"
                                required
                                type='text'
                                placeholder="Enter BVN"
                            />
                            <div className="w-[90%] block mx-auto">
                                <div className="grid grid-cols-2 gap-x-[20px] mb-[10px] mt-2.5">
                                    <Button
                                        isValid={true}
                                        type="button"
                                        text="Back"
                                        onClick={()=>setShowAddAccount(false)}
                                        className="!border-[1px] !border-[#D0D5DD] !bg-white !text-[#0C0C0C]"
                                    />
                                    <Button
                                        isValid={true}
                                        isLoading={createLoading}
                                        type="submit"
                                        text="Proceed"
                                        className="!bg-cc-primary"
                                    />
                                </div>
                            </div>
                        </form>
                    </FormikProvider>
                </div>
            </Modal>

            <Modal show={showOneTimeAcc} onHide={()=>setShowOneTimeAcc(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="p-[30px] box-border relative text-center text-[#101828]">
                    <BsXLg className="absolute top-4 right-3 w-6 scale-[1.3] cursor-pointer text-[#7E7E7E]" onClick={()=>setShowOneTimeAcc(false)}/>
                    <p className="text-[24px] font-bold mt-6">One-time wallet funding Account</p>
                    <div className="flex gap-x-3 items-center justify-center">
                        <p className="text-[40px] font-bold">{dynamicAccNumber||""}</p>
                        <img src={copy} alt="" onClick={() => copyText(dynamicAccNumber, "Account number copied!")} className='cursor-pointer' />
                    </div>
                    <p className="text-[20px]">VFD MFB</p>
                    <div className="mt-6">
                        <Button
                            isValid={true}
                            type="button"
                            fullwidth={true}
                            onClick={()=>setShowOneTimeAcc(false)}
                            text="I have transferred the money"
                            className="!bg-cc-primary mt-2.5"
                        />
                    </div>
                </div>
            </Modal>
            <TransferInfoModal
                show={showTransferInfo}
                handleClose={()=>setShowTransferInfo(false)}
            />
        </div>
    )
}

export default Wallet;