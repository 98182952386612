import {Row, Col} from "react-bootstrap";
import Back from "components/shared/back";
import { useNavigate } from "react-router-dom";

import info from "../../../../images/svg/identity/info.svg";
import { useBilledPageExit } from "hooks/feedback.hook";


export default function BVNResult () {
    const navigate = useNavigate();
    
    let bvnRes = {};
    try {
        bvnRes = JSON.parse(sessionStorage.getItem('identity'));
    } catch (err) {
        //err
    } 

    useBilledPageExit("BVN Verification")

    return(
        <div className="py-[30px] px-10 sm:pl-2.5 sm:pr-[30px]">
            <div style={{margin:"-60px 0 0px"}}>
                <Back to={()=> navigate(-1)} />
            </div>
            <div className="identity_result">
                <div className="identity_result-top">
                    <div
                        style={{
                            backgroundImage: !!bvnRes.photo ? `url(${bvnRes.photo})` : "url('')"
                        }}
                        className="w-[130px] h-[130px] mr-[20px] border-[2px] border-white shrink-0 overflow-hidden rounded-full bg-no-repeat bg-center bg-cover md:w-[60px] md:h-[60px]"
                    />
                    <div>
                        <h5>{bvnRes?.firstName} {bvnRes?.middleName} {bvnRes?.lastName}</h5>
                        <p>BVN: {bvnRes?.bvn}</p>
                    </div>
                    <img src={info} alt="" className="identity_result-top_button" />
                </div>
                <hr className="my-[30px]"/>
                <Row>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">NIN</p>
                            <p className="text2">{bvnRes?.nin===""?"Not available":bvnRes.nin}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Date of Birth</p>
                            <p className="text2">{bvnRes?.dateOfBirth===""?"Not available":bvnRes.dateOfBirth}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Gender</p>
                            <p className="text2">{bvnRes?.gender===""?"Not available":bvnRes.gender}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Marital Status</p>
                            <p className="text2">{bvnRes?.maritalStatus===""?"Not available":bvnRes.maritalStatus}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Nationality</p>
                            <p className="text2">{bvnRes?.nationality===""?"Not available":bvnRes.nationality}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Watchlisted</p>
                            <p className="text2">{bvnRes?.watchListed===""?"Not available":bvnRes.watchListed}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Phone Numbers</p>
                            {   bvnRes?.phones.length>0 ?
                                bvnRes?.phones.map((item)=>(
                                    <p className="text2" key={item} style={{margin: "0"}}>{item}</p>
                                ))
                                :
                                <p className="text2">Not available</p>
                            }
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Email</p>
                            <p className="text3" style={{textTransform:"lowercase"}}>{bvnRes?.email===""?"Not available":bvnRes.email}</p>
                        </div>
                    </Col>
                </Row>
                <hr className="my-[30px]"/>
                <Row>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Registered On</p>
                            <p className="text2">{bvnRes?.registrationDate===""?"Not available":bvnRes.registrationDate}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Original Location</p>
                            <p className="text2">{bvnRes?.lgaOfOrigin===""?"Not available":bvnRes.lgaOfOrigin}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Name On Card</p>
                            <p className="text2">{bvnRes?.nameOnCard===""?"Not available":bvnRes.nameOnCard}</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="identity_result-box1">
                            <p className="text1">Residential Address</p>
                            <p className="text2">{bvnRes?.residentialAddress===""?"Not available":bvnRes.residentialAddress}</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}