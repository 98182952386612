import { FormikProvider, useFormik } from "formik";
import { TextField } from "components/Form/TextField";
import Button from "components/shared/Button";
import { complianceContact } from "utils/validationSchemas";
import { useEffect, useState } from "react";
import { TextArea } from "components/Form/TextArea";
import clsx from "clsx";
import Back from "../../../components/shared/back";
import { IdentityService } from "services/identity.service";
import { useSelector } from "react-redux";
import FormLoader from "./components/FormLoader";


function Contact ({nextButton}) {
    const [isForm, setIsForm] = useState(true)
    const b2b_data = useSelector(state => state?.loginData?.data);
    const [isLoading, setIsLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true)

    useEffect(()=>{
        // setInitialLoading(true)
        IdentityService.getComplianceContact(b2b_data?.businessId||b2b_data?.user?.businessId)
            .then((response) => response.data)
            .then((res) => {
                if(res.status === true){
                    if(res?.data?.complianceContactStatus) {
                        nextButton()
                    }
                    form.setFieldValue("email", res?.data?.email || "")
                    form.setFieldValue("phone", res?.data?.phoneNumber || "")
                    form.setFieldValue("website", res?.data?.website || "")
                    form.setFieldValue("address", res?.data?.address || "")
                    form.setFieldValue("description", res?.data?.description || "")
                    setInitialLoading(false)
                }
            })
            .catch((err) => {setInitialLoading(false)});
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const form = useFormik({
        initialValues: {
            email: "",
            phone: "",
            website: "",
            address: "",
            description: "",
        },
        validationSchema: complianceContact, 
        onSubmit: (values) => {
            setIsForm(false)
        }
    })

    const submitContact = () => {
        const {values} = form
        const payload = JSON.stringify({
            email: values.email,
            phoneNumber: values.phone,
            ...(!!values.website ? {website: values.website}:{}),
            address: values.address,
            description: values.description,
        })
        setIsLoading(true)
        IdentityService.sendComplianceContact(payload)
            .then((response) => response.data)
            .then((res) => {
                setIsLoading(false)
                if(res.status === true){
                    nextButton()
                }
            })
            .catch((err) => {
                setIsLoading(false)
            });
    }

    return(
        <div>
            {
                initialLoading ?
                <FormLoader title="Loading form content" message="Please wait while we load your form content" />
                :
                <div className="box-border">
                    {
                        !isForm &&
                        <div className="-mb-[30px]">
                            <Back to={()=>setIsForm(true)} />
                        </div>
                    }
                    <h5 className="text-xl font-medium mb-6">Contact</h5>
                    {isForm ?
                        <FormikProvider value={form}>
                            <form onSubmit={form.handleSubmit}>
                                <TextField
                                    label="Email Address"
                                    name='email'
                                    type='email'
                                    placeholder="Email Address"
                                    required
                                />
                                <TextField
                                    label="Phone Number"
                                    name='phone'
                                    type='text'
                                    placeholder="Phone Number"
                                    required
                                />
                                <TextField
                                    label="Website"
                                    name='website'
                                    type='text'
                                    placeholder="Website"
                                />
                                <TextField
                                    label="Office Address"
                                    name='address'
                                    type='text'
                                    placeholder="Office Address"
                                    required
                                />
                                <TextArea
                                    name='description'
                                    label="Business Description"
                                    placeholder="Describe the activities of your business"
                                    required
                                />
                                <div className="relative">
                                    <p 
                                        className={clsx(
                                            "text-xs absolute -top-[26px]",
                                            !!form?.errors?.description && "hidden",
                                            form?.values?.description.split(" ").length > 50 && "text-cc-red-1"
                                        )}
                                    >Maximum words {form?.values?.description?.split(" ").length||0}/50</p>
                                </div>
                                <Button
                                    isValid={true}
                                    isLoading={false}
                                    type="submit"
                                    text="Save"
                                    className="mt-10 bg-cc-primary"
                                />
                            </form>
                        </FormikProvider>
                        :
                        <div>
                            <div className="mb-3">
                                <h6 className="text-[#5B5B5B] text-[1em]">Email Address</h6>
                                <p className="text-[#353535]">{form.values.email}</p>
                            </div>
                            <div className="mb-3">
                                <h6 className="text-[#5B5B5B] text-[1em]">Phone Number</h6>
                                <p className="text-[#353535]">{form.values.phone}</p>
                            </div>
                            <div className="mb-3">
                                <h6 className="text-[#5B5B5B] text-[1em]">Website (optional)</h6>
                                <p className="text-[#353535]">{form.values.website}</p>
                            </div>
                            <div className="mb-6">
                                <h6 className="text-[#5B5B5B] text-[1em]">Office Address</h6>
                                <p className="text-[#353535]">{form.values.address}</p>
                            </div>
                            <div className="mb-6">
                                <h6 className="text-[#5B5B5B] text-[1em]">Business Description</h6>
                                <p className="text-[#353535]">{form.values.description}</p>
                            </div>
                            <div className="grid grid-cols-2 gap-6">
                                <Button
                                    isValid={true}
                                    isLoading={false}
                                    type="button"
                                    text="Edit"
                                    className="bg-white !text-[#0C0C0C] !border-[1px] !border-[#D0D5DD]"
                                    onClick={()=>setIsForm(true)}
                                />
                                <Button
                                    isValid={true}
                                    isLoading={isLoading}
                                    type="button"
                                    text="Next"
                                    className="bg-cc-primary"
                                    onClick={()=>submitContact()}
                                />
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}


export default Contact;