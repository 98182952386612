import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { currencyFormatter } from "utils/helpers";

export default function AgreementFile ({data}) {
    const business = useSelector(state => state?.loginData?.data)
    
    return (
        <div className="text-sm">
            <p className="text-center font-bold text-[32px]">Terms & Conditions</p>
            <p className="text-[20px] font-bold my-10">Offer for Creditchek Business Float</p>
            <div className="flex flex-col gap-10">
                <div className="flex flex-col gap-2.5">
                    {
                        [
                            {title: "Date", value: dayjs().format("DD MMMM YYYY")},
                            {title: "Obligor 1 Name", value: `${data?.director1Fullname||""} (${data?.director1Position||""})`},
                            {title: "Obligor 2 Name", value: `${data?.director2Fullname||""} (${data?.director2Position||""})`},
                            {title: "Registered Business Name & RC Number", value: `${business?.businessName||""} (${data?.rcNumber||""})`},
                            {title: "Registered Business Address", value: `${business?.address||""}`},
                        ].map(item => (
                            <p>{item.title}: {item.value}</p>
                        ))
                    }
                </div>
                <div>
                    <p className="mb-2.5">Dear Sir/Ma,</p>
                    <p>We are pleased to advise that the Management of Creditchek Technologies Nigeria Limited has approved the loan facility requested, hence, this offer. This letter outlines the major terms and conditions under which we are willing to make available to you the required amount stated below.</p>
                </div>
                <div className="flex flex-col gap-2.5">
                    {
                        [
                            {title: "Facility Type", value: "Business Loan"},
                            {title: "Facility Amount", value: currencyFormatter(data?.loanAmount||0)},
                            {title: "Purpose", value: "Business"},
                            {title: "Tenure", value: `${data?.loanDuration||""}`},
                            {title: "Moratorium", value: "1 Month"}, // change
                            {title: "Repayment Source", value: "Principal + Interest paid monthly"},
                            {title: "Interest Rate & type", value: "1% fixed interest rate"}, // change
                        ].map(item => (
                            <p>{item.title}: {item.value}</p>
                        ))
                    }
                </div>
                <div className="flex flex-col gap-2.5">
                    <p className="font-bold">Terms of Offer</p>
                    <p>1. Disbursement is subject to {business?.businessName||""} being onboarded on the Creditchek platform and subsequent use for its credit assessment.</p>
                    <p>2. The Obligor will reimburse Creditchek Technologies Nigeria Limited on demand, all expenses (including but not limited to legal and insurance expenses and all taxes Where-so applicable) thereon incurred by Creditchek Technologies Nigeria Limited in Processing this facility and in suing for or recovering any sum due hereunder or otherwise in enforcing or protecting its rights and interests hereunder.</p>
                    <p>3. Creditchek Technologies Nigeria Limited reserves the right to refuse and/or withhold disbursement without notice.</p>
                    <p>4. This Offer is subject to external confirmation and authenticity of all documents submitted for the loan application.</p>
                    <p>5. Where any document submitted by the Obligor is found not to be genuine or unreliable, this offer will automatically terminate</p>
                    <p>6. Pre – liquidation: Obligor consents to the payment of the outstanding principal plus accrued interest to date. (Where management fees, legal fees, quarterly fees and VAT are capitalized on the principal).</p>
                    <p>7. Where the facility is guaranteed by a guarantor, the Obligor gives the lender the right to present statement of account on how the facility has been performing to the guarantor</p>
                </div>
                <div className="flex flex-col gap-2.5">
                    <p className="font-bold">Advance and Repayments</p>
                    <p>8. The Lender agrees to advance the Loan and the Obligor agrees to take the Loan subject to the terms and conditions as set out in this offer letter.</p>
                    <p>9. The Obligor agrees to repay the Loan (principal plus accrued interest) in accordance with the terms and conditions of this offer letter and dedicated repayment schedule as provided herein. The Obligor shall notify the Lender without delay of any changes to the Obligor’s repayment date.</p>
                    <p>10. The Obligor may terminate the offer letter and repay back all or any part of the borrowed amount on any day before the scheduled maturity date, by paying to the Lender, all interest, and other monies then accrued or due under this offer letter including a Pre-liquidation charge.</p>
                    <p>11. Where the Obligor makes a payment under the Loan prior to the scheduled repayment date (the “Payment Due Date”), such payment shall be made at least 3 clear business days prior to the Payment Due Date through bank transfer or deposit into any designated repayment account provided by the Lender. Evidence of such payment shall be communicated to the Lender, within 24 hours following payment, via email at hi@creditchek.africa</p>
                    <p>12. Where the Obligor fails to make a scheduled repayment at least 3 clear business days prior to the Payment Due Date or makes the scheduled repayment 3 clear business days prior to the Payment Due Date but fails to communicate the early repayment to the Lender within 24 hours thereafter, the Lender shall be entitled to present the Obligor’s repayment instrument to 3rd party Banks for processing in line with the relevant applicable clearing guidelines. In such cases, the Obligor will have no recourse against the Lender for presentation of its repayment instrument.</p>
                    <p>13. Where the Obligor’s primary repayment method is post-dated cheque, the clearance period for said payment is 3 clear business days</p>
                </div>
                <div className="flex flex-col gap-2.5">
                    <p className="font-bold">Bulk Repayment</p>
                    <p>14. Where the Obligor makes a part payment of the Loan amount exceeding two monthly repayments, the Obligor agrees that a revised repayment schedule shall become applicable containing the revised tenor and loan amount. Such revised repayment schedule is deemed to be incorporated into this Offer Letter.The revised repayment schedule shall be communicated to the Obligor within a reasonable period of time.</p>
                    <p>15. Where the Obligor makes a part repayment of the Loan amount for one month and not exceeding two months, such payment(s) shall be treated in accordance with the dedicated repayment schedule provided in this Loan offer letter.</p>
                    <p>16. Where the Obligor makes a part repayment of the Loan prior to the scheduled repayment date(the “Payment Due Date”) into any designated repayment account provided by the Lender, evidence of such payment shall be communicated to the Lender, within 24 hours following payment, via email at hi@creditchek.africa</p>
                    <p>17. Where the Obligor’s primary repayment method is post-dated cheque, the Obligor shall be required to communicate this part repayment to the Lender within 24 hours, retrieve earlier post-dated cheques from the Lender and present the Obligor’s preferred repayment instrument for the next Payment Due Date</p>
                </div>
                <div className="flex flex-col gap-2.5">
                    <p className="font-bold">Communication</p>
                    <p>18. Any communications by the Obligor regarding the loan facility shall be made directly to the Lender via e-mail at hi@creditchek.africa or in person at any of the Lender’s branches.</p>
                    <p>19. Any notice to be given to the Obligor by the Lender shall be deemed to have been sufficiently given if sent by electronic mail, communicated via the Obligor’s account officer, delivered by hand or sent by courier service, registered mail to such address provided by the Obligor in writing to the Lender. Any notice sent by hand or by courier shall be deemed to have been given if the party receiving the notice shall have signed an acknowledgement of receipt of same.</p>
                </div>
                <div className="flex flex-col gap-2.5">
                    <p className="font-bold">Interest Rate</p>
                    <p>20. The repayments as calculated in the dedicated repayment schedule, are done on the basis of payment at regular agreed intervals. If the due date for a monthly payment instalment falls on a non-business day, then the repayment may be paid on the next business day or a business day before the non-business day</p>
                    <p>21. The Lender will calculate interest at a fixed rate or reducing rate, meaning that interest is charged on the principal at a fixed rate or on the outstanding balance at a reducing rate.</p>
                    <p>22. This offer is subject to a satisfactory credit report and further verification checks. The Lender reserves the right to withdraw this offer in the event that the results of the credit and verification checks are unsatisfactory.</p>
                    <p>23. The Obligor is permitted to rescind this offer within 7 days from the date of execution of this offer letter, provided the Lender has not disbursed the Facility amount to the Obligor.</p>
                </div>
                <div className="flex flex-col gap-2.5">
                    <p className="font-bold">Representations and Warranties</p>
                    <p>The Obligor(s) represents and warrants that:</p>
                    <p>24. He/she has the right to accept this facility and has taken all necessary actions to authorize the same upon the terms and conditions herein.</p>
                    <p>25. He/she is not in default under any obligation in respect of any borrowed money that the acceptance of this facility will be or result in breach of or default under any provision of any other agreement to which the Obligor is a party.</p>
                    <p>26. The information given to the Lender verbally, via email or other electronic medium or in other forms of writing as well as documents submitted in support of the loan application form is true, accurate and complete. The Obligor is solely responsible for the correctness of such information and the Lender shall have no obligation to verify the authenticity of any documentation provided by the Obligor. The Lender accepts no liability for consequences arising out of any erroneous, incorrect or incomplete information supplied by the Obligor. If the Obligor suspects that there is an error in the information he or she has provided to the Lender, he or she shall advise the Lender accordingly without delay. The Lender will endeavour to correct the error whenever and wherever possible on a 'best effort' basis but shall have no liability arising therefrom.</p>
                    <p>27. He/she shall complete his/her repayment instrument(s)(that is cheque, direct debit mandate) with true, accurate, up to date and complete personal information and append his / her signature prior to submission to an authorised representative of the Lender. In the event the Obligor’s personal information, including phone number and address, changes the Obligor is required to inform the Lender of such change.</p>
                    <p>28. Under no circumstance will the Lender be liable for any loss, expense or damage of any kind incurred by the Obligor due to noncompliance with this requirement.</p>
                    <p>29. The Obligor hereby represents that he/she is a director or appointed by a director of the company. Should there at any relevant time(before or after the approval and disbursement of the Loan) be a change in the Obligor's status, the Obligor shall notify the Lender immediately and without delay of such change, which may be in the form of termination or resignation of employment.</p>
                </div>
                <div className="flex flex-col gap-2.5">
                    <p className="font-bold">Events of Default</p>
                    <p>The occurrence of any of the following events shall cause all outstanding amounts under this facility to become immediately due and payable:</p>
                    <p>30. The Obligor fails to make a repayment or payment of principal, interest or other amounts in respect of the Loan on the date it was due to be paid.</p>
                    <p>31. If the Obligor defaults in the performance or the observance of any term or/and conditions here-above stated;</p>
                    <p>32. Where Creditchek Technologies Nigeria Limited gives notice of said breach/default to the borrower and said breach/default continues and remains unalleviated after seven(7) days notice had been given to it, or</p>
                    <p>33. Where the repayment of the facility is not discharged as at when due(with reference to the monthly instalment payment and other outstanding amounts applicable to the facility), Creditchek Technologies Nigeria Limited has the right to upload the customer’s data as delinquent on the Credit bureau.</p>
                    <p>34. Returned cheques/failed Direct Debit shall attract an additional charge of =N= 4,000.00 per return.</p>
                    <p>35. Should any of the rentals remain unpaid after 3 days for any reason whatsoever, a penalty equal to 0.13% of the amount of the rental shall be payable for each day of the period that the rental remained unpaid</p>
                    <p>36. Subject to the Lender’s prior approval any rental payment may be deferred to a future date or restructured subject to the Obligor’s payment of deferment/restructure and moratorium charge of 0.11% and 0.12% respectively of the rental amount per day. Such deferred rental shall be repaid immediately after the tenor is approved.</p>
                    <p>37. The Obligor breaches any of the terms and conditions of the Loan including any representation or confirmation given by the Obligor in this loan agreement.</p>
                    <p>38. Where a bankruptcy petition is filed against the Obligor.</p>
                    <p>39. Where fraud is detected at any time during the lifespan of the facility.</p>
                    <p>40. Where a situation arises, which in the Lender’s opinion makes it inappropriate to continue to extend the facility to the Obligor.</p>
                </div>
                <div className="flex flex-col gap-2.5">
                    <p className="font-bold">Assignment and Disclosure of Information</p>
                    <p>41. The Obligor consents irrevocably to any future transfer and assignment, however arising, of the loan, whether as part of a Loan transfer scheme or otherwise.</p>
                    <p>42. The Obligor authorizes the Lender to disclose any information or documentation relating to the Loan to third parties including credit reference agencies, collection agencies, law enforcement agencies including but not limited to the Economic and Financial Crimes Commission, the Special Fraud Unit of the Nigerian Police Force, and where the Obligor is in salaried employment, the Obligor’s employers.</p>
                </div>
                <div className="flex flex-col gap-2.5">
                    <p className="font-bold">Variation of Conditions</p>
                    <p>43. The Lender reserves the right at all times to vary the terms and conditions of the Loan agreement. Any such variation will become effective upon notice to the Obligor by any means the Lender considers reasonable in the circumstance.</p>
                    <p>44. In the event that the Lender varies the rate of interest payable on the Facility Amount, the Obligor shall be duly notified ten(10) days prior to the implementation of said variation.</p>
                </div>
                <div className="flex flex-col gap-2.5">
                    <p className="font-bold">Set- Off</p>
                    <p>45. By signing this offer letter/ loan agreement and by drawing on the loan, the promoters, signatories, Obligors, and directors of this business covenant to repay the loan as and when due. In the event that i/we fail to repay the loan as agreed, and the loan becomes delinquent, the Lender shall have the right to report the delinquent loan to the Central Bank of Nigeria(“CBN”) through the Credit Risk Management System (“CRMS”) or by any other means, and request the CBN exercise its regulatory power to direct all banks and other financial institutions under its regulatory purview to set-off my indebtedness from any money standing to my credit in any bank account and from any other financial assets they may be holding for my benefit.</p>
                    <p>46. The business promoters covenant and warrant that the Lender shall have the power to set - off its indebtedness under this loan agreement from all such monies and funds standing to my credit/benefit in any and all such accounts or from any other financial assets belonging to me in the custody of any such bank.</p>
                    <p>47. The promoters hereby waive any right of confidentiality whether arising under common law or statute or in any other manner whatsoever and irrevocably agree that they shall not argue to the contrary before any court of law, tribunal administrative authority or any other body acting in any judicial or quasijudicial capacity.</p>
                </div>
                <div className="flex flex-col gap-2.5">
                    <p className="font-bold">Governing Law</p>
                    <p>48. This offer letter is governed by Nigerian law and the courts of the Federal Republic of Nigeria have jurisdiction in any matter arising from it. Thank you</p>
                </div>
                <div className="flex flex-col gap-2.5">
                    <p className="text-center font-bold text-[32px] mb-[20px]">Default Terms & Fees</p>
                    <p>Events of Default</p>
                    <p>The occurrence of any of the following events shall cause all outstanding amounts under this facility to become immediately due and payable:</p>
                    <p>1. The Obligor fails to make a repayment or payment of principal, interest or other amounts in respect of the Loan on the date it was due to be paid.</p>
                    <p>2. If the Obligor defaults in the performance or the observance of any term or/and conditions here-above stated;</p>
                    <p>3. Where Creditchek Technologies Nigeria Limited gives notice of said breach/default to the borrower and said breach/default continues and remains unalleviated after seven(7) days notice had been given to it; or</p>
                    <p>4. Where the repayment of the facility is not discharged as at when due(with reference to the monthly instalment payment and other outstanding amounts applicable to the facility), Creditchek Technologies Nigeria Limited has the right to upload the customer’s data as delinquent on the Credit bureau.</p>
                    <p>5. Returned cheques/failed Direct Debit shall attract an additional charge of =N= 4,000.00 per return.</p>
                    <p>6. Should any of the rentals remain unpaid after 3 days for any reason whatsoever, a penalty equal to 0.13% of the amount of the rental shall be payable for each day of the period that the rental remained unpaid</p>
                    <p>7. Subject to the Lender’s prior approval any rental payment may be deferred or restructured to a future date subject to the Obligor’s payment of deferment and moratorium charge of 0.11% and 0.12% respectively of the rental amount per day. Such deferred rental shall be repaid immediately after the tenor is approved.</p>
                    <p>8. The Obligor breaches any of the terms and conditions of the Loan including any representation or confirmation given by the Obligor in this loan agreement.</p>
                    <p>9. Where a bankruptcy petition is filed against the Obligor.</p>
                    <p>10. Where fraud is detected at any time during the lifespan of the facility.</p>
                    <p>11. Where a situation arises, which in the Lender’s opinion makes it inappropriate to continue to extend the facility to the Obligor.</p>
                    <p>Yours faithfully,</p>
                    <p>For: Creditchek Technologies Nigeria Limited</p>
                    <p>Head, Risk Management.</p>
                </div>
                <div className="flex flex-col gap-2.5">
                    <p className="text-center font-bold text-[32px] mb-[20px]">Debit Mandate</p>
                    {
                        [
                            {title: "Date", value: dayjs().format("DD MMMM YYYY")},
                            {title: "Beneficiary Name", value: `CreditChek`},
                            {title: "Email", value: `hi@creditchek.africa`},
                            {title: "Bank", value: `Providus Bank`},
                            {title: "Account Name", value: `CREDITCHEK TECH NIG LTD`},
                            {title: "Account Number", value: `5401252883`},
                        ].map(item => (
                            <p>{item.title}: {item.value}</p>
                        ))
                    }
                </div> 
                <div className="flex flex-col gap-2.5">
                    <p className="font-bold">Mandate Details</p>
                    {
                        [
                            {title: "Description of Payment", value: "Creditchek Business Float"},
                            {title: "Amount", value: currencyFormatter(data?.loanAmount||0)},
                            {title: "Processing Fee", value: currencyFormatter(0)}, // change
                            {title: "VAT", value: currencyFormatter(0)}, // change
                            {title: "Total Payable", value: currencyFormatter(data?.loanAmount||0)},
                            {title: "Frequency", value: 'Monthly'}, // change
                            {title: "Payer Name", value: `${data?.businessBankDetail?.account_name}`},
                            {title: "Email", value: `${business?.user?.email||""}`},
                            {title: "Bank", value: `${data?.businessBankDetail?.bank_name||""}`},
                            {title: "Account Number", value: `${data?.businessBankDetail?.account_number||""}`},
                        ].map(item => (
                            <p>{item.title}: {item.value}</p>
                        ))
                    }
                </div> 
                <div className="flex flex-col gap-2.5">
                    {
                        [
                            {title: "Obligor 1", value: data?.director1Fullname||''},
                            {title: "Bank", value: data?.director1BankName||''},
                            {title: "Account Number", value: data?.director1BankAccountNumber||''},
                            {title: "Obligor 2", value: data?.director2Fullname||''},
                            {title: "Bank", value: data?.director2BankName||''},
                            {title: "Account Number", value: data?.director2BankAccountNumber||''},
                        ].map(item => (
                            <p>{item.title}: {item.value}</p>
                        ))
                    }
                </div> 
                <div className="flex flex-col gap-2.5">
                    <p>INDEMNITY</p>
                    <p>To:</p>
                    <p>Creditchek Technologies Nigeria Limited</p>
                    <p>Plot 24, Adebisi Ogunniyi Crescent,</p>
                    <p>Lekki Phase 1</p>
                    <p>Lagos, Nigeria</p>
                    <p>Dear Sirs,</p>
                    <p>1. IN CONSIDERATION of you severally accepting instructions from time to time from {business.businessName}(hereinafter called the “Payer”) to debit the account of the payer with the amounts specified on instruments drawn in paper form or in automated input form written in accordance with the Direct Debit mandate above between {business.businessName} and Creditchek Technologies Nigeria Limited, we hereby warrant that the Payer on whose account a debit is drawn will have signed a Direct Debit Mandate, and we shall keep you indemnified upon your first demand against all actions, losses, damages, claims, demands costs and expenses (including legal costs, fees and expenses on a full indemnity basis) howsoever arising, which you may incur or sustain directly or indirectly from such debiting or failure to debit and without our requiring proof of our agreement to the validity of such demand we shall forthwith pay the amount.</p>
                    <p>2. We authorize you to admit compromise or reject any claims made upon you without reference to or authority from the Payer.</p>
                    <p>3. You are not required to verify or check that instructions given to you have been given and remain in force in respect of any debit and transfer request.</p>
                    <p>4. You are not required to verify or check that any purpose of payment stated in the Direct Debit Mandate signed by the Payer is fulfilled or is observed.</p>
                    <p>5. This Direct Debit Indemnity is to be in addition to and is not to prejudice or be prejudiced by any other Direct Debit Indemnity which has been or may now or hereafter be executed by us in connection with the Direct Debit Agreement, and shall be binding on us as continuing security notwithstanding any payments from time to time made to you or any settlement of account or disability, incapacity, insolvency that may affect us or any other thing whatsoever.</p>
                    <p>6. You are to be at liberty without thereby affecting your rights hereunder at any time and from time to time at your absolute discretion to release, discharge, compound with or otherwise vary or agree the liability under this Direct Debit Indemnity or make any other arrangements with us.</p>
                    <p>7. This Direct Debit Indemnity shall be enforceable notwithstanding any change in your name or any change in the constitution of your organization, its successors or assigns or by its amalgamation with any other bank or banks.</p>
                    <p>8. This Direct Debit Indemnity shall be governed by and construed in accordance with the laws of the Federal Republic of Nigeria.</p>
                    <p>Signed By: For and on behalf of: {business.businessName} pursuant to a resolution of the Board of Directors of the Creditor a certified copy of which is annexed hereto</p>
                    <p>In the presence of:</p>
                    <p>Obligor’s Name: {data?.director1Fullname||''}</p>
                    <p>Address: {data?.address||""}</p>
                    <p>Role: {data?.director1Position||""}</p>
                </div>
                <div className="flex flex-col gap-2.5">
                    <p className="font-bold">MEMORANDUM OF ACCEPTANCE</p>
                    <p>We the Obligor/Directors ({data?.director1Fullname||''} and {data?.director2Fullname||''}) hereby accept the terms and conditions contained in this offer letter and further agree that we are personally liable in the event of a default for this Business loan as confirmed by clicking the consent link sent shared via our emails.</p>
                </div>
                <div className="font-bold">
                    <div className="flex">
                        <p className="whitespace-nowrap">Full Name:</p>
                        <div className="w-full border-b-[2px] border-b-black" />
                    </div>
                    <div className="flex gap-10 mt-6">
                        <div className="flex w-1/2">
                            <p>Signature:</p>
                            <div className="w-full border-b-[2px] border-b-black" />
                        </div>
                        <div className="flex w-1/2">
                            <p>Date:</p>
                            <div className="w-full border-b-[2px] border-b-black" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}