import { Modal } from "react-bootstrap";
import logo from "images/logos/white-logo.svg";
import { IoCopyOutline } from "react-icons/io5";
import { copyText } from "utils/helpers";

export default function TransferInfoModal ({
    show, handleClose
}) {
    return(
        <Modal show={show} onHide={handleClose} 
        // aria-labelledby="contained-modal-title-vcenter" centered
        >
            <div className="bg-cc-primary rounded-[10px] text-white text-base py-6 px-[20px] bg-[url('./images/svg/sidebar/bg-image.svg')]">
                <img src={logo} alt="logo" className="block mx-auto w-[200px]" />
                {
                    [
                        {
                            title: "ACH/Domestic wire instructions",
                            message: "Use the following instructions below to send ACH or domestic wires to us:",
                            list: [
                                { text: "ABA/Routing number", value: "121145349" },
                                { text: "Account number", value: "579676493840721", hasCopy: true },
                                { text: "Account type", value: "Business Checking" },
                                { text: "Recipient/Beneficiary name*", value: "Creditchek Inc" },
                                { text: "Bank name", value: "Column NA - Brex" },
                                { text: "Bank address", value: "1 Letterman Drive Building A, Suite A4-700, San Francisco, CA 94129", hasCopy: true },
                            ]
                        },
                        {
                            title: "International wires instructions",
                            message: <span>Follow the instructions below to send an international wire to us: <br/>Beneficiary bank information</span>,
                            list: [
                                { text: `SWIFT/BIC code`, value: "CLNOUS66BRX", hasCopy: true },
                                { text: `Bank name`, value: "Column NA - Brex" },
                                { text: `Bank address`, value: "1 Letterman Drive Building A, Suite A4-700, San Francisco, CA 94129", hasCopy: true },
                                { text: `unt type`, value: "Business Checking" },
                            ]
                        },
                        {
                            title: "Creditchek Bank Information",
                            list: [
                                { text: `Beneficiary name`, value: "Creditchek Inc" },
                                { text: `Beneficiary Account number`, value: "579676493840721", hasCopy: true },
                                { text: `Beneficiary address`, value: "2055 Limestone Rd Ste 200C, Wilmington, DE 19808", hasCopy: true },
                            ]
                        }
                    ].map((item, idx)=>(
                        <div key={idx}>
                            <p className="text-[18px] font-bold mt-6">{idx+1}. {item?.title}</p>
                            <p className="mb-1.5">{item?.message}</p>
                            <ul>
                                {
                                    item.list.map((x, i)=>(
                                        <li key={i} className="flex items-center gap-1.5">
                                            {x.text}{": "}
                                            {x.value}  
                                            {
                                                x?.hasCopy && 
                                                <IoCopyOutline 
                                                    className="text-white shrink-0 w-[20px] cursor-pointer"
                                                    onClick={() => copyText(x.value, `${x.text} copied!`)}
                                                />
                                            }
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    ))
                }
            </div>
        </Modal>
    )
}