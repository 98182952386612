import loading from "images/gif/blue-loader.gif";

export default function FormLoader ({title, message}) {
    return (
        <div className="mt-[100px] flex flex-col items-center justify-center">
            <img src={loading} alt="load" className="w-[80px]"/>
            <p className="text-[24px] font-medium">{title}</p>
            <p className="text-sm">{message}</p>
        </div>
    )
}