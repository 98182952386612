import { useState, useEffect } from "react";
import ConfirmAction from "components/Modal/ConfirmAction";
import { toast } from "react-toastify";
import { logToBin } from "lib/logs";
import { BillingService } from "services/billing.service";
import { IdentityService } from "services/identity.service";
import { Mixpanel } from "lib/mixpanel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { currencyFormatter } from "utils/helpers";

export default function NINInterface ({permissionValues}) {
    let history = useNavigate();
    const [nin, setNIN] = useState("");
    const [ninPrice, setNinPrice] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [disableAction, setDisableAction] = useState(false);
    const b2b_data = useSelector(state => state?.loginData?.data)
  
    const handleSubmit = () => {
      Mixpanel.track(`Identity Insight (Verify NIN) button was clicked`, {
        name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
        bussinessName: b2b_data?.businessName
    });
      if(permissionValues?.includes("edit")){
        if(nin.length===11){
          setShowModal(true);
        } else {
          toast.error("NIN must be 11 characters long!")
        }
      }else{
        toast.error("You are not authorized to perform this action!!!")
      }
    }
  
    useEffect(() => {
      let payload = {
        action: "identity nin verification"
      }
      BillingService.getBillingPricing(payload)
      .then(response=> response.data)
      .then(res => {
          if(res?.success === true){
            setNinPrice(res?.data?.price)
          } else {
              toast.error(res.message)
          }
      })
      .catch((err) => {
          if(err.response){
            logToBin(err.response.data, window.location.href);
              // toast.error(err.response.data.error.message);
          } else{
            if(err.message === "timeout of 15000ms exceeded"){
                toast.error('Request timed out, please try again!')
            } else if (err.message==="Network Error"){
                toast.error('Network error, please make sure you are connected to the internet!')
            } else{
                toast.error("Your request can't be processed at this time, please try again later!");
            }
          }
      });
    }, [])
  
    const doSubmit = () => {
        IdentityService.doNINVerification(nin)
            .then((response) => response.data)
            .then((res) => {
                setShowModal(false)
                if (res.status === true) {
                  sessionStorage.setItem("identity", JSON.stringify(res.data))
                    toast.success("Request Successful!!!")
                    setTimeout( function (){
                        history(`/identity/nin-verification`)
                    }, 100);
                } else {
                    toast.error(res.message)
                    setDisableAction(false)
                }
            })
            .catch((err) => {
                setDisableAction(false);
                setShowModal(false)
                if(err.response){
                  logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                  if(err.message === "timeout of 15000ms exceeded"){
                      toast.error('Request timed out, please try again!')
                  } else if (err.message==="Network Error"){
                      toast.error('Network error, please make sure you are connected to the internet!')
                  } else{
                      toast.error("Your request can't be processed at this time, please try again later!");
                  }
                }
            });
    }
  
    return(
      <div className="box-border">
        <div className="settings_profile-form w-[400px] md:w-full md_min:mb-0 mb-[30px]">
          <label>National Identity Number</label><br/>
          <input className="apps_create-input" type="text" placeholder="NIN" onChange={(e)=>setNIN(e.target.value)} /><br/>
          {/* <p>Generate VNIN using the code <strong>*346*3*customer NIN*696739#</strong></p> */}
          <button className={permissionValues?.includes("edit")?"apps_create-button":"apps_create-btnInactive"} onClick={()=>handleSubmit()} >Verify NIN</button>
        </div>
        <ConfirmAction
          show={showModal}
          onCancel={()=>setShowModal(false)}
          onAction={()=>{
            doSubmit()
            setDisableAction(true);
          }}
          loading={disableAction}
          title={`NIN Verification`}
          content={`Using the NIN service would attract a charge of ${currencyFormatter(ninPrice)}.`}
        />
      </div>
    )
}