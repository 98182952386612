import React, { useEffect, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { TextField } from 'components/Form/TextField'
import { recovaSettlement } from 'utils/validationSchemas'
import { useSteps } from 'react-step-builder'
import Button from 'components/shared/Button'
import { useSelector } from 'react-redux'
import { SelectField } from 'components/Form/SelectField'
import { useRecovaSetup } from 'hooks/redux.hook'
import { RecovaService } from 'services/recova.service'
import { currencyFormatter, handleRequestErrors } from 'utils/helpers'
import { toast } from 'react-toastify'
import { BillingService } from 'services/billing.service'
import mark from "images/svg/recova/mark.svg";
import loaddot from "images/gif/load-dots.gif";
import { IdentityService } from 'services/identity.service'


export default function AccountDetails () {
    const { next } = useSteps();
    const [isLoading, setIsLoading] = useState("");
    const ngBanks = useSelector((state) => state.banksData.banks);
    const { recovaSetupData, updateSetupDispatch } = useRecovaSetup();
    const [price, setPrice] = useState(0)
    const filter = ngBanks?.filter(x => x?.code === recovaSetupData?.linkedAccount?.bankCode) || [];

    useEffect(()=>{
        let payload = {
            action: "account number basic verification"
        }
        BillingService.getBillingPricing(payload)
            .then(response=> response.data)
            .then(res => {
                if(res.success){
                    setPrice(res.data.price)
                }
            }).catch(err=>{})
    },[])

    const form = useFormik({
        initialValues: {
            bank:  filter?.[0] || null,
            accountNumber: recovaSetupData?.linkedAccount?.accountNumber || "",
            accountName: recovaSetupData?.linkedAccount?.accountName || "",
        },
        validationSchema: recovaSettlement,
        onSubmit: (values) => {
            setIsLoading(true);
            const payload = JSON.stringify({
                accountName: values.accountName,
                accountNumber: values.accountNumber,
                bankName: values.bank.name,
                bankCode: values.bank.code
            })
            RecovaService.payoutUpdateAccount(payload)
                .then(response=> response.data)
                .then(res => {
                    setIsLoading(false)
                    if(res.status){
                        updateSetupDispatch({
                            linkedAccount: {
                                accountName: values.accountName,
                                accountNumber: values.accountNumber,
                                bankName: values.bank.name,
                                bankCode: values.bank.code
                            }
                        })
                        next();
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    handleRequestErrors(err)
                    setIsLoading(false)
                });
        }
    })

    useEffect(()=>{
        if(!!form.values.bank && form.values.accountNumber.length===10){
            IdentityService.basicBankVerification(form.values.accountNumber, form.values.bank.code)
                .then(response=> response.data)
                .then(res => {
                    if(res.status){
                        form.setFieldValue("accountName", res.data.account_name);
                    }
                })
                .catch((err) => {
                    form.setFieldValue("accountName", "");
                    handleRequestErrors(err)
                });
        } else {
            form.setFieldValue("accountName", "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values.bank, form.values.accountNumber])

    return(
        <div className='mx-5'>
            <div
                style={{boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", border: `1px solid #D9D9D9`}}
                className='px-3 py-3 rounded-2xl my-4'
            >
                <h2 className='text-sm font-bold mt-4 w-7/12'>
                    Fill in correctly the bank details
                    to enable  automated settlement
                </h2>
                <p className='text-cc-red-1 mt-1 mb-4 text-xs '>Note: You will be charged {currencyFormatter(price, 0)} for Account name verification in order to succesfully link an account.</p>
                <FormikProvider value={form}>  
                    <form onSubmit={form.handleSubmit}>
                        <div className='box-border'>
                            <SelectField
                                name='bank'
                                options={ngBanks.map(item => ({
                                    value: item,
                                    label: item.name,
                                }))}
                                searchable
                                value={form?.values?.bank}
                                label="Bank"
                                placeholder="Select bank"
                                onChange={(selected)=>{
                                    form.setFieldValue( "bank", selected.value )
                                }}
                                required
                            />

                            <TextField
                                label="Account Number"
                                name='accountNumber'
                                type='text'
                                placeholder="Account Number"
                                required
                            />

                            <div className='relative'>
                                <TextField
                                    label="Account Name"
                                    name='accountName'
                                    type='text'
                                    placeholder="Account Name"
                                    required
                                />
                                { 
                                    !!form.values.bank && form.values.accountNumber.length===10 &&
                                    <img src={loaddot} alt="load" className='top-10 w-[20px] right-[10px] absolute' /> 
                                }
                                { !!form.values.accountName && <img src={mark} alt="mark" className='top-10 w-[20px] right-[10px] absolute' /> }
                            </div>

                            <Button
                                isValid={form.isValid}
                                // disabled={
                                //     [form?.values?.accountNumber, form.values?.accountName, bank ].some(x=> x==="")
                                // }
                                isLoading={isLoading}
                                type="submit"
                                text="Link Account"
                            />

                        </div>
                    </form>
                </FormikProvider>
            </div>
        </div>
    )
}