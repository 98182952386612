/* eslint-disable use-isnan */
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast} from "react-toastify";
import {Row, Col} from "react-bootstrap";
import CreditCard from "./components/VirtualCard/CreditCard";
import Table from "components/Table/OldTable";
import EdtiTable from "components/Table/edtiTable";
import { IncomeService } from "services/income.service";
import Chart from "./Chart";
import Donut from "./Chart/Donut";
import {jsPDFGenerator} from '../../../lib/pdfGenerate/income';
import Back from 'components/shared/back'
import { logToBin } from "../../../lib/logs";
import OutsideClickHandler from 'react-outside-click-handler';
import MonthRange from "./MonthRange";
import CashFlowHighlight from "./components/CashFlowHighlights";

import fico from "../../../images/svg/credit/fico.svg";
import moreFico from "../../../images/svg/credit/more-fico.svg";
import red from "../../../images/svg/income/red-income.svg";
import blue from "../../../images/svg/income/blue-income.svg";
import green from "../../../images/svg/income/green-income.svg";
import scrollLeft from "../../../images/svg/card/scrollLeft.svg";
import scrollRight from "../../../images/svg/card/scrollRight.svg";
import download from "../../../images/svg/credit/download.svg";
import outstanding from "../../../images/svg/income/outstanding.svg";
import overdue from "../../../images/svg/income/overdue.svg";
import totalValue from "../../../images/svg/income/total-value.svg";
import calendar from "../../../images/svg/income/calendar.svg";
import calendarWhite from "../../../images/svg/income/calendarWhite.svg";
import toGraph from "../../../images/svg/income/toGraph.svg";
import toTable from "../../../images/svg/income/toTable.svg";
import { TransactionPatterns } from "./components/TransactionPatterns";
import { SalaryHighlight } from "./components/SalaryHighlight";
import { useSelector } from "react-redux";
import Name from "components/shared/Name";
import { currencyFormatter } from "utils/helpers";
import AverageValues from "./components/AverageValue";
import FilePreview from "components/shared/FilePreview";
import { useBilledPageExit } from "hooks/feedback.hook";

function InsightDetails ({setDetailName}) {
    const ngBanks = useSelector(state => state?.banksData?.banks)
    const [data, setData] = useState({
        tableFor: "user-history",
        headings: ["TYPE", "NARRATION", "AMOUNT", "DATE", "BANK"],
        items: []
    });
    const [edtiData, setEdtiData] = useState({
        tableFor: "edti",
        headings: ["Month", "Amount"],
        items: []
    });
    const [topNav, setTopNav] = useState("transaction")
    const [creditData, setCreditData] = useState({
        tableFor: "accounts",
        headings: ["CUSTOMER NAME", "ACCOUNT NUMBER", "BALANCE", "DATE", "BANK"],
        items: []
    })
    const colors = ['#DB5ABA', '#1B60FC', '#C7E8F3', '#8DCE8A', '#FADADD', '#152E0A', '#6DE833', '#A567FF', '#FFA567', '#696969', '#FF6666', '#FFD700', 'red', 'green', "blue"];
    const [filterValue, setFilterValue] = useState({
        months: [],
        activeKeys: [],
        customStart: 0,
        customEnd: 0,
    })
    const [filterSingle, setFilterSingle] = useState('Select Month')
    const [showSingleOptions, setShowSingleOptions] = useState(false);
    const [isGraph, setIsGraph] = useState(false);
    const [balanceFilter, setBalanceFilter] = useState('year');
    const businessId = useSelector(state => state?.loginData?.data?.user?.businessId)

    useBilledPageExit("Income Insight")

    const navigate = useNavigate();
    let income = {};
    let EDTIForPDF = {};

    try {
        let details2 = sessionStorage?.getItem('incomeInsightData');
        income = JSON?.parse(details2);
        let details3 = sessionStorage?.getItem('incomeInsightData');
        EDTIForPDF = JSON?.parse(details3)
    } catch (err) {
        //err
    }

    useEffect(()=>{
        const objArray = [];
        if(filterSingle!=='Select Month'){
            filterValue.activeKeys.forEach((item)=>{
                objArray.push({
                    name: item, 
                    value: income?.edti[`${item}`][`${filterSingle}`]===undefined?0:income?.edti[`${item}`][`${filterSingle}`]
                })
            })
            setEdtiData({
                ...edtiData,
                items: objArray,
                headings: ["Action", "Amount"]
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterSingle])

    const getDatesInRange = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const newDate = [];
        filterValue?.months?.forEach((item)=>{
            const itemDate = new Date(item);
            if(itemDate >= start && itemDate <= end){
                newDate.push(item)
            }
        })
            
        return newDate;
    }

    useEffect(()=>{
        const chosenRange = getDatesInRange(filterValue?.months?.[filterValue?.customStart-1], filterValue?.months?.[filterValue?.customEnd-1])
        const customArray = []

        if(filterValue.customStart!==0 && filterValue.customEnd!==0){
            filterValue?.activeKeys?.forEach((item)=>{
                const valuesArray = [0]
                chosenRange.forEach((monthItem)=>{
                    valuesArray.push(income.edti[`${item}`][`${monthItem}`]===undefined?0:income.edti[`${item}`][`${monthItem}`])
                })
                const valuesArraySum = valuesArray?.reduce(function(a,b){
                    return a+b;
                });
                customArray.push({
                    name: item, 
                    value: valuesArraySum
                })
            })
            setEdtiData({
                ...edtiData,
                items: customArray,
                headings: ["Action", "Amount"]
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValue])

    useEffect(()=>{
        try{
            let details3 = sessionStorage.getItem('incomeInsightData');

            // get months and years available in the pdf
            let balance = JSON.parse(details3).edti.balance;
            const availableMonths = [];
            Object.keys(balance).forEach(key => {
                if(!availableMonths?.includes(key)){
                    availableMonths.push(key);
                    setFilterValue({
                        ...filterValue,
                        months: availableMonths
                    })
                }
            });

            if(topNav==="expense"){
                let EDTI = JSON.parse(details3).edti.EDTI
                let result = Object.keys(EDTI).map((key) => [key, EDTI[key]])
                
                setEdtiData({
                    ...edtiData,
                    items: result,
                    headings: ["Month", "DBR from percentage"]
                })
            } else if (topNav==="sitting"){
                let result = Object.keys(balance).map((key) => [key, balance[key]])
                
                setEdtiData({
                    ...edtiData,
                    items: result.reverse(),
                    headings: income?.edti?.creditBreakdown?.monthlyCredit ? ["Month", "End-of-month balance", "Debit", "Credit"] : ["Month", "End-of-month balance"]
                })
            } else if (topNav==="recurrent"){
                let recurrent = JSON.parse(details3).edti.recurrent_expenses_debt
                let result = Object.keys(recurrent).map((key) => [key, recurrent[key]])
                
                setEdtiData({
                    ...edtiData,
                    items: result,
                    headings: ["Month", "Debt Burden ratio (amount)"]
                })
            } else if (topNav==="spending"){
                let spending = JSON.parse(details3).edti.spendingAnalysis;
                const result = [];
                const activeKeys = [];
                Object.keys(spending).forEach(key => {
                    result.push({name: key, value: spending[key][0]});
                    if(spending[key][0]!==0){
                        activeKeys.push(key)
                    }
                });
                setFilterValue({
                    ...filterValue,
                    activeKeys: activeKeys
                })
                setEdtiData({
                    ...edtiData,
                    items: result,
                    headings: ["Action", "Amount"]
                })
            } else {

            }
        } catch {}
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[topNav])
    
    useEffect(()=>{
        let payload = JSON.stringify({
            borrowerId: JSON.parse(sessionStorage.getItem('incomeBorrower')).data
        });
        IncomeService.monoTransactions(payload)
            .then((response) => response.data)
            .then((res) => {
                if (res.status === "success") {
                    setData({
                        ...data,
                        items: res.data
                    })
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(()=>{
        IncomeService.encryptText(JSON.stringify({text: businessId})).then(resp => {
            let business_id = resp
            let borrowerId = JSON.parse(sessionStorage.getItem('incomeBorrower')).data
            let payload = {business_id, borrowerId  }
            IncomeService.getUserAcccounts(payload)
                .then((response) => response.data)
                .then((res) => {
                    console.log(res)
                    if (res.status === "success" ) {
                        setCreditData({
                            ...data,
                            items: res.data
                        })
                        setDetailName(`${res?.data[0]?.name}`)
                    } else {
                        // toast.error(res.message)
                    }
                })
                .catch((err) => {
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                });
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const collapseReasons = () => {
        let more = document.getElementById('more');
        let drp = document.getElementById('drp-arrow');
        var content = document.getElementById('reasons');
        if (content.style.maxHeight){
          content.style.maxHeight = null;
          drp.style.transform = "rotate(0deg)";
          more.innerHTML = "More Details"
        } else {
          content.style.maxHeight = content.scrollHeight + "px";
          drp.style.transform = "rotate(180deg)";
          more.innerHTML = "Less Details"
        }
    }

    const [cards] = useState(["#5252FF"]);
    const ref = useRef(null);
    const [showLeftScrollAction, setShowLeftScrollAction] = useState(false);
    const [showRightScrollAction, setShowRightScrollAction] = useState(true);
    const [refScrollLeft, setRefScrollLeft] = useState(0);

    const scroll = (scrollOffset) => {
        ref.current.scrollLeft += scrollOffset;
        setRefScrollLeft((ref.current.scrollLeft += scrollOffset));
    };

    useEffect(() => {
        // for the left scroll action
        if (refScrollLeft < 1) {
        setShowLeftScrollAction(false);
        } else {
        setShowLeftScrollAction(true);
        }
        // for the left scroll action
        if (refScrollLeft >= ref.current.scrollWidth - ref.current.clientWidth) {
        setShowRightScrollAction(false);
        } else {
        setShowRightScrollAction(true);
        }
    }, [refScrollLeft]);

    return(
        <div className="py-[30px] px-10 sm:pl-2.5 sm:pr-[30px]">
            <div className="box-border">
                <Back to={()=> navigate(`/income/link-account?tm=${new Date().toISOString()}`)} />
                <div style={{display:'flex'}}>
                    <Name data={creditData?.items[0]?.name||"User"} />
                    <div className="credit_boxes-title-buttons" style={{marginLeft:'auto'}}>
                        <button className="download flex items-center" onClick={()=>jsPDFGenerator(creditData, income, EDTIForPDF)} > 
                            <img src={download} alt="" />
                            <p>Download Report</p>
                        </button>
                    </div>
                </div>
                <div style={{margin:"50px 0"}}>
                    <div className="home_main-topText income_topic">
                        <h5>Connected accounts</h5>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div style={{position:"relative"}}>
                                {showLeftScrollAction && (
                                    <div className="cards-left-arrow" onClick={() => scroll(-321)}>
                                        <img src={scrollLeft} alt="" />
                                    </div>
                                )}
                                {showRightScrollAction && (
                                    <div className="cards-right-arrow" onClick={() => scroll(321)}>
                                        <img src={scrollRight} alt="" />
                                    </div>
                                )}
                                <div className="card-scroll" ref={ref}>
                                {cards.map((item) => (
                                    <CreditCard
                                        ngBanks={ngBanks}
                                        creditData={creditData}
                                        color={item}
                                        key={item._id}
                                    />
                                ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="home_main-topText income_topic">
                    <h5>Income Insights for {creditData?.items[0]?.name?.toLowerCase()}</h5>
                    <div className="home_main-topText income_topic-months"><div></div><p>{income?.edti?.number_of_active_months} {income?.edti?.number_of_active_months===1?'month':'months'}</p></div>
                </div>
                <div style={{boxSizing:"border-box"}}>
                    <Row>
                        <Col md={6} lg={4} xl={4}>
                            <div className="credit_boxes-single">
                                <div className="credit_boxes-single-top">
                                    <img src={fico} alt="icon" className="my-0" />
                                    <p>Debt Burden Ratio</p>
                                </div>
                                <div style={{margin:"10px 0 -10px"}}>
                                    <div className="credit_fico-single-flex">
                                        <p className="credit_boxes-single-text">
                                            {income?.edti?.average_monthly_edti === 'NaN' ? 0 : Math.round(income?.edti?.average_monthly_edti)}%
                                        </p>
                                        <div className="credit_fico-single-flex more items-center" onClick={collapseReasons}>
                                            <p id="more">More Details</p>
                                            <img src={moreFico} alt="more" id="drp-arrow" className="w-[20px] mt-0" />
                                        </div>
                                    </div>
                                    <AverageValues 
                                        positive={income?.edti?.average_monthly_edti > income?.edti?.creditBreakdown?.averageAnnualEdti} 
                                        value={`${Math.round(income?.edti?.creditBreakdown?.averageAnnualEdti||0)}%`} 
                                    />
                                    <div className="credit_fico-single-reasons mt-[10px]" id="reasons">
                                        <ul>
                                            <li>{income?.edti?.DTI_reason}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={4} xl={4}>
                            <div className="credit_boxes-single">
                                <div className="credit_boxes-single-top mb-[10px] items-center">
                                    <img src={green} alt="icon" className="my-0" />
                                    <p>Average Monthly Income</p>
                                </div>
                                <p className="credit_boxes-single-text">
                                    {
                                        currencyFormatter(
                                            (income?.edti?.average_monthly_income||0)/100, 
                                            2, "NGN"
                                        )
                                    }
                                </p>
                                <AverageValues 
                                    positive={income?.edti?.average_monthly_income > income?.edti?.creditBreakdown?.averageAMonthlyIncome} 
                                    value={`${currencyFormatter((income?.edti?.creditBreakdown?.averageAMonthlyIncome||0)/100, 2, "NGN")}`} 
                                />
                            </div>
                        </Col>
                        <Col md={6} lg={4} xl={4}>
                            <div className="credit_boxes-single">
                                <div className="credit_boxes-single-top mb-[10px] items-center">
                                    <img src={green} alt="icon" className="my-0" />
                                    <p>Total Money Received</p>
                                </div>
                                <p className="credit_boxes-single-text">
                                    {
                                        currencyFormatter(
                                            (income?.edti?.totalMoneyReceive || income?.edti?.totalMoneyReceived || 0)/100, 
                                            2, "NGN"
                                        )
                                    }
                                </p>
                                <AverageValues 
                                    positive={(income?.edti?.totalMoneyReceive || income?.edti?.totalMoneyReceived) > income?.edti?.creditBreakdown?.averageTotalMoneyReceived} 
                                    value={`${currencyFormatter((income?.edti?.creditBreakdown?.averageTotalMoneyReceived||0)/100, 2, "NGN")}`} 
                                />
                            </div>
                        </Col>
                        <Col md={6} lg={4} xl={4}>
                            <div className="credit_boxes-single">
                                <div className="credit_boxes-single-top mb-[10px] items-center">
                                    <img src={red} alt="icon" className="my-0" />
                                    <p>Total Money Spent</p>
                                </div>
                                <p className="credit_boxes-single-text">
                                    {
                                        currencyFormatter((income?.edti?.totalMoneySpent||0)/100, 2, "NGN")
                                    }
                                </p>
                            </div>
                        </Col>
                        <Col md={6} lg={4} xl={4}>
                            <div className="credit_boxes-single">
                                <div className="credit_boxes-single-top mb-[10px] items-center">
                                    <img src={red} alt="icon" className="my-0" />
                                    <p>Average Monthly Recurring Expense</p>
                                </div>
                                <p className="credit_boxes-single-text">
                                    {currencyFormatter(income?.edti?.average_monthly_recurring_debt_expenses/100, 2, "NGN")}
                                </p>
                            </div>
                        </Col>
                        <Col md={6} lg={4} xl={4}>
                            <div className="credit_boxes-single">
                                <div className="credit_boxes-single-top mb-[10px] items-center">
                                    <img src={blue} alt="icon" className="my-0" />
                                    <p>Recommended Loan Amount</p>
                                </div>
                                <p className="credit_boxes-single-text">
                                    {
                                        currencyFormatter((income?.edti?.eligibleAmount||0)/100, 2, "NGN")
                                    }
                                </p>
                                <AverageValues 
                                    positive={income?.edti?.eligibleAmount > income?.edti?.creditBreakdown?.averageEligibleAmount} 
                                    value={`${currencyFormatter((income?.edti?.creditBreakdown?.averageEligibleAmount||0)/100, 2, "NGN")}`} 
                                />
                            </div>
                        </Col>
                        { 
                            income?.edti?.creditData &&
                            <>
                                <Col md={6} lg={4} xl={4}>
                                    <div className="credit_boxes-single">
                                        <div className="credit_boxes-single-top mb-[10px] items-center">
                                            <img src={totalValue} alt="icon" className="my-0" />
                                            <p>Total Loan Value</p>
                                        </div>
                                        <p className="credit_boxes-single-text">
                                            {
                                                currencyFormatter(income?.edti?.totalBorrowed, 2, "NGN")
                                            }
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6} lg={4} xl={4}>
                                    <div className="credit_boxes-single">
                                        <div className="credit_boxes-single-top mb-[10px] items-center">
                                            <img src={outstanding} alt="icon" className="my-0" />
                                            <p>Total Oustanding Value</p>
                                        </div>
                                        <p className="credit_boxes-single-text">
                                            { currencyFormatter(income?.edti?.totalOutstanding, 2, "NGN") }
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6} lg={4} xl={4}>
                                    <div className="credit_boxes-single">
                                        <div className="credit_boxes-single-top mb-[10px] items-center">
                                            <img src={overdue} alt="icon" className="my-0" />
                                            <p>Total Overdue</p>
                                        </div>
                                        <p className="credit_boxes-single-text">
                                            {
                                                currencyFormatter(income?.edti?.totalOverdue, 2, "NGN")
                                            }
                                        </p>
                                    </div>
                                </Col>
                            </>
                        }
                    </Row>
                </div>
                {/* {
                    creditData?.items?.[0]?.name!=="" &&
                } */}
                <div style={{marginTop:'40px'}}>
                    <div className="home_main-topText income_topic">
                        <h5>Bank Statements</h5>
                    </div>
                    <div style={{margin:'-15px 0 40px'}}>
                        <Row>
                            {
                                creditData?.items?.map(item=>(
                                    <Col md={6} lg={4} key={item._id}>
                                        <FilePreview
                                            name={`${item?.name || "user"}.pdf`}
                                            additionalName={item?.institution?.name||"---"}
                                            hideDownload={item.source!=="pdf"}
                                            downloadLink={item?.pdfUrl}
                                        />
                                    </Col>
                                ))
                            }
                        </Row>
                    </div>
                </div>
                <div className="settings_navMain">
                    <p className={topNav==="transaction"?"active":""} onClick={()=>setTopNav("transaction")}>Transactions</p>
                    <p className={topNav==="expense"?"active":""} onClick={()=>setTopNav("expense")}>Debt-Burden-Ratio (%)</p>
                    <p className={topNav==="sitting"?"active":""} onClick={()=>setTopNav("sitting")}>Monthly Sitting Balance</p>
                    <p className={topNav==="recurrent"?"active":""} onClick={()=>setTopNav("recurrent")}>Monthly Recurrent Expenses/Debt</p>
                    {income?.edti?.spendingAnalysis && <p className={topNav==="spending"?"active":""} onClick={()=>setTopNav("spending")}>Spending Categories</p>}
                    <p className={topNav==="cashflow"?"active":""} onClick={()=>setTopNav("cashflow")}>Cashflow Highlight</p>
                    {income?.edti?.salary?.amount?.length>0 && <p className={topNav==="salary"?"active":""} onClick={()=>setTopNav("salary")}>Salary Highlight</p>}
                    <p className={topNav==="patterns"?"active":""} onClick={()=>setTopNav("patterns")}>Transaction Patterns</p>
                </div>
                {
                    topNav === "transaction" ? 
                    <Table data={data} />
                    :
                    topNav === "spending" ? 
                    <div className="income_spending">
                        <div className="income_spending-box">
                            <h5 className="text-lg font-medium">Spending Categories</h5>
                            <p>Here’s a view of the spending categories for {creditData?.items[0]?.name}</p>
                            <hr className="my-2"/>
                            <div style={{display:'flex', margin:'0 0 20px -10px'}}>
                                <div className={filterSingle==="Select Month"?"home_main-topText-filter":"home_main-topText-filter income-filterActive"} onClick={() => setShowSingleOptions(true)}>
                                    <p className={filterSingle==="Select Month"?'flex items-center':'income-filterActive-text flex items-center'}>
                                        <img src={filterSingle==="Select Month"?calendar:calendarWhite} alt="" />
                                        <span>{filterSingle==="Select Month" ? filterSingle : `${filterSingle?.substring(0,3)} ${filterSingle?.split(" ")[1]}`}</span>
                                    </p>
                                    {
                                        showSingleOptions &&
                                        <div className="income_spending-box-filter-options" style={{minWidth: '100px', overflowY: 'scroll'}}>
                                            <OutsideClickHandler
                                                onOutsideClick={() => {
                                                    setShowSingleOptions(false)
                                                }}
                                            >
                                                {
                                                    filterValue?.months?.map((item, idx)=>(
                                                        <>
                                                            <p 
                                                                onClick={()=>{
                                                                    setFilterSingle(item);
                                                                    setFilterValue({...filterValue, customStart: 0, customEnd: 0})
                                                                }}
                                                            >
                                                                {item?.substring(0,3)} {item?.split(" ")[1]}
                                                            </p>
                                                            {(filterValue?.months?.length-1) !== idx && <hr/>}
                                                        </>
                                                    ))
                                                }
                                            </OutsideClickHandler>
                                        </div>
                                    }
                                </div>
                                <MonthRange 
                                    data={filterValue} 
                                    setSelectionRange={(item1, item2)=>{
                                        setFilterValue({...filterValue, customStart: item1, customEnd: item2});
                                        setFilterSingle('Select Month')
                                    }} 
                                />
                            </div>
                            <div className="income_spending-box-data">
                                <Donut data={edtiData} colors={colors}/>
                                <div className="rep">
                                    {
                                        edtiData?.items?.map((item, idx) => (
                                            item?.value!==0 &&
                                            <div key={idx}>
                                                <span style={{background:`${colors[idx]}`}} ></span><p>{item?.name}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <hr className="my-3"/>
                            <Row>
                                {
                                    edtiData?.items?.map((item, idx) => (
                                        item?.value!==0 &&
                                        <Col md={4} xs={6} key={idx}>
                                            <div className="income_spending-box-single mb-3">
                                                {/* <img src={gambling} alt=""/> */}
                                                <div>
                                                    <p>{item?.name}<br/><span>{currencyFormatter(item?.value/100, 2, "NGN")}</span></p>
                                                </div>
                                            </div>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </div>
                    </div>
                    : 
                    topNav === "sitting" || topNav === "recurrent" || topNav === "expense" ? 
                    <div>
                        {
                            (topNav==="sitting" && income?.edti?.creditBreakdown) &&
                            <div className="income_detailsNav-filter">
                                <p className="filterBy">Filter By</p>
                                <p className={`filterOption ${balanceFilter==='year'&&'active'}`} onClick={()=>setBalanceFilter('year')}>1 year</p>
                                <p className={`filterOption ${balanceFilter==='bi-annual'&&'active'}`} onClick={()=>setBalanceFilter('bi-annual')}>Bi-Annually</p>
                                <p className={`filterOption ${balanceFilter==='quarter'&&'active'}`} onClick={()=>setBalanceFilter('quarter')}>Quarterly</p>
                            </div>
                        }
                        <div className="income_detailsNav-table">
                            {
                                isGraph ?
                                <div>
                                    <Chart data={edtiData.items} type={topNav}/>
                                </div>
                                :
                                <div className={topNav==="sitting"?"income_detailsNav-table-size1":"income_detailsNav-table-size2"} >
                                    <EdtiTable data={edtiData}  changeSee={topNav} creditBreakdown={income.edti.creditBreakdown} filterOpt={balanceFilter} /> 
                                </div>
                            }
                            <img className="income_detailsNav-table-switch" src={isGraph?toTable:toGraph} alt="switch tab" onClick={()=>setIsGraph(!isGraph)} />
                        </div>
                    </div>
                    :
                    topNav === "cashflow" ?
                    <CashFlowHighlight data={income?.edti?.creditBreakdown} forYear={income?.edti?.EDTI}/>
                    :
                    topNav === "salary" ?
                    <SalaryHighlight sweep={income?.edti?.creditBreakdown?.aveSweepMonthlyCred} highlight={income?.edti?.salary} />
                    :
                    topNav === "patterns" ?
                    <TransactionPatterns data={income?.edti?.creditBreakdown} forYear={income?.edti?.EDTI} />
                    :
                    null
                }
            </div>
        </div>
    )
}

export default InsightDetails;