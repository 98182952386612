import React, { useEffect, useState } from 'react'
import { useSteps } from 'react-step-builder'
import Header from './Header'
import { FormikProvider, useFormik } from 'formik'
import { TextField } from 'components/Form/TextField'
import Button from 'components/shared/Button'
import mark from "images/svg/recova/mark.svg";
import loaddot from "images/gif/load-dots.gif";
import link from "images/svg/recova/link.svg";
import { useSelector } from 'react-redux'
import { SelectField } from 'components/Form/SelectField'
import { RecovaService } from 'services/recova.service'
import { currencyFormatter, handleRequestErrors } from 'utils/helpers'
import { useRecovaSetup } from 'hooks/redux.hook'
import { toast } from 'react-toastify'
import { IdentityService } from 'services/identity.service'
import { BillingService } from 'services/billing.service'


const AutomatedActivation = () => {
    const { jump } = useSteps();
    const [isLoading, setIsLoading] = useState(false);
    const ngBanks = useSelector((state) => state.banksData.banks);
    const {updateSetupDispatch, recovaSetupData} = useRecovaSetup()
    const [price, setPrice] = useState(0)
    const filter = ngBanks?.filter(x => x?.code === recovaSetupData?.linkedAccount?.bankCode) || [];

    const form = useFormik({
        initialValues: {
            bank:  filter?.[0] || null,
            accountNumber: recovaSetupData?.linkedAccount?.accountNumber || "",
            accountName: recovaSetupData?.linkedAccount?.accountName || "",
        },
        onSubmit: (values) => {
            setIsLoading(true)
            handleLinkAcc(values)
        }
    })

    const handleLinkAcc = (values) => {
        const payload = JSON.stringify({
            accountName: values.accountName,
            accountNumber: values.accountNumber,
            bankName: values.bank.name,
            bankCode: values.bank.code
        })
        RecovaService.linkAccount(payload)
            .then(response=> response.data)
            .then(res => {
                console.log(res)
                setIsLoading(false)
                if(res.status){
                    updateSetupDispatch({
                        currentStep: 11,
                        linkedAccount: {
                            accountName: values.accountName,
                            accountNumber: values.accountNumber,
                            bankName: values.bank.name,
                            bankCode: values.bank.code
                        }
                    })
                    jump(11)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                handleRequestErrors(err, true)
                setIsLoading(false)
            });
    }

    useEffect(()=>{
        if(!!form.values.bank && form.values.accountNumber.length===10){
            IdentityService.basicBankVerification(form.values.accountNumber, form.values.bank.code)
                .then(response=> response.data)
                .then(res => {
                    if(res.status){
                        form.setFieldValue("accountName", res.data.account_name);
                    }
                })
                .catch((err) => {
                    handleRequestErrors(err)
                });
        } else {
            form.setFieldValue("accountName", "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values.bank, form.values.accountNumber])

    useEffect(()=>{
        let payload = {
            action: "account number basic verification"
        }
        BillingService.getBillingPricing(payload)
            .then(response=> response.data)
            .then(res => {
                console.log(res)
                if(res.success){
                    setPrice(res.data.price)
                }
            }).catch(err=>{})
    },[])

    return (
        <div className="rounded-3xl px-2.5 py-4 sm:px-0">
            <Header progress={30} back={()=> {
                    jump(5)
                    updateSetupDispatch({
                        currentStep: 5
                    })
                }}
            />

            <h2 className='text-xl font-bold leading-5'>
                Automated settlement
            </h2>
            <p className='mt-2'>
                Add your preferred bank account to receive automated payouts,
                instant notifications can also be sent via email and webhook
                service
            </p>

            <div
            style={{boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", border: `1px solid #D9D9D9`}}
            className='px-3 py-3 rounded-2xl my-4 box-border'
            >
                <h2 className='text-sm font-bold w-7/12 mt-2.5'>
                    Fill in correctly the bank details
                    to enable  automated settlement
                </h2>
                <p className='text-cc-red-1 mt-1 mb-4 text-xs '>Note: You will be charged {currencyFormatter(price, 0)} for Account name verification in order to succesfully link an account.</p>
                <FormikProvider value={form}>
                    <form onSubmit={form.handleSubmit}>
                        <SelectField
                            name='bank'
                            options={ngBanks.map(item => ({
                                value: item,
                                label: item.name
                            }))}
                            searchable
                            value={form?.values?.bank?.name || null}
                            label="Bank"
                            placeholder="Select bank"
                            onChange={(selected)=>{
                                form.setFieldValue( "bank", selected.value )
                            }}
                            required
                        />
                        <TextField
                            label="Account Number"
                            name='accountNumber'
                            type='text'
                            placeholder="Account Number"
                            required
                        />
                        <div className='relative'>
                            <TextField
                                required
                                label="Account Name"
                                name='accountName'
                                type='text'
                                disabled
                            />
                            { 
                                !!form.values.bank && form.values.accountNumber.length===10 &&
                                <img src={loaddot} alt="load" className='top-10 w-[20px] right-[10px] absolute' /> 
                            }
                            { !!form.values.accountName && <img src={mark} alt="mark" className='top-10 w-[20px] right-[10px] absolute' /> }
                        </div>
                        <Button
                            isValid={!!form.values.accountName}
                            isLoading={isLoading}
                            type="submit"
                            text="Link Account"
                            iconPosition="left"
                            icon={<img src={link} alt="link" className='w-[20px] mr-1' />}
                        />
                    </form>
                </FormikProvider>
            
            </div>
        </div>
    )
}

export default AutomatedActivation