import { APIKit } from "services";
import { getPublicKey } from "utils/helpers";


const timeout = 15000

export const IdentityService = {
    uploadBorrowerStatement: (payload) => {
        return APIKit(60000).post(`/identity/borrower/upload-statement`, payload, {
            headers: {
                token: `${getPublicKey()}`,
                "Content-Type": "multipart/form-data",
            }
        })
    },

    updateIdentityData: (borrowerId, payload) => {
        return APIKit(15000).post(`/identity/update-phone/${borrowerId}`, payload, {
            headers: {
                token: `${getPublicKey()}`
            }, 
        })
    },

    businessPolicy: (payload) => {
        return APIKit(15000).post(`/identity/update-business-policy-and-consent-agreement`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },

    getBusinessForm: (appLink) => {
        return APIKit(15000).get(`/identity/widget/show-form-data?appLink=${appLink}`)
    },
    
    submitGuarantorDetails: (payload) => {
        return APIKit(15000).post(`/identity/guarantor/consent`, payload)
    },
    
    grantBorrowerConsent: (payload) => {
        return APIKit(15000).post(`/identity/borrower/business-consent`, payload)
    },
    
    getBorrowers: () => {
        return APIKit(15000).get(`/identity/widget/borrowers`,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    checkIdentity: (bvn) => {
        return APIKit(15000).get(`/identity/check?bvn=${bvn}`,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    shareLinkToMail: (payload) => {
        return APIKit(15000).post(`/identity/share-link-via-email`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    doBVNVerification: (bvn) => {
        return APIKit(15000).post(`/identity/verifyData?bvn=${bvn}`, {},
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    doNINVerification: (nin) => {
        return APIKit(15000).post(`/identity/verifyData?nin=${nin}`, {},
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },

    doPassportVerification: (passport_number, last_name) => {
        return APIKit(15000).post(`/identity/verifyData?passport_number=${passport_number}&last_name=${last_name}`, {},
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    doCACVerification: (rc_number) => {
        return APIKit(15000).post(`/identity/verifyData?rc_number=${rc_number}`, {},
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    doBasicCAC: (payload) => {
        return APIKit(15000).post(`/identity/basic-cac`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    doBankVerification: (accNo, code) => {
        return APIKit(15000).post(`/identity/verifyData?account_number=${accNo}&bank_code=${code}`, {},
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    basicBankVerification: (accNo, code) => {
        return APIKit(15000).post(`/identity/account-verification?account_number=${accNo}&bank_code=${code}`, {},
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    doLicenseVerification: (data) => {
        return APIKit(30000).post(`/identity/verifyData?drivers_license=${data.drivers_license}&dob=${data.dob}&first_name=${data.first_name}&last_name=${data.last_name}`, {},
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    doVotersVerification: (cardNo, lastName, state) => {
        return APIKit(15000).post(`/identity/verifyData?card_number=${cardNo}&last_name=${lastName}&state=${state}`, {},
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    getVerifiedHistory: (type) => {
        return APIKit(15000).get(`/identity/userData/history/${type}`,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    bvnIgreeVerification: (payload) => {
        return APIKit(50000).post(`/identity/bvn-verifcation/igree`, payload, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    customerBVNIgree: (businessId, payload) => {
        return APIKit(50000).post(`/identity/customerBvn-verifcation/igree`, payload, {
            headers: {
                businessId: `${businessId}`
            }
        })
    },

    getComplianceContact: (businessId) => {
        return APIKit(timeout).get(`/identity/compliance/${businessId}/contact`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    getComplianceDocuments: (businessId) => {
        return APIKit(timeout).get(`/identity/compliance/${businessId}/document`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    getComplianceProfile: (businessId) => {
        return APIKit(timeout).get(`/identity/compliance/${businessId}/profile`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    sendComplianceProfile: (payload) => {
        return APIKit(timeout).post(`/identity/compliance/profile`, payload, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    sendComplianceContact: (payload) => {
        return APIKit(timeout).post(`/identity/compliance/contact`, payload, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    sendComplianceDocuments: (payload) => {
        return APIKit(timeout).post(`/identity/documents-compliance`, payload, {
            headers: {
                token: `${getPublicKey()}`,
                "Content-Type": "multipart/form-data",
            }
        })
    },
}