import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import GenerateModal from "components/Modal/GenerateReport";
import ConfirmAction from "components/Modal/ConfirmAction";
import NoCreditData from "components/Modal/NoCreditData";
import { BillingService } from "services/billing.service";
import { CreditService } from "services/credit.service";
import { useSelector } from "react-redux";
import Table from "components/Table";
import { Mixpanel } from "lib/mixpanel";
import clsx from "clsx";
import { currencyFormatter, dateFormatter, handleRequestErrors, timeFormatter } from "utils/helpers";

import view from "images/svg/income/view.svg";
import reload from "images/svg/income/reload.svg";
import deleteIcon from "images/svg/income/delete.svg";
import deleteIconInactive from "images/svg/income/deleteInactive.svg";
import { FormikProvider, useFormik } from "formik";
import { TextField } from "components/Form/TextField";
import { SelectField } from "components/Form/SelectField";
import Button from "components/shared/Button";
import { PiWarningCircleBold } from "react-icons/pi";
import { standAloneCreditInsightSchema } from "utils/validationSchemas";


export default function CRC ({rolePermissions}) {
    let history = useNavigate();
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [disableAction, setDisbleAction] = useState(false);
    const [insightLoading, setInsightLoading] = useState(true);
    const [showNoCredit, setShowNoCredit] = useState(false);
    const [price, setPrice] = useState("");
    const [permissionValues, setPermissionValues] = useState([]);
    const b2b_data = useSelector(state => state?.loginData?.data);
    const [formValues, setFormValues] = useState(null);
    const [searchParams] = useSearchParams();
    const path = searchParams.get("tab")

    useEffect(() => {
        rolePermissions?.map((item) => {
            let key = Object.keys(item)
            if(key[0] === "home"){
                let values = Object.values(item)
                setPermissionValues(values[0])
            }
            return key
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rolePermissions])

    useEffect(()=>{
        setInsightLoading(true);
        doHistoryFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[path, searchTerm])

    const doHistoryFetch = () => {
        CreditService.getCreditHistory("crc")
            .then((response) => response.data)
            .then((res) => {
                setInsightLoading(false);
                if (res.status === true) {
                    const filteredData = res.data?.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
                    filteredData?.sort(function(a,b){
                        return new Date(b.searchedDate) - new Date(a.searchedDate);
                    })

                    setData(filteredData)

                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setInsightLoading(false)
                setData([])
                handleRequestErrors(err)
            });
    }

    const showCreditFromHistory = (item) => {
        Mixpanel.track(`View credit insight button was clicked`, {
            name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
            bussinessName: b2b_data?.businessName
        });
        if(item?.score){
            console.log(item.score)
            sessionStorage.setItem( "report", JSON.stringify({ 
                data: item?.scorePremium?.totalNoOfLoans ? item?.scorePremium : item?.score,
                name: item?.name,
                gender: item?.gender,
                phone: item?.phone,
                address: item?.address,
                dob: item?.dateOfBirth,
                bvn: item?.bvn
            })); 
            history(`/credit-insight/customer/details/${path}?type=${
                    item?.scorePremium?.totalNoOfLoans ? "full": "summary"
                }`)
        } else {
            toast.info("Report hasn't been generated for this user")
        }
    }

    const deleteChange = () => {
        let payload = {
            type: path.toLowerCase(),
            bvn: formValues.bvn
        }
        CreditService.deleteCreditData(payload)
            .then(response =>response.data)
            .then(res => {
                if(res.status === true){
                    setShowDeleteModal(false);
                    setDisbleAction(false);
                    toast.success("Deleted Successfully!")
                    doHistoryFetch()
                }
            })
            .catch((err) => {
                setShowDeleteModal(false);
                setDisbleAction(false);
                handleRequestErrors(err)
            });
    }

    const handleChange = (e) => {
        setSearchTerm(e.target.value)
    }

    const form = useFormik({
        initialValues: {
            reportType: "",
            bvn: "",
        },
        validationSchema: standAloneCreditInsightSchema, 
        onSubmit: (values) => {
            setFormValues(values)
            setShowModal(true)
        }
    })

    useEffect(() => {
        getPrice()
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values.reportType])

    const getPrice = () => {
        let payload = {
            action: (form.values.reportType==="summary") ? "credit crc" :
                    "credit crc full report"
        }
        BillingService.getBillingPricing(payload)
            .then(response=> response.data)
            .then(res => {
                if(res.success === true){
                    setPrice(res.data.price)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                handleRequestErrors(err, true)
            });
    }

    const callCreditService = (bvn, reportType) => {
        CreditService.getCRCReport(bvn, reportType)
            .then((response) => response.data)
            .then((res) => {
                setShowModal(false);
                setDisbleAction(false);
                if (res.status === true) {
                    sessionStorage.setItem( "report", JSON.stringify({ 
                        data: res?.data?.score,
                        name: res?.data?.name,
                        gender: res?.data?.gender,
                        phone: res?.data?.phone,
                        address: res?.data?.address,
                        dob: res?.data?.dateOfBirth,
                        bvn: bvn
                    })); 
                    toast.success("Request Successful!!!")
                    setTimeout( function (){
                        history(`/credit-insight/customer/details/${path}?type=${reportType}`)
                    }, 100);
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setShowModal(false);
                setDisbleAction(false);
                handleRequestErrors(err, true)
                if(err?.response?.status===404){
                    setShowNoCredit(true)
                } else {
                    toast.error(err.response.data.message);
                }
            });
        
    }

    return (
        <div>
            <div className="max-w-[500px] box-border mt-10">
                <FormikProvider value={form}>
                    <form onSubmit={form.handleSubmit}>
                        <SelectField
                            name='reportType'
                            options={[
                                {
                                    name: "Summary",
                                    value: "summary"
                                },
                                {
                                    name: "Full Report",
                                    value: "full"
                                },
                            ]?.map(item=>({
                                label: item.name,
                                value: item.value
                            }))}
                            required
                            onChange={(selected)=>{
                                form.setFieldValue( "reportType", selected.value )
                            }}
                            value={form?.values?.reportType}
                            placeholder="Select Report Type"
                        />
                        <div className="flex gap-2.5">
                            <div className="box-border w-full">
                                <TextField
                                    name='bvn'
                                    type='text'
                                    placeholder="Enter BVN"
                                    extraClassName="!-mt-0"
                                />
                            </div>
                            <Button
                                isValid={true}
                                isLoading={false}
                                type="submit"
                                fullwidth={false}
                                text="Search"
                                className="bg-cc-primary !m-0 !px-6 !py-1 h-10"
                            />
                        </div>
                        <div className="flex items-start gap-x-1.5 ml-0.5 -mt-[15px]">
                            <PiWarningCircleBold className="scale-[1.5] text-cc-red-1 mt-0.5" />
                            <p className="text-sm">There is a fixed minimum price for using CRC service whether or not data is returned from the credit bureau.</p>
                        </div>
                    </form>
                </FormikProvider>
            </div>
            <div>
                <div className="home_main-topText mt-[30px]">
                    <h5>History</h5>
                    <div className="home_main-topText-search" >
                        <p>List of previous Credit Insights</p>
                    </div>
                </div>
                <Table
                    tableTitle={"Credit Insights"}
                    searchPlaceHolder={"Search by customer name"}
                    onSearchChange={handleChange}
                    data={data}
                    loading={insightLoading}
                    hideSearch={false}
                    trClassName="!border-none"
                    theadClassName="!border-none"
                    tableClassName="bg-[#F9FAFB] 2xl:min-w-[1200px]"
                    rows={[
                        {
                            header:'CUSTOMER NAME',
                            view: (item) => (
                                <span className="uppercase py-[10px] pl-[10px] pr-[5px]">
                                    {item?.name}
                                </span>
                            ),
                        },
                        {
                            header: 'REPORT TYPE',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px] pr-[5px]">
                                    {item?.scorePremium?.totalNoOfLoans ? "Full":"Summary"}
                                </span>
                            ),
                        },
                        {
                            header:'BVN',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px] pr-[5px]">
                                    {item?.bvn}
                                </span>
                            ),
                        },
                        {
                            header:'DATE',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px] pr-[5px]">
                                    {dateFormatter(item?.searchedDate||item?.score?.lastUpdatedAt, 'dd/mm/yyyy')}, {timeFormatter(item?.searchedDate||item?.score?.lastUpdatedAt)}
                                </span>
                            ),
                        },
                        {
                            header:'ACTION',
                            view: (item) => (
                                <div className="flex py-2.5">
                                    <button 
                                        className="text-[0.8em] px-1.5 py-0.5 text-white border rounded-[4px] flex items-center justify-center bg-cc-green-4 mr-2.5 w-[80px] h-8" 
                                        onClick={()=>{
                                            showCreditFromHistory(item)
                                        }}
                                    >
                                        <img src={view} alt="view" className="w-[16px] mr-1" />
                                        <p>View</p>
                                    </button>
                                    <button 
                                        className={clsx(
                                            "text-[0.8em] px-1.5 py-0.5 text-white border rounded-[4px] flex items-center justify-center mr-2.5 w-[120px] h-8",
                                            permissionValues?.includes("edit") ? 'bg-cc-primary':'bg-cc-grey-3', 
                                        )}
                                        onClick={()=>{
                                            setFormValues({
                                                ...formValues,
                                                bvn: item.bvn
                                            })
                                            permissionValues?.includes("edit") ? setShowModal(true) : toast.error("You are not authorized to perform this action!!!")
                                        }}
                                    >
                                        <img src={reload} alt="reload" className="w-[16px] mr-1" />
                                        <p>Reload Data</p>
                                    </button>
                                    <img 
                                        className="cursor-pointer"
                                        onClick={()=>{
                                            setFormValues({
                                                ...formValues,
                                                bvn: item.bvn
                                            })
                                            permissionValues?.includes("edit") ? setShowDeleteModal(true) : toast.error("You are not authorized to perform this action!!!")
                                            Mixpanel.track(`Delete credit insight button was clicked`, {
                                                name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
                                                bussinessName: b2b_data?.businessName
                                            });
                                        }} 
                                        src={permissionValues?.includes("edit")?deleteIcon:deleteIconInactive} 
                                        alt="delete" 
                                    />
                                </div>
                            ),
                        },
                    ]}
                />
            </div>
            <GenerateModal 
                show={showModal}
                type={"generate-report"} 
                cancel={()=>setShowModal(false)} 
                data={price}
                doAction={()=>{
                    callCreditService(formValues.bvn, formValues.reportType);
                    setDisbleAction(true);
                }} 
                reportType={path}
                message={`Please be informed that this action attracts a charge of ${currencyFormatter(price, 2, "NGN")} from your eWallet. This also include if the assessment is successful or not`}
                disableAction={disableAction}
            />
            <ConfirmAction
                show={showDeleteModal}
                onCancel={()=>setShowDeleteModal(false)}
                onAction={()=>{
                    deleteChange()
                    setDisbleAction(true);
                }}
                type="delete"
                loading={disableAction}
                title="Delete Credit Report?"
                content="Are you sure you want to delete this borrower’s credit record? This is an irreversible action, and would require you to perform a fresh assessment for this borrower."
            />
            <NoCreditData 
                type={'individual'}
                show={showNoCredit}
                cancel={()=>setShowNoCredit(false)}
            />
        </div>
    )
}