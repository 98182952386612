import { createSlice } from '@reduxjs/toolkit'



export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: {
    show: false,
    triggerModal: false, // only if last feedback was made above 3 months ago
    source: "",
  },
  reducers: {
    setShow: (state, action) => {
      return { ...state, show: action.payload}
    },
    setTriggerModal: (state, action) => {
      return { ...state, triggerModal: action.payload}
    },
    setFeedbackSource: (state, action) => {
      return { ...state, source: action.payload}
    },
  },
})

export const { setShow, setTriggerModal, setFeedbackSource } = feedbackSlice.actions

export default feedbackSlice.reducer