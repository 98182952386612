import React, {  useState } from "react";
import FlipImg from "images/svg/card/flip.svg";
import creditChek from "images/svg/apps/creditChek.svg";
import LineChart from "./LineChart";
import { currencyFormatter } from "utils/helpers";


function CreditCard({ color, ngBanks, creditData }) {
  const [flipped, setFlipped] = useState("");

  var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  var today = new Date();
  var d;
  var month = [];
  // let monthSlice = [];

  for(var i = 6; i > 0; i -= 1) {
    d = new Date(today.getFullYear(), today.getMonth() - i, 1);
    month.push(monthNames[d.getMonth()])
  }

  const flipCard = (id) => {
    if(id===flipped){
      setFlipped("")
    } else {
      setFlipped(id)
    }
  }

  return  creditData && creditData?.items.map((item, idx) => (
        <div className={`credit-card`} style={{margin:"0 15px 0 0"}} key={idx}>
        
          <div className={`flip-container ${flipped===item._id && "hover"}`}>
         
            <div className={`flipper`}>
              
              <div className="front artist-1">
                <div className="credit-card-content">
               
                    <div className="income_cards-front" style={{background:`${color}`}}>
                          <div style={{display:"flex"}}>
                          <div className="income_cards-front-logo">
                            <img src={ngBanks.find(o => o.code === item?.institution?.bankCode)?.logo?.url ?? creditChek} alt="banklogo" width="40px" />
                          </div>
                            <div className="income_cards-front-text">
                                <h4>{item?.institution?.name||"---"}</h4>
                                <p>{item?.accountNumber||"---"}</p>
                                <p>{item?.name||"---"}</p>
                            </div>
                         
                      </div> 
                      {
                        item?.balance ? 
                        <div>
                            <h4 style={{margin:"40px 0 0 0", fontSize:"2.1em"}}>{currencyFormatter(item?.balance/100, 2, "NGN")}</h4>
                            <div style={{display:"flex"}}>
                                <p>TOTAL BALANCE</p>
                                <p style={{marginLeft:"auto"}}>as at {item?.updatedAt.substring(0, 10)}</p>
                            </div>
                        </div>
                        :
                        <p className="mt-[60px] text-lg text-cc-red-3 w-[200px]">Error processing this bank statement</p>
                      } 
                   </div>
                </div>
              </div>
              <div className="back">
                <div> 
                    <div className="income_cards-front" style={{background:`${color}`}}>
                      {
                        item?.balance ? 
                        <LineChart
                          item={item}
                          month ={month}
                        />
                        :
                        <></>
                      }
                    </div>
                </div>
              </div>
              

            </div>
           
      </div>
   
          {/* <div style={{height:"250px", zIndex:"20"}}></div> */}
          {true && (
            <div className="flip-img-wrapper" onClick={()=>flipCard(item._id)}>
              <img alt="" src={FlipImg} />
            </div>
          )}
        
        </div>
        ))
}

export default CreditCard;
