import { useState, useEffect } from "react";
import ChooseChannel from "../ChooseChannel";
import ShareIgreeLink from "../ShareIgreeLink";
import ConfirmAction from "components/Modal/ConfirmAction";
import { toast } from "react-toastify";
import { logToBin } from "lib/logs";
import { BillingService } from "services/billing.service";
import { IdentityService } from "services/identity.service";
import { Mixpanel } from "lib/mixpanel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { currencyFormatter } from "utils/helpers";


export default function BVNInterface ({permissionValues}) {
    let history = useNavigate();
    const [bvn, setBVN] = useState("");
    const [bvnIGree, setBVNIGree] = useState("");
    const [showModal, setShowModal] = useState(false)
    const [disableAction, setDisableAction] = useState(false);
    const [bvnPrice, setBvnPrice] = useState('');
    const [showChannels, setShowChannels] = useState(false);
    const [mode, setMode] = useState("INITIATE");
    const [channelList, setChannelList] = useState([]);
    const [showShareLink, setShowShareLink] = useState(false);
    const b2b_data = useSelector(state => state?.loginData?.data)
  
    const handleSubmit = () => {
      Mixpanel.track(`Identity Insight (Verify BVN) button was clicked`, {
        name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
        bussinessName: b2b_data?.businessName
    });
      if(permissionValues?.includes("edit")){
        if(bvn.length===11 || bvnIGree.length===11){
          setShowModal(true);
        } else {
          toast.error("BVN must be 11 characters long!")
        }
      }else{
        toast.error("You are not authorized to perform this action!!!")
      }
    }
  
    useEffect(() => {
        let payload = {
            action: "identity bvn verification"
        }
        BillingService.getBillingPricing(payload)
        .then(response=> response.data)
        .then(res => {
            if(res.success === true){
                setBvnPrice(res.data.price)
            } else {
                toast.error(res.message)
            }
        })
        .catch((err) => {
            if(err.response){
                logToBin(err.response.data, window.location.href);
                // toast.error(err.response.data.error.message);
            } else{
                if(err.message === "timeout of 15000ms exceeded"){
                    toast.error('Request timed out, please try again!')
                } else if (err.message==="Network Error"){
                    toast.error('Network error, please make sure you are connected to the internet!')
                } else{
                    toast.error("Your request can't be processed at this time, please try again later!");
                }
            }
        });
    }, [])
  
    const doSubmit = () => {
      IdentityService.doBVNVerification(bvn)
          .then((response) => response.data)
          .then((res) => {
              setShowModal(false);
              setDisableAction(false);
              if (res.status === true) {
                sessionStorage.setItem("identity", JSON.stringify(res.data))
                  toast.success("Request Successful!!!")
                  setTimeout( function (){
                      history(`/identity/bvn-verification`);
                  }, 100);
              } else {
                  toast.error(res.message);
              }
          })
          .catch((err) => {
              setShowModal(false)
              setDisableAction(false);
              if(err.response){
                logToBin(err.response.data, window.location.href);
                  toast.error(err.response.data.message);
              } else{
                if(err.message === "timeout of 15000ms exceeded"){
                    toast.error('Request timed out, please try again!')
                } else if (err.message==="Network Error"){
                    toast.error('Network error, please make sure you are connected to the internet!')
                } else{
                    toast.error("Your request can't be processed at this time, please try again later!");
                }
              }
          });
    }
  
    const handleiGreeSubmit = (payload) => {
      IdentityService.bvnIgreeVerification(payload)
        .then((response) => response.data)
        .then((res) => {
            setShowModal(false);
            setDisableAction(false);
            if (res.status === true) {
              if(mode==="INITIATE"){
                setShowModal(false)
                setShowChannels(true)
                setMode('SET_CHANNEL')
                setChannelList(res.data.contacts)
              } else if (mode==="SET_CHANNEL") {
                setMode('FINALIZE')
              } else {
                sessionStorage.setItem("identity", JSON.stringify(res.data))
                toast.success("Request Successful!!!");
                history(`/identity/bvn-verification`);
              }
            } else {
                toast.error(res.message);
            }
        })
        .catch((err) => {
          setDisableAction(false);
          if(err.response){
            logToBin(err.response.data, window.location.href);
              toast.error(err.response.data.message);
          } else{
            if(err.message === "timeout of 15000ms exceeded"){
                toast.error('Request timed out, please try again!')
            } else if (err.message==="Network Error"){
                toast.error('Network error, please make sure you are connected to the internet!')
            } else{
                toast.error("Your request can't be processed at this time, please try again later!");
            }
          }
        });
    }
    
    return(
        <div className="box-border">
            <div className="md_min:flex">
            <div className="box-border w-[400px] md:w-full md_min:mb-0 md_min:mr-[100px] mb-[30px]">
                <label className="mt-2.5">Input Bank Verification Number</label><br/>
                <input className="apps_create-input" type="text" placeholder="BVN" onChange={(e)=>{setBVNIGree('');setBVN(e.target.value);}} /><br/>
                <button className={permissionValues?.includes("edit")?"apps_create-button":"apps_create-btnInactive"} onClick={()=>handleSubmit()}>Verify BVN</button>
            </div>
            <div className="box-border w-[400px] md:w-full md_min:mb-0 mb-[30px]">
                <label className="mt-2.5">Verify with BVN iGree</label><br/>
                <input className="apps_create-input" type="text" placeholder="BVN" onChange={(e)=>{setBVN('');setBVNIGree(e.target.value);}} /><br/>
                <button className={permissionValues?.includes("edit")?"apps_create-button":"apps_create-btnInactive"} onClick={()=>handleSubmit()}>Next</button>
                <p className="cursor-pointer text-sm -mt-3 text-cc-primary" onClick={()=>setShowShareLink(true)}>Share Link to your customers</p>
            </div>
            </div>
            <ConfirmAction
                show={showModal}
                onCancel={()=>setShowModal(false)}
                onAction={()=>{
                    if(bvn===""){
                    let payload = JSON.stringify({
                        bvn: bvnIGree,
                        mode: "INITIATE",
                    })
                    handleiGreeSubmit(payload)
                    setDisableAction(true);
                    } else {
                    doSubmit()
                    setDisableAction(true);
                    }
                }}
                loading={disableAction}
                title="BVN Verification"
                content={`Using the BVN service would attract a charge of ${currencyFormatter(bvnPrice)}.`}
            />
            <ChooseChannel
                show={showChannels}
                mode={mode}
                channels={channelList}
                onClose={()=>{
                    setDisableAction(false);
                    setShowChannels(false)
                    setMode("INITIATE")
                }}
                onSubmit={(payload)=>handleiGreeSubmit(payload)}
                bvn={bvnIGree}
            />
            <ShareIgreeLink
                show={showShareLink}
                onClose={()=>{
                    setShowShareLink(false)
                }}
            />
        </div>
    )
  }