import { useState, useEffect } from "react";
import ConfirmAction from "components/Modal/ConfirmAction";
import { toast } from "react-toastify";
import { logToBin } from "lib/logs";
import { BillingService } from "services/billing.service";
import { IdentityService } from "services/identity.service";
import { Mixpanel } from "lib/mixpanel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TypeSelect from "components/Form/TypeSelect";
import {ng_states} from "constants/lga";
import { currencyFormatter } from "utils/helpers";


export default function VotersCard ({permissionValues}) {
    let history = useNavigate();
    const [cardNo, setCardNo] = useState("");
    const [cardPrice, setCardPrice] = useState("");
    const [lastName, setLastName] = useState("");
    const [state, setState] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [disableAction, setDisableAction] = useState(false);
    const b2b_data = useSelector(state => state?.loginData?.data)
  
    const handleSubmit = () => {
      if(permissionValues?.includes("edit")){
        Mixpanel.track(`Identity Insight (Verify Voters Card) button was clicked`, {
        name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastname}`,
        bussinessName: b2b_data?.businessName
    });
      if(cardNo!==""&&lastName!==""&&state!==""){
          setShowModal(true);
        } else {
          toast.error("No empty fields allowed!")
        }
      }else{
        toast.error("You are not authorized to perform this action!!!")
      }
    }
  
    useEffect(() => {
      let payload = {
        action: "pvc verification"
      }
      BillingService.getBillingPricing(payload)
      .then(response=> response.data)
      .then(res => {
          if(res?.success === true){
            setCardPrice(res?.data?.price)
          } else {
              toast.error(res.message)
          }
      })
      .catch((err) => {
          if(err.response){
            logToBin(err.response.data, window.location.href);
              // toast.error(err.response.data.error.message);
          } else{
            if(err.message === "timeout of 15000ms exceeded"){
                toast.error('Request timed out, please try again!')
            } else if (err.message==="Network Error"){
                toast.error('Network error, please make sure you are connected to the internet!')
            } else{
                toast.error("Your request can't be processed at this time, please try again later!");
            }
          }
      });
  }, [])
  
    const doSubmit = () => {
        IdentityService.doVotersVerification(cardNo, lastName, state)
            .then((response) => response.data)
            .then((res) => {
                setShowModal(false)
                if (res.status === true) {
                  sessionStorage.setItem("identity", JSON.stringify(res.data))
                    toast.success("Request Successful!!!")
                    setTimeout( function (){
                        history(`/identity/pvc-verification`)
                    }, 100);
                } else {
                    toast.error(res.message)
                    setDisableAction(false)
                }
            })
            .catch((err) => {
                setShowModal(false)
                setDisableAction(false)
                if(err.response){
                  logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                  if(err.message === "timeout of 15000ms exceeded"){
                      toast.error('Request timed out, please try again!')
                  } else if (err.message==="Network Error"){
                      toast.error('Network error, please make sure you are connected to the internet!')
                  } else{
                      toast.error("Your request can't be processed at this time, please try again later!");
                  }
                }
            });
    }
  
    return(
      <div className="box-border">
        <div className="settings_profile-form w-[400px] md:w-full md_min:mb-0 mb-[30px]">
          <label>Card Number</label><br/>
          <input className="apps_create-input" type="text" placeholder="Card Number" onChange={(e)=>setCardNo(e.target.value)}/><br/>
          <label>State</label><br/>
          <div className="auth_signup" style={{marginTop:'0px'}}>
            <TypeSelect 
              placeholder={"Select State"}
              options={ng_states}
              arrayType={"countryObject"}
              filled={true}
              selectChange={(item, id)=>setState(item)}
            />
          </div>
          <label>Last Name</label><br/>
          <input className="apps_create-input" type="text" placeholder="Last Name" onChange={(e)=>setLastName(e.target.value)}/><br/>
          {/* <input className="apps_create-input" type="text" placeholder="State" onChange={(e)=>setState(e.target.value)}/><br/> */}
          <button className={permissionValues?.includes("edit")?"apps_create-button":"apps_create-btnInactive"} onClick={()=>handleSubmit()}>Verify Voter's Card</button>
        </div>
        <ConfirmAction
          show={showModal}
          onCancel={()=>setShowModal(false)}
          onAction={()=>{
            doSubmit()
            setDisableAction(true);
          }}
          loading={disableAction}
          title={`Voter's Card Verification`}
          content={`Using this service would attract a charge of ${currencyFormatter(cardPrice)}.`}
        />
      </div>
    )
}