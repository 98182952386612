import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { currencyFormatter, getDateFromDayinYear } from "utils/helpers";

import coins from "../../images/pdf-export/coins.png";
import money from "../../images/pdf-export/money.png";
import profit from "../../images/pdf-export/profit.png";
import debt from "../../images/pdf-export/debt.png";

const months=['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const jsPDFGenerator = (creditData, income, edtiData) => {
    const doc = new jsPDF({
        unit: "mm",
        format: [210, 297]
    });

    // format data to fit
    let EDTIResult = Object.keys(edtiData.edti.EDTI).map((key) => [key, edtiData.edti.EDTI[key]]);
    let balanceResult = Object.keys(edtiData.edti.balance).map((key) => [key, edtiData.edti.balance[key]]);
    let recurrentResult = Object.keys(edtiData.edti.recurrent_expenses_debt).map((key) => [key, edtiData.edti.recurrent_expenses_debt[key]]);
    let spending = Object.keys(edtiData.edti.spendingAnalysis).map((key) => [key, edtiData.edti.spendingAnalysis[key]]);

    doc.setFont("DMSans-Regular", "normal", "bold");
    doc.setFillColor('#0046E6');
    doc.roundedRect(55, 95, 100, 10, 1, 1, 'F');
    doc.setTextColor("#ffffff");
    doc.setFont("DMSans-Regular", "normal", "normal");
    doc.text(`${creditData?.items[0]?.name}`, 105, 102, {align:"center"});
    doc.setTextColor("#000")
    doc.text(`Income Report - ${new Date().getDate()} ${months[new Date().getMonth()]}, ${new Date().getFullYear()}`, 105, 115, {align:"center"});
    doc.text(`Total months analysed - ${income?.edti?.number_of_active_months}`, 105, 122, {align:"center"});
    doc.setFont("DMSans-Regular", "normal", "bold")
    doc.text(`ACCOUNTS CONNECTED`, 105, 135, {align:"center"});
    doc.setFontSize(14)
    doc.setFont("DMSans-Regular", "normal", "normal");
    
    if (creditData.items.length===1){
      doc.text(`Bank Name - ${creditData.items[0].institution.name}`, 105, 145, {align:"center"});
      doc.text(`Account Number - ${creditData.items[0].accountNumber}`, 105, 151, {align:"center"});
      doc.text(`Balance - ${currencyFormatter(creditData.items[0].balance/100, 2, "NGN")}`, 105, 157, {align:"center"});
    } else if (creditData.items.length===2){
      doc.text(`Bank Name - ${creditData.items[0].institution.name}`, 30, 145);
      doc.text(`Account Number - ${creditData.items[0].accountNumber}`, 30, 151);
      doc.text(`Balance - ${currencyFormatter(creditData.items[0].balance/100, 2, "NGN")}`, 30, 157);
      doc.text(`Bank Name - ${creditData.items[1].institution.name}`, 120, 145);
      doc.text(`Account Number - ${creditData.items[1].accountNumber}`, 120, 151);
      doc.text(`Balance - ${currencyFormatter(creditData.items[1].balance/100, 2, "NGN")}`, 120, 157);
    } else if (creditData.items.length===3){
      doc.text(`Bank Name - ${creditData.items[0].institution.name}`, 30, 145);
      doc.text(`Account Number - ${creditData.items[0].accountNumber}`, 30, 151);
      doc.text(`Balance - ${currencyFormatter(creditData.items[0].balance/100, 2, "NGN")}`, 30, 157);
      doc.text(`Bank Name - ${creditData.items[1].institution.name}`, 120, 145);
      doc.text(`Account Number - ${creditData.items[1].accountNumber}`, 120, 151);
      doc.text(`Balance - ${currencyFormatter(creditData.items[1].balance/100, 2, "NGN")}`, 120, 157);
      doc.text(`Bank Name - ${creditData.items[2].institution.name}`, 30, 170);
      doc.text(`Account Number - ${creditData.items[2].accountNumber}`, 30, 176);
      doc.text(`Balance - ${currencyFormatter(creditData.items[2].balance/100, 2, "NGN")}`, 30, 181);
    } else {
      doc.text(`Bank Name - ${creditData.items[0].institution.name}`, 30, 145);
      doc.text(`Account Number - ${creditData.items[0].accountNumber}`, 30, 151);
      doc.text(`Balance - ${currencyFormatter(creditData.items[0].balance/100, 2, "NGN")}`, 30, 157);
      doc.text(`Bank Name - ${creditData.items[1].institution.name}`, 120, 145);
      doc.text(`Account Number - ${creditData.items[1].accountNumber}`, 120, 151);
      doc.text(`Balance - ${currencyFormatter(creditData.items[1].balance/100, 2, "NGN")}`, 120, 157);
      doc.text(`Bank Name - ${creditData.items[2].institution.name}`, 30, 170);
      doc.text(`Account Number - ${creditData.items[2].accountNumber}`, 30, 176);
      doc.text(`Balance - ${currencyFormatter(creditData.items[2].balance/100, 2, "NGN")}`, 30, 181);
      doc.text(`Bank Name - ${creditData.items[3].institution.name}`, 120, 170);
      doc.text(`Account Number - ${creditData.items[3].accountNumber}`, 120, 176);
      doc.text(`Balance - ${currencyFormatter(creditData.items[3].balance/100, 2, "NGN")}`, 120, 181);
    }

    
    var imgDebt = new Image();
    imgDebt.src = debt;
    var imgProfit = new Image();
    imgProfit.src = profit;
    var imgMoney = new Image();
    imgMoney.src = money;
    var imgCoins = new Image();
    imgCoins.src = coins;

    doc.addPage()
    doc.setFont("DMSans-Regular", "normal", "bold")
    doc.setFontSize(20)
    doc.addImage(imgProfit, 'png', 20, 15, 15, 15);
    doc.text(`Income Insight`, 40, 20);
    doc.setFontSize(14)
    doc.setFont("DMSans-Regular", "normal", "normal")
    doc.text(`${creditData?.items[0]?.name}`, 40, 28);

    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.text(`Debt Burden Ratio`, 20, 50);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${Math.round(income?.edti?.average_monthly_edti)}%`, 20, 60);

    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Average Monthly Income`, 77, 50);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${currencyFormatter(parseInt(income?.edti?.average_monthly_income)/100, 2, "NGN")}`, 77, 60);
        
    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Total Money Received`, 134, 50);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${income?.edti?.totalMoneyReceive ? currencyFormatter(parseInt(income?.edti?.totalMoneyReceive)/100, 2, "NGN") : currencyFormatter(parseInt(income?.edti?.totalMoneyReceived)/100, 2, "NGN")}`, 134, 60);

    
    doc.setDrawColor("#D6D6D6");
    doc.line(20,65, 190,65)

    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Total Money Spent`, 20, 80);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${currencyFormatter(parseInt(income?.edti?.totalMoneySpent)/100, 2, "NGN")}`, 20, 90);

    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Average Monthly Reccuring`, 77, 80);
    doc.text(`Expense`, 77, 84);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${currencyFormatter(parseInt(income?.edti?.average_monthly_recurring_debt_expenses/100), 2, "NGN")}`, 77, 90);
    
    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Recommended Loan Amount`, 134, 80);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${currencyFormatter(parseInt(income?.edti?.eligibleAmount)/100, 2, "NGN")}`, 134, 90);
    
    doc.setFontSize(16)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "bold")
    doc.text(`DBR Summary`, 20, 110);
    doc.setFont("Inter", "normal", "normal");
    doc.setTextColor("#101828")
    doc.setFontSize(14)
    var strArr = doc.splitTextToSize(`${income?.edti?.DTI_reason}`, 170)
    doc.text(strArr, 20, 115);

    doc.line(20,95, 190,95)

    // Debt Burden Ratio
    doc.addPage()
    doc.setFont("DMSans-Regular", "normal", "bold")
    doc.setFontSize(20)
    doc.addImage(imgDebt, 'png', 20, 15, 15, 15);
    doc.text(`Debt-Burden-Ratio (%)`, 40, 20);
    doc.setFontSize(14)
    doc.setFont("DMSans-Regular", "normal", "normal")
    doc.text(`${creditData?.items[0]?.name}`, 40, 28);
    autoTable(doc, {
        styles: {minCellHeight: 15, valign: 'middle', cellWidth: 50, textColor: "#667085"},
        theme: 'grid',
        margin: { top: 35 },
        bodyStyles: {lineColor: "#EAECF0"},
        headStyles: { fillColor: "#CED4DE", textColor: "white" },
        columnStyles: { 0: { textColor: "#667085" } },
          head: [['Month', 'DBR from percentage']],
          body: EDTIResult.map((item)=>[
            item[0], `${item[1]}%`
          ])
    })

    // Monthly Sitting Balance

    const convertObject = (data_) => {
      const objKeys = income?.edti?.creditBreakdown?.monthlyCredit ? Object.keys(income?.edti?.creditBreakdown[`${data_}`]) : null;
      const objVals = income?.edti?.creditBreakdown?.monthlyCredit ? Object.values(income?.edti?.creditBreakdown[`${data_}`]) : null;
      let result = {
          monthYear: objKeys, 
          amount: objVals
      };
      return result;
    }
    
    const monthly_Credit = (idx) => {
      return convertObject('monthlyCredit')?.amount?.reverse()?.[idx];
    }
    const monthly_Debit = (idx) => {
      return convertObject('monthlyDebit')?.amount?.reverse()?.[idx];
    }

    doc.addPage()
    doc.setFont("DMSans-Regular", "normal", "bold")
    doc.setFontSize(20)
    doc.addImage(imgCoins, 'png', 20, 15, 15, 15);
    doc.text(`Monthly Sitting Balance`, 40, 20);
    doc.setFontSize(14)
    doc.setFont("DMSans-Regular", "normal", "normal")
    doc.text(`${creditData?.items[0]?.name}`, 40, 28);
    autoTable(doc, {
        styles: {minCellHeight: 10, valign: 'middle', textColor: "#667085"},
        theme: 'grid',
        margin: { top: 35 },
        bodyStyles: {lineColor: "#EAECF0"},
        headStyles: { fillColor: "#CED4DE", textColor: "white" },
        columnStyles: { 0: { textColor: "#667085" } },
          head: [['Month', 'End of Month Balance', 'Debit', 'Credit']],
          body: balanceResult.reverse().map((item, index)=>[
            item[0], `${currencyFormatter(item[1]/100, 2, "NGN")}`, `${currencyFormatter(monthly_Debit(index)/100, 2, "NGN")}`, `${currencyFormatter(monthly_Credit(index)/100, 2, "NGN")}`
          ])
    })

    // Debt-Burden-Ratio (amount)
    doc.addPage()
    doc.setFont("DMSans-Regular", "normal", "bold")
    doc.setFontSize(20)
    doc.addImage(imgMoney, 'png', 20, 15, 15, 15);
    doc.text(`Monthly Recurrent Expenses/Debt`, 40, 20);
    doc.setFontSize(14)
    doc.setFont("DMSans-Regular", "normal", "normal")
    doc.text(`${creditData?.items[0]?.name}`, 40, 28);
    autoTable(doc, {
        styles: {minCellHeight: 15, valign: 'middle', cellWidth: 50, textColor: "#667085"},
        theme: 'grid',
        margin: { top: 35 },
        bodyStyles: {lineColor: "#EAECF0"},
        headStyles: { fillColor: "#CED4DE", textColor: "white" },
        columnStyles: { 0: { textColor: "#667085" } },
          head: [['Month', 'Amount']],
          body: recurrentResult.map((item)=>[
            item[0], `${currencyFormatter(item[1]/100, 2, "NGN")}`
          ])
    })

    // Spending Categories
    doc.addPage()
    doc.setFont("DMSans-Regular", "normal", "bold")
    doc.setFontSize(20)
    doc.addImage(imgMoney, 'png', 20, 15, 15, 15);
    doc.text(`Spending Categories`, 40, 20);
    doc.setFontSize(14)
    doc.setFont("DMSans-Regular", "normal", "normal")
    doc.text(`${creditData?.items[0]?.name}`, 40, 28);
    autoTable(doc, {
        styles: {minCellHeight: 15, valign: 'middle', cellWidth: 50, textColor: "#667085"},
        theme: 'grid',
        margin: { top: 35 },
        bodyStyles: {lineColor: "#EAECF0"},
        headStyles: { fillColor: "#CED4DE", textColor: "white" },
        columnStyles: { 0: { textColor: "#667085" } },
          head: [['Category', 'Amount']],
          body: spending.map((item)=>[
            item[0].toUpperCase(), `${currencyFormatter(item[1][0]/100, 2, "NGN")}`
          ])
    })
    
    function gettheyear (forYear) {
      const firstMonth = Object.entries(forYear)?.reverse()?.[0]?.[0];
      const getYear = firstMonth.substring(firstMonth.length-4, firstMonth.length)
      return getYear
    }

    // Cashflow highlights
    doc.addPage()
    doc.setFont("DMSans-Regular", "normal", "bold")
    doc.setFontSize(20)
    doc.addImage(imgMoney, 'png', 20, 15, 15, 15);
    doc.text(`Cashflow Highlight`, 40, 20);
    doc.setFontSize(14)
    doc.setFont("DMSans-Regular", "normal", "normal")
    doc.text(`${creditData?.items[0]?.name} - (${income?.edti?.number_of_active_months} months)`, 40, 28);

    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.text(`Maximum Deposit Week`, 20, 50);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`Week ${income?.edti?.creditBreakdown?.max_depo_week_list_dict?.[0] || 'not available'}`, 20, 60);

    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Maximum Deposit Day`, 77, 50);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${getDateFromDayinYear(gettheyear(income?.edti?.EDTI), income?.edti?.creditBreakdown?.max_depo_day_list_dict?.[0] || 0)}`, 77, 60);
        
    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Maximum Balance Week`, 134, 50);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`Week ${income?.edti?.creditBreakdown?.max_bal_week_list_dict?.[0] || 'not available'}`, 134, 60);
    
    doc.setDrawColor("#D6D6D6");
    doc.line(20,65, 190,65)

    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Maximum Balance Day`, 20, 80);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${getDateFromDayinYear(gettheyear(income?.edti?.EDTI), income?.edti?.creditBreakdown?.max_bal_day_list_dict?.[0] || 0)}`, 20, 90);

    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Minimum Balance Week`, 77, 80);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`Week ${income?.edti?.creditBreakdown?.min_bal_week_list_dict?.[0] || 'not available'}`, 77, 90);
    
    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Minimum Balance Day`, 134, 80);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${getDateFromDayinYear(gettheyear(income?.edti?.EDTI), income?.edti?.creditBreakdown?.min_bal_days_list_dict?.[0] || 0)}`, 134, 90);
    
    doc.setDrawColor("#D6D6D6");
    doc.line(20,95, 190,95)

    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Number Of Negative Balance`, 20, 110);
    doc.text(`(Days)`, 20, 114);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${income?.edti?.creditBreakdown?.negBalDays || 0}`, 20, 120);

    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Percentage Credit on`, 77, 110);
    doc.text(`Transactions`, 77, 114);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${Math.round(income?.edti?.creditBreakdown?.perCredTrans || 0)}%`, 77, 120);
    
    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Percentage Debit on`, 134, 110);
    doc.text(`Transactions`, 134, 114);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${Math.round(income?.edti?.creditBreakdown?.perDebTrans || 0)}%`, 134, 120);
    
    doc.setDrawColor("#D6D6D6");
    doc.line(20,125, 190,125)

    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Average Daily Balance`, 20, 140);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${currencyFormatter(income?.edti?.creditBreakdown?.averageDailyBal/100 || 0, 2, "NGN")}`, 20, 150);


    // Salary Highlight
    doc.addPage()
    doc.setFont("DMSans-Regular", "normal", "bold")
    doc.setFontSize(20)
    doc.addImage(imgMoney, 'png', 20, 15, 15, 15);
    doc.text(`Salary Highlight`, 40, 20);
    doc.setFontSize(14)
    doc.setFont("DMSans-Regular", "normal", "normal")
    doc.text(`${creditData?.items[0]?.name} - (${income?.edti?.number_of_active_months} months)`, 40, 28);
    autoTable(doc, {
        styles: {textColor: "#667085"},
        theme: 'grid',
        margin: { top: 35 },
        bodyStyles: {lineColor: "#EAECF0"},
        headStyles: { fillColor: "#CED4DE", textColor: "white" },
        columnStyles: { 0: { textColor: "#667085" } },
          head: [['Narration', 'Amount']],
          body: income?.edti?.salary?.amount?.map((item, idx)=>[
            income?.edti?.salary?.narration?.[idx], `${currencyFormatter(item/100, 2, "NGN")}`
          ])
    })


    // Transaction Patterns
    doc.addPage()
    doc.setFont("DMSans-Regular", "normal", "bold")
    doc.setFontSize(20)
    doc.addImage(imgMoney, 'png', 20, 15, 15, 15);
    doc.text(`Transaction Patterns`, 40, 20);
    doc.setFontSize(14)
    doc.setFont("DMSans-Regular", "normal", "normal")
    doc.text(`${creditData?.items[0]?.name} - (${income?.edti?.number_of_active_months} months)`, 40, 28);

    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.text(`Highest Deposit`, 20, 50);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${currencyFormatter(income?.edti?.creditBreakdown?.maxCredit/100 || 0, 2, "NGN")}`, 20, 60);

    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Average Deposit`, 77, 50);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${currencyFormatter(income?.edti?.creditBreakdown?.meanCredit/100 || 0, 2, "NGN")}`, 77, 60);
        
    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Lowest Deposit`, 134, 50);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${currencyFormatter(income?.edti?.creditBreakdown?.min_crebit/100 || 0, 2, "NGN")}`, 134, 60);
    
    doc.setDrawColor("#D6D6D6");
    doc.line(20,65, 190,65)

    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Highest Withdrawal`, 20, 80);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${currencyFormatter(income?.edti?.creditBreakdown?.maxDebit/100 || 0, 2, "NGN")}`, 20, 90);

    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Average Withdrawal`, 77, 80);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${currencyFormatter(income?.edti?.creditBreakdown?.meanDebit/100 || 0, 2, "NGN")}`, 77, 90);
    
    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Lowest Withdrawal`, 134, 80);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${currencyFormatter(income?.edti?.creditBreakdown?.minDebit/100 || 0, 2, "NGN")}`, 134, 90);
    
    doc.setDrawColor("#D6D6D6");
    doc.line(20,95, 190,95)

    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Highest Balance`, 20, 110);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${getDateFromDayinYear(gettheyear(income?.edti?.EDTI), income?.edti?.creditBreakdown?.max_bal_day_list_dict?.[0] || 0)}`, 20, 120);

    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Average Daily Balance`, 77, 110);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${currencyFormatter(income?.edti?.creditBreakdown?.averageDailyBal/100 || 0, 2, "NGN")}`, 77, 120);
    
    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Week With Lowest Balance`, 134, 110);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`Week ${income?.edti?.creditBreakdown?.min_bal_week_list_dict?.[0] || 'not available'}`, 134, 120);
   

    doc.save(`${creditData?.items[0]?.name}_income_report.pdf`);
}