import Back from "components/shared/back";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import GaugeChart from 'react-gauge-chart'
import Button from "components/shared/Button";
import { jsPDFGenerator } from "lib/pdfGenerate/scores";
import downloadScore from "images/svg/credit/downloadScore.svg"
import { useBilledPageExit } from "hooks/feedback.hook";



export default function Details ({setDetailName}) {
    const navigate = useNavigate();
    const params = useParams();

    let report = {};

    try{
        let details = sessionStorage.getItem("report");
        report = JSON.parse(details)
    } catch(e) {}

    useBilledPageExit("Credit Insight")

    useEffect(()=>{
        setDetailName(`
            ${params.type==="fico" ? "Fico Score"
            : "i-Score"}
        `)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setDetailName])

    return (
        <div className="mx-[30px] my-[50px]">
            <Back to={()=> navigate(-1)}/>
            <div id="score_page">
                <h6 className="text-[22px] font-bold">
                    {
                        params.type==="fico" ? "FICO Score":"i-Score" 
                    }
                </h6>
                {
                    params.type==="fico" ?
                    <div className="w-[500px] h-[350px] relative shadow-lg rounded-[15px] mt-[40px] mx-auto block">
                        <p className="text-[22px] font-semibold p-[20px] text-cc-primary">FICO Score</p>
                        <p className="absolute top-[30px] right-[20px] text-[40px]">
                            { report?.data?.ficoScore?.score || 0}
                        </p>
                        <GaugeChart id="gauge-chart2"
                            nrOfLevels={5} 
                            percent={(report?.data?.ficoScore?.score-300)/550 || 0} 
                            arcPadding={0} 
                            cornerRadius={0} 
                            colors={["#CC4409", "#E47E06", "#F9CF3B", "#66F34F", "#166609"]} 
                            arcsLength={[0.44, 0.22, 0.07, 0.09, 0.18]}
                            needleColor="#000" 
                            hideText={true}
                            animate={false}
                        />
                        <p className="absolute text-cc-grey-18 bottom-[65px] left-[70px]">300</p>
                        <p className="absolute text-cc-grey-18 top-[60px] left-[200px]">540</p>
                        <p className="absolute text-cc-grey-18 top-[75px] right-[150px]">660</p>
                        <p className="absolute text-cc-grey-18 top-[110px] right-[95px]">700</p>
                        <p className="absolute text-cc-grey-18 top-[150px] right-[60px]">750</p>
                        <p className="absolute text-cc-grey-18 bottom-[65px] right-[70px]">850</p>
                        <p className="absolute bottom-[10px] left-[10px] italic">Powered by CRC</p>
                    </div>
                    :
                    <div className="w-[500px] h-[350px] relative shadow-lg rounded-[15px] mt-[40px] mx-auto block">
                        <p className="text-[22px] font-semibold p-[20px]">i-Score</p>
                        <p className="absolute top-[30px] right-[20px] text-[40px]">{report?.data?.totalConsumerScore || 0}</p>
                        <GaugeChart id="gauge-chart2" 
                            nrOfLevels={5} 
                            percent={(report?.data?.totalConsumerScore-300)/600} 
                            arcPadding={0} 
                            cornerRadius={0} 
                            colors={["#CC4409", "#E47E06", "#F9CF3B", "#66F34F", "#166609"]} 
                            arcsLength={[0.4, 0.2, 0.07, 0.08, 0.25]}
                            needleColor="#000" 
                            hideText={true}
                            animate={false}
                        />
                        <p className="absolute text-cc-grey-18 bottom-[65px] left-[70px]">300</p>
                        <p className="absolute text-cc-grey-18 top-[75px] left-[170px]">540</p>
                        <p className="absolute text-cc-grey-18 top-[75px] right-[165px]">660</p>
                        <p className="absolute text-cc-grey-18 top-[90px] right-[125px]">700</p>
                        <p className="absolute text-cc-grey-18 top-[130px] right-[85px]">750</p>
                        <p className="absolute text-cc-grey-18 bottom-[65px] right-[70px]">900</p>
                        <p className="absolute bottom-[10px] left-[10px] italic">Powered by First Central</p>
                    </div>
                }
                <div className="max-w-[900px] mx-auto block mt-[100px]">
                    <div className="border-[1px] border-cc-primary rounded-[15px] p-[20px]">
                        <div className="grid grid-cols-4 gap-[20px]">
                            <div>
                                <p className="text-sm font-medium text-cc-grey-4">Full Name</p>
                                <p>{report?.name || "n/a"}</p>
                            </div>
                            <div>
                                <p className="text-sm font-medium text-cc-grey-4">Date of Birth</p>
                                <p>{report?.dob || "n/a"}</p>
                            </div>
                            <div>
                                <p className="text-sm font-medium text-cc-grey-4">Gender</p>
                                <p>{report?.gender || "n/a"}</p>
                            </div>
                            <div>
                                <p className="text-sm font-medium text-cc-grey-4">Phone Number</p>
                                <p>{report?.phone || "n/a"}</p>
                            </div>
                            {
                                params.type==="fico" &&
                                <>
                                    <div>
                                        <p className="text-sm font-medium text-cc-grey-4">Total No of Delinquent Facilities</p>
                                        <p>{report?.data?.totalNoOfDelinquentFacilities || "n/a"}</p>
                                    </div>
                                    <div>
                                        <p className="text-sm font-medium text-cc-grey-4">Has Loan</p>
                                        <p>{report?.data?.hasLoans || "n/a"}</p>
                                    </div>
                                </>
                            }
                            <div>
                                <p className="text-sm font-medium text-cc-grey-4">{params.type==="fico"?"FICO Score":"i-Score"}</p>
                                <p>{(params.type==="fico" ? report?.data?.ficoScore?.score : report?.data?.totalConsumerScore) || "n/a"}</p>
                            </div>
                            <div>
                                <p className="text-sm font-medium text-cc-grey-4">{params.type==="fico"?"Rating":"Description"}</p>
                                <p>{(params.type==="fico" ? report?.data?.ficoScore?.rating:report?.data?.description) || "n/a"}</p>
                            </div>
                        </div>
                        {
                            params.type==="fico" &&
                            <p className="text-[16px] mt-6"><span className="font-semibold">Reasons:</span> {report?.data?.ficoScore?.reasons}</p>
                        }
                    </div>
                    <div className="flex">
                        <div className="w-[180px] ml-auto mt-[20px]">
                            <Button
                                isValid={true}
                                type="submit"
                                text="Download PDF"
                                iconPosition="left"
                                icon={<img src={downloadScore} alt="downloadScore" className='w-[20px] mr-1' />}
                                onClick={()=>jsPDFGenerator(params?.type==="fico" ? "FICO Score":"i-Score")}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}