import jsPDF from "jspdf";

import { currencyFormatter, dateFormatter, timeFormatter } from "utils/helpers";

const months=['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


export const spectrumPDF = (data, type) => {
    const doc = new jsPDF({
        unit: "mm",
        format: [210, 297]
    });

    doc.setFont("DMSans-Regular", "normal", "bold");
    doc.setFillColor('#0046E6');
    doc.roundedRect(55, 115, 100, 10, 1, 1, 'F');
    doc.setTextColor("#ffffff");
    doc.setFont("DMSans-Regular", "normal", "normal");
    doc.text(`${data.name}`, 105, 122, {align:"center"});
    doc.setTextColor("#000")
    doc.text(`Spectrum Report - ${new Date().getDate()} ${months[new Date().getMonth()]}, ${new Date().getFullYear()}`, 105, 135, {align:"center"});


    doc.addPage()
    doc.setFont("DMSans-Regular", "normal", "bold")
    doc.setFontSize(20)
    doc.text(`Overview`, 20, 20);

    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.text(type==="individual"?"BVN#":"RC/BN#", 20, 50);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${type==="individual"?data?.BVN : data?.regNo}`, 20, 60);

    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(type==="individual"?"NIN":"Registered on", 77, 50);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${type==="individual"?data?.NIN : data?.dateOfRegistration===""?'Not available':data?.dateOfRegistration}`, 77, 60);
        
    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(type==="individual"?"Date of Birth":"Industry", 134, 50);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${type==="individual" ? data?.dateOfBirth : data?.industry===""?'Not available':data?.industry}`, 134, 60);

    
    doc.setDrawColor("#D6D6D6");
    doc.line(20,65, 190,65)

    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(type==="individual"?"Gender":"Business Type", 20, 80);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${type==="individual" ? data?.gender : data?.businessType===""?'Not available':data?.businessType}`, 20, 90);

    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Phone Number`, 77, 80);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${data?.phoneNumber?.[0]}`, 77, 90);
    
    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Ledger Id`, 134, 80);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${data?.ledgerId}`, 134, 90);
    
    doc.line(20,95, 190,95)
    
    doc.setFontSize(11.5)
    doc.setTextColor("#667085")
    doc.setFont("Inter", "normal", "normal")
    doc.text(`Email Address`, 20, 110);
    doc.setFont("Inter", "normal", "bold");
    doc.setTextColor("#101828")
    doc.setFontSize(16)
    doc.text(`${data?.email?.[0]}`, 20, 120);

    // eslint-disable-next-line array-callback-return
    data?.loans?.reverse().map((item)=>{
        doc.addPage()
        doc.setFont("DMSans-Regular", "normal", "bold")
        doc.setFontSize(16)
        doc.setTextColor("#101828")
        doc.text(`Version ${item.metadata.version} - (${dateFormatter(item.metadata.txTime, 'dd/mm/yyyy')}, ${timeFormatter(item.metadata.txTime)}) `, 20, 20);
        doc.setFontSize(13)
        doc.setTextColor("#05CD0D")
        doc.setFont("DMSans-Regular", "normal", "bold")
        doc.text("Performing Accounts", 20, 32);

        if(item?.data?.performingAccounts?.last7days!==undefined){
            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last 7 days", 20, 40);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.performingAccounts?.last7days}`, 20, 45);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last 30 days", 62, 40);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.performingAccounts?.last30days}`, 62, 45);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last 90 days", 104, 40);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.performingAccounts?.last90days}`, 104, 45);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("All Time", 146, 40);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.performingAccounts?.all_time}`, 146, 45);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Date First Reported", 20, 55);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${dateFormatter(item?.data?.performingAccounts?.dateFirstReported, 'dd-mm-yyyy')}`, 20, 60);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Guarantor", 62, 55);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.performingAccounts?.guarantors}`, 62, 60);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Total Loan Amount", 104, 55);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${currencyFormatter(item?.data?.performingAccounts?.totalLoanAmount, 0)}`, 104, 60);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Total Outstanding Balance", 146, 55);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${currencyFormatter(item?.data?.performingAccounts?.totalOutstandingBalance, 0)}`, 146, 60);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Total Amount Arrears", 20, 70);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${currencyFormatter(item?.data?.performingAccounts?.totalAmountInArrears, 0)}`, 20, 75);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last Updated At", 62, 70);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${dateFormatter(item?.data?.performingAccounts?.lastUpdatedAt, 'dd-mm-yyyy')}`, 62, 75);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Currency", 104, 70);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.performingAccounts?.currency}`, 104, 75);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Performance Status", 146, 70);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.performingAccounts?.performanceStatus}`, 146, 75);
            
            doc.setFontSize(11.5)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text("Repayments::", 20, 87);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last 7 days", 20, 95);
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.performingAccounts?.repaymentHistory?.last7days}`, 20, 100);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last 30 days", 62, 95);
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.performingAccounts?.repaymentHistory?.last30days}`, 62, 100);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last 90 days", 104, 95);
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.performingAccounts?.repaymentHistory?.last90days}`, 104, 100);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("All time", 146, 95);
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.performingAccounts?.repaymentHistory?.all_time}`, 146, 100);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Total Paid", 20, 110);
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.performingAccounts?.repaymentHistory?.total_repaid}`, 20, 115);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("First Repayment Date", 62, 110);
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.performingAccounts?.repaymentHistory?.firstRepaymentDate!=="1970-01-01T00:00:00.000Z" ? dateFormatter(item?.data?.performingAccounts?.repaymentHistory?.firstRepaymentDate, 'dd-mm-yyyy'):"---"}`, 62, 115);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last Repayment Date", 104, 110);
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.performingAccounts?.repaymentHistory?.lastRepaymentDate!=="1970-01-01T00:00:00.000Z" ? dateFormatter(item?.data?.performingAccounts?.repaymentHistory?.lastRepaymentDate, 'dd-mm-yyyy'):"---"}`, 104, 115);
        } else {
            doc.setTextColor("#667085")
            doc.setFontSize(14)
            doc.text(`Performing accounts summary is empty.`, 105, 50, {align:"center"});
        }

        doc.setFontSize(13)
        doc.setFont("DMSans-Regular", "normal", "bold")
        doc.setTextColor("#0046E6")
        doc.text("Delinquent Accounts", 20, 142);

        if(item?.data?.delinquentAccounts?.last7days!==undefined){
            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last 7 days", 20, 150);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.delinquentAccounts?.last7days}`, 20, 155);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last 30 days", 62, 150);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.delinquentAccounts?.last30days}`, 62, 155);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last 90 days", 104, 150);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.delinquentAccounts?.last90days}`, 104, 155);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("All Time", 146, 150);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.delinquentAccounts?.all_time}`, 146, 155);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Date First Reported", 20, 165);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${dateFormatter(item?.data?.delinquentAccounts?.dateFirstReported, 'dd-mm-yyyy')}`, 20, 170);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Guarantor", 62, 165);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.delinquentAccounts?.guarantors}`, 62, 170);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Total Loan Amount", 104, 165);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${currencyFormatter(item?.data?.delinquentAccounts?.totalLoanAmount, 0)}`, 104, 170);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Total Outstanding Balance", 146, 165);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${currencyFormatter(item?.data?.delinquentAccounts?.totalOutstandingBalance, 0)}`, 146, 170);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Total Amount Arrears", 20, 180);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${currencyFormatter(item?.data?.delinquentAccounts?.totalAmountInArrears, 0)}`, 20, 185);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last Updated At", 62, 180);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${dateFormatter(item?.data?.delinquentAccounts?.lastUpdatedAt, 'dd-mm-yyyy')}`, 62, 185);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Currency", 104, 180);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.delinquentAccounts?.currency}`, 104, 185);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Performance Status", 146, 180);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.delinquentAccounts?.performanceStatus}`, 146, 185);
            
            doc.setFontSize(11.5)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text("Repayments::", 20, 197);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last 7 days", 20, 205);
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.delinquentAccounts?.repaymentHistory?.last7days}`, 20, 210);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last 30 days", 62, 205);
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.delinquentAccounts?.repaymentHistory?.last30days}`, 62, 210);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last 90 days", 104, 205);
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.delinquentAccounts?.repaymentHistory?.last90days}`, 104, 210);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("All time", 146, 205);
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.delinquentAccounts?.repaymentHistory?.all_time}`, 146, 210);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Total Paid", 20, 220);
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.delinquentAccounts?.repaymentHistory?.total_repaid}`, 20, 225);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("First Repayment Date", 62, 220);
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.delinquentAccounts?.repaymentHistory?.firstRepaymentDate!=="1970-01-01T00:00:00.000Z" ? dateFormatter(item?.data?.delinquentAccounts?.repaymentHistory?.firstRepaymentDate, 'dd-mm-yyyy'):"---"}`, 62, 225);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last Repayment Date", 104, 220);
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.delinquentAccounts?.repaymentHistory?.lastRepaymentDate!=="1970-01-01T00:00:00.000Z" ? dateFormatter(item?.data?.delinquentAccounts?.repaymentHistory?.lastRepaymentDate, 'dd-mm-yyyy'):"---"}`, 104, 225);
        } else {
            doc.setTextColor("#667085")
            doc.setFontSize(14)
            doc.text(`Delinquent accounts summary is empty.`, 105, 160, {align:"center"});
        }

        doc.addPage()

        doc.setFontSize(13)
        doc.setTextColor("#FF2608")
        doc.setFont("DMSans-Regular", "normal", "bold")
        doc.text("Closed Accounts", 20, 32);

        if(item?.data?.closedAccounts?.last7days!==undefined){
            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last 7 days", 20, 40);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.closedAccounts?.last7days}`, 20, 45);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last 30 days", 62, 40);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.closedAccounts?.last30days}`, 62, 45);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last 90 days", 104, 40);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.closedAccounts?.last90days}`, 104, 45);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("All Time", 146, 40);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.closedAccounts?.all_time}`, 146, 45);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Date First Reported", 20, 55);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${dateFormatter(item?.data?.closedAccounts?.dateFirstReported, 'dd-mm-yyyy')}`, 20, 60);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Guarantor", 62, 55);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.closedAccounts?.guarantors}`, 62, 60);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Total Loan Amount", 104, 55);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${currencyFormatter(item?.data?.closedAccounts?.totalLoanAmount, 0)}`, 104, 60);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Total Outstanding Balance", 146, 55);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${currencyFormatter(item?.data?.closedAccounts?.totalOutstandingBalance, 0)}`, 146, 60);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Total Amount Arrears", 20, 70);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${currencyFormatter(item?.data?.closedAccounts?.totalAmountInArrears, 0)}`, 20, 75);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last Updated At", 62, 70);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${dateFormatter(item?.data?.closedAccounts?.lastUpdatedAt, 'dd-mm-yyyy')}`, 62, 75);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Currency", 104, 70);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.closedAccounts?.currency}`, 104, 75);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Performance Status", 146, 70);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.closedAccounts?.performanceStatus}`, 146, 75);
            
            doc.setFontSize(11.5)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text("Repayments::", 20, 87);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last 7 days", 20, 95);
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.closedAccounts?.repaymentHistory?.last7days}`, 20, 100);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last 30 days", 62, 95);
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.closedAccounts?.repaymentHistory?.last30days}`, 62, 100);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last 90 days", 104, 95);
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.closedAccounts?.repaymentHistory?.last90days}`, 104, 100);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("All time", 146, 95);
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.closedAccounts?.repaymentHistory?.all_time}`, 146, 100);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Total Paid", 20, 110);
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.closedAccounts?.repaymentHistory?.total_repaid}`, 20, 115);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("First Repayment Date", 62, 110);
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.closedAccounts?.repaymentHistory?.firstRepaymentDate!=="1970-01-01T00:00:00.000Z" ? dateFormatter(item?.data?.closedAccounts?.repaymentHistory?.firstRepaymentDate, 'dd-mm-yyyy'):"---"}`, 62, 115);

            doc.setFontSize(10)
            doc.setTextColor("#667085")
            doc.text("Last Repayment Date", 104, 110);
            doc.setTextColor("#101828")
            doc.setFontSize(13)
            doc.text(`${item?.data?.closedAccounts?.repaymentHistory?.lastRepaymentDate!=="1970-01-01T00:00:00.000Z" ? dateFormatter(item?.data?.closedAccounts?.repaymentHistory?.lastRepaymentDate, 'dd-mm-yyyy'):"---"}`, 104, 115);
        } else {
            doc.setTextColor("#667085")
            doc.setFontSize(14)
            doc.text(`Closed accounts summary is empty.`, 105, 50, {align:"center"});
        }

    })

    doc.save(`${data?.name}_spectrum_report.pdf`);
}