import { useEffect, useState } from "react";
import FloatDetails from "./FloatDetails";
import { Routes, Route, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { logToBin } from "../../../../lib/logs";
import ConfirmAction from "components/Modal/ConfirmAction";
import { Modal } from "react-bootstrap";
import tick from "../../../../images/svg/float/tick.svg";
import floatSuccess from "../../../../images/svg/float/float-success.svg";
import Director2 from "./Director2";
import Director1 from "./Director1";
import { BillingService } from "services/billing.service";
import { FloatService } from "services/float.service";
import Back from "components/shared/back";
import { useSelector } from "react-redux";
import { currencyFormatter, dateFormatter, handleRequestErrors } from "utils/helpers";
import clsx from "clsx";
import MandateConsent from "./MandateConsent";
import { useFeedbackModal } from "hooks/feedback.hook";


function NewRequest () {
    let navigate = useNavigate();
    const [path, setPath] = useState('details');
    const [showSuccess, setShowSuccess] = useState(false);
    const [price, setPrice] = useState(0);
    const [showPriceModal, setShowPriceModal] = useState(false);
    const [disableAction, setDisableAction] = useState(false);
    const [actionType, setActionType] = useState(false);
    const [verification, setVerification] = useState({
        rc: 'none', //processing, success, failed or none
        acc: 'none',
        bvn1: 'none',
        acc1: 'none',
        cons1: 'none',
        bvn2: 'none',
        acc2: 'none',
        cons2: 'none'
    })
    const ngBanks = useSelector(state => state?.banksData?.banks)
    const { showFeedback } = useFeedbackModal()

    const [searchParams] = useSearchParams();

    const amountType= searchParams.get("amountType") ?? "naira";
    const b2b_data = useSelector(state => state?.loginData?.data)

    let floatId='';

    try {
        floatId = JSON.parse(sessionStorage.getItem('floatId'))
    } catch (error) {
        console.log(error)
    }

    const [data, setData] = useState({
        floatId,
        businessId: b2b_data?.user?.businessId,
        rcNumber: "",
        dateOfRegistration: "",
        loanAmount: "",
        loanDuration: "",
        bankAccountNumber:"",
        businessBankDetail: {},
        accountName: "",
        bankName: "",
        password:"",
        bankStatement: null,
        director1Fullname: "",
        director1BankAccountNumber: "",
        director1BankName: "",
        director1BVN: "",
        director1Position: "",
        director1Phone: "",
        director1Email: "",
        director1Address: "",
        director1Passport: null,
        director2Fullname: "",
        director2BankAccountNumber: "",
        director2BankName: "",
        director2BVN: "",
        director2Position: "",
        director2Phone: "",
        director2Email: "",
        director2Address: "",
        director2Passport: null,
        amountType: amountType
    }) 

    useEffect(()=>{
        FloatService.getBusinessDirectors(b2b_data?.user?.businessId)
            .then(response => response.data)
            .then(resp =>{
                if(resp.status === 'success'){
                    console.log(resp.data)
                    if(resp.data.rcNumber !== null && resp.data !== 'first request' ){
                        setData({
                            ...data,
                            rcNumber: resp?.data?.rcNumber || "",
                            bankName: resp?.data?.businessBankDetail?.bank_name || "",
                            bankAccountNumber: resp?.data?.businessBankDetail?.account_number || "",
                            director1Fullname: resp?.data?.directors1?.fullName || "",
                            director1BVN: resp?.data?.directors1?.bvn || "",
                            director1BankAccountNumber: resp?.data?.directors1?.bankAccountNumber || "",
                            director1BankName: resp?.data?.directors1?.bankName || "",
                            director1Email: resp?.data?.directors1?.email || "",
                            director1Phone: resp?.data?.directors1?.phone || "",
                            director1Position: resp?.data?.directors1?.position || "",
                            businessBankDetail: resp?.data?.businessBankDetail || "",
                            director2Fullname: resp?.data?.director2?.fullName || "",
                            director2BVN: resp?.data?.director2?.bvn || "",
                            director2BankAccountNumber:resp?.data?.director2?.bankAccountNumber || "",
                            director2BankName:resp?.data?.director2?.bankName || "",
                            director2Email: resp?.data?.director2?.email || "",
                            director2Phone:resp?.data?.director2?.phone || "",
                            director2Position:resp?.data?.director2?.position || "",
                        })
                        setVerification({
                            ...verification,
                            rc: resp?.data?.rcNumber?'success':'none',
                            acc: resp?.data?.businessBankDetail?.account_number?'success':'none',
                            bvn1: resp?.data?.directors1?.fullName?'success':'none',
                            acc1: resp?.data?.directors1?.fullName?'success':'none',
                            bvn2: resp?.data?.director2?'success':"none",
                            acc2: resp?.data?.director2?'success':"none",
                            cons1: resp?.data?.director1?.consent ? "success":"none",
                            cons2: resp?.data?.director2?.consent ? "success":"none",
                        })
                    }
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(()=>{
        try{
            // set the data if user has filled the form before
            setData({
                ...data,
                floatId: JSON.parse(sessionStorage.getItem("floatId"))
            });
        } catch {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getPrice = (action) => {
        let payload = {
          action: action 
        }
        setActionType(action==='identity cac verification'?'rcFloat':action==='account number basic verification'?'accFloat':action==='float request'?'floatRequest':'bvnFloat')
        BillingService.getBillingPricing(payload)
            .then(response=> response.data)
            .then(res => {
                if(res.success === true){
                    setPrice(res.data.price)
                    setShowPriceModal(true)
                } else {
                    toast.error(res.message)
                    setShowPriceModal(false)
                }
            })
            .catch((err) => {
                console.log(err)
                handleRequestErrors(err, true)
            });
    }

    //cac, bvn & bank account verification
    const doSubmit = () => {
        //cac verification
        setShowPriceModal(false)
        if(actionType === 'rcFloat') {
            setVerification({
                ...verification,
                rc:'processing'
            })
            let payload = {
                    floatId,
                    identityUser:"CAC",
                    rcNumber: data?.rcNumber
                }
                
                FloatService.CACVerification(payload)
                .then((response) => response.data)
                .then((res) => {
                    setDisableAction(false)
                    if (res.status === 'success') {
                        setVerification({
                            ...verification,
                            rc:'success'
                        })
                            sessionStorage.setItem('floatVerification', JSON.stringify({rc:'success'}))
                        
                    } else {
                        sessionStorage.setItem('floatVerification', JSON.stringify({rc:'failed'}))
                        setVerification({
                            ...verification,
                            rc:'failed'
                        })
                    }
                })
                .catch((err) => {
                    setDisableAction(false)
                    sessionStorage.setItem('floatVerification', JSON.stringify({rc:'failed'}))
                    setVerification({
                        ...verification,
                        rc:'failed'
                    })
                    if(err.response){
                        logToBin(err.response.data, window.location.href);
                    }
                });
        }
     
        //bank account verification
        if(actionType === 'accFloat') {
            setVerification({
                ...verification,
                acc:'processing'
            })
            let bankcode;
            let payload;
            if(path === 'details'){
                bankcode = ngBanks.find(o => o.name === data?.bankName)?.code;
                payload = {
                    floatId,
                    identityUser:"businessBankDetail",
                    bankAccountNumber: data?.bankAccountNumber,
                    bankcode
                } 
            }else if(path === 'director1'){
                bankcode = ngBanks.find(o => o.name === data?.director1BankName)?.code;
                payload = {
                    floatId,
                    identityUser:"director1BankDetail",
                    bankAccountNumber: data?.director1BankAccountNumber,
                    bankcode
                } 
            } else if(path === 'director2'){
                bankcode = ngBanks.find(o => o.name === data?.director2BankName)?.code;
                payload = {
                    floatId,
                    identityUser:"director2BankDetail",
                    bankAccountNumber: data?.director2BankAccountNumber,
                    bankcode
                } 
            }
            FloatService.bankAccountVerification(payload)
                .then((response) => response.data)
                .then((res) => {
                    setDisableAction(false)
                    if (res.status === "success") {
                        toast.success('Successful')
                        console.log(res.data)
                       if(path === "details"){
                           setData({
                               ...data,
                               businessBankDetail: res.data
                           })
                       }
                        path === 'details' ? 
                        setVerification({
                            ...verification,
                            acc:'success'
                        }) : path === 'director1' ? 
                        setVerification({
                            ...verification,
                            acc1:'success'
                        }) : 
                        setVerification({
                            ...verification,
                            acc2:'success'
                        }) 
                        
                    } else {
                        toast.error(res.message)
                        path === 'details' ? 
                        setVerification({
                            ...verification,
                            acc:'failed'
                        }) : path === 'director1' ? 
                        setVerification({
                            ...verification,
                            acc1:'failed'
                        }) : 
                        setVerification({
                            ...verification,
                            acc2:'failed'
                        }) 
                    }
                })
                .catch((err) => {
                    setDisableAction(false)
                    handleRequestErrors(err)
                    path === 'details' ? setVerification({
                        ...verification,
                        acc:'failed'
                    }) : path === 'director1' ? setVerification({
                        ...verification,
                        acc1:'failed'
                    }) : setVerification({
                        ...verification,
                        acc2:'failed'
                    })
                });
            }

        //bvn verification
        if(actionType === 'bvnFloat') {
            let payload;
            setVerification({
                ...verification,
                [`bvn${path==='director1'?"1":"2"}`]:'processing'
            })
            payload = {
                floatId,
                identityUser: path==='director1'?"director1BVN":"director2BVN",
                bvn: path==='director1'?data?.director1BVN:data?.director2BVN
            }
        
            FloatService.bvnVerification(payload)
                .then((response) => response.data)
                .then((res) => {
                    setDisableAction(false)
                    if (res.status === 'success') {
                        toast.success('Successful')
                        if(path=== 'director1'){
                            setVerification({
                                ...verification,
                                bvn1:'success'
                            })
                        }else{
                            setVerification({
                                ...verification,
                                bvn2:'success'
                            })
                        }
                    } else {
                        toast.error(res.message)
                        if(path=== 'director1'){
                            setVerification({
                                ...verification,
                                bvn1:'failed'
                            })
                        }else{
                            setVerification({
                                ...verification,
                                bvn2:'failed'
                            })
                        }
                    }
                })
                .catch((err) => {
                    setDisableAction(false)
                    if(err.response){
                        logToBin(err.response.data, window.location.href);
                        toast.error(err.response.data.message);
                        if(path=== 'director1'){
                            setVerification({
                                ...verification,
                                bvn1:'failed'
                            })
                        }else{
                            setVerification({
                                ...verification,
                                bvn2:'failed'
                            })
                        }
                    } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                        if(path=== 'director1'){
                            setVerification({
                                ...verification,
                                bvn1:'failed'
                            })
                        }else{
                            setVerification({
                                ...verification,
                                bvn2:'failed'
                            })
                        }
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                        if(path=== 'director1'){
                            setVerification({
                                ...verification,
                                bvn1:'failed'
                            })
                        }else{
                            setVerification({
                                ...verification,
                                bvn2:'failed'
                            })
                        }
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                        if(path=== 'director1'){
                            setVerification({
                                ...verification,
                                bvn1:'failed'
                            })
                        }else{
                            setVerification({
                                ...verification,
                                bvn2:'failed'
                            })
                        }
                    }
                    }
                });
        }
    }

    const handleDirectorConsent = (values) => {
        let payload = {
            businessName: b2b_data?.businessName,
            floatId,
            rcNumber: data.rcNumber,
            businessEmail: b2b_data?.user?.email,
            amount: data.loanAmount,
            directorType: path,
            directorBVN: path === 'director1' ? values.director1BVN : values.director2BVN,
            directorBankName: path === 'director1' ? values.director1BankName : values.director2BankName,
            directorPosition: path === 'director1' ? values.director1Position : values.director2Position,
            directorPhoneNumber: path === 'director1' ? values.director1Phone : values.director2Phone,
            directorEmail: path === 'director1' ? values.director1Email : values.director2Email,
            directorFullname: path === 'director1' ? values.director1Fullname : values.director2Fullname,
            directorBankAccountNumber: path === 'director1' ? values.director1BankAccountNumber : values.director2BankAccountNumber,
    }
        if(path === 'director1'){
            if(!values.director1BVN || !values.director1Position || !values.director1BankName || !values.director1Phone || 
                !values.director1Email || !values.director1Fullname || !values.director1BankAccountNumber ){
                    toast('Please fill all options above')
                }else{
                    console.log(payload)
                    FloatService.floatDirectorConsent(payload)
                    .then((response) => response.data)
                    .then((res) => {
                        setDisableAction(false)
                        if (res.status === 'success') {
                            toast.success('Director 1 email consent sent')
                            if(path==='director1'){
                                setVerification({
                                    ...verification,
                                    cons1: 'success',
                                })
                            }else if(path==='director2'){
                                setVerification({
                                    ...verification,
                                    cons2: 'success',
                                })
                            }
                        } else {
                            toast.error(res.message)
                        }
                    })
                    .catch((err) => {
                        setDisableAction(false)
                        if(err.response){
                            logToBin(err.response.data, window.location.href);
                            toast.error(err.response.data.message);
                          
                        } else{
                        if(err.message === "timeout of 15000ms exceeded"){
                            toast.error('Request timed out, please try again!')
                          
                        } else if (err.message==="Network Error"){
                            toast.error('Network error, please make sure you are connected to the internet!')
                           
                        } else{
                            toast.error("Your request can't be processed at this time, please try again later!");
                          
                        }
                        }
                    });
                }
        } 
         if(path === 'director2'){
            if(!values.director2BVN || !values.director2Position || !values.director2BankName || !values.director2Phone || 
                !values.director2Email || !values.director2Fullname || !values.director2BankAccountNumber  ){
                    toast('Please fill all options above')
                }else{
                    FloatService.floatDirectorConsent(payload)
                    .then((response) => response.data)
                    .then((res) => {
                        setDisableAction(false)
                        if (res.status === 'success') {
                            toast.success('Director 2 Consent email sent')
                            if(path==='director1'){
                                setVerification({
                                    ...verification,
                                    cons1: 'success',
                                })
                            }else if(path==='director2'){
                                setVerification({
                                    ...verification,
                                    cons2: 'success',
                                })
                            }
                        } else {
                            toast.error(res.message)
                        }
                    })
                    .catch((err) => {
                        setDisableAction(false)
                        if(err.response){
                            logToBin(err.response.data, window.location.href);
                            toast.error(err.response.data.message);
                        } else{
                        if(err.message === "timeout of 15000ms exceeded"){
                            toast.error('Request timed out, please try again!')
                        } else if (err.message==="Network Error"){
                            toast.error('Network error, please make sure you are connected to the internet!')
                        } else{
                            toast.error("Your request can't be processed at this time, please try again later!");
                        }
                        }
                    });
                }
        }
    }

    const submitApplication = () => {
        const bankName_pending = ngBanks?.find(o => o.code === data?.businessBankDetail?.bank_code)?.name;
        let payload = {
            floatId,
            businessId: b2b_data?.user?.businessId,
            rcNumber: data?.rcNumber,
            dateOfRegistration: `${dateFormatter(data?.dateOfRegistration, 'dd/mm/yyyy')}`,
            loanAmount: data?.loanAmount,
            loanDuration: data?.loanDuration,
            bankAccountNumber: data?.bankAccountNumber,
            bankCode: data?.businessBankDetail?.bank_code,
            accountName: data?.businessBankDetail?.account_name,
            bankName: data?.bankName || bankName_pending,
            director1Fullname: data?.director1Fullname,
            director1BankAccountNumber: data?.director1BankAccountNumber,
            director1BankName: data?.director1BankName,
            director1BVN: data?.director1BVN,
            director1Position: data?.director1Position,
            director1Phone: data?.director1Phone,
            director1Address: data?.director1Address,
            director1Email: data?.director1Email,
            director2Fullname: data?.director2Fullname,
            director2BankAccountNumber: data?.director2BankAccountNumber,
            director2BankName: data?.director2BankName,
            director2BVN: data?.director2BVN,
            director2Position: data?.director2Position,
            director2Phone: data?.director2Phone,
            director2Address: data?.director2Address,
            director2Email: data?.director2Email,
            amountType:amountType
        }

        FloatService.newRequest(payload)
            .then((response) => response.data)
            .then((res) => {
                setShowPriceModal(false)
                setDisableAction(false)
                if (res.status === 'success') {
                    setShowSuccess(true)               
                    sessionStorage.removeItem( "floatId")  
                    sessionStorage.removeItem( "floatRequest")  
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setShowPriceModal(false)
                setDisableAction(false)
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 30000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const steps = [
        {
            number: 1,
            title: "Float Details",
            subtitle: "Lending to the lenders",
            key: "details",
            link: `/float/new-request?amountType=${amountType}`,
        },
        {
            number: 2,
            title: "Director (Guarantor 1)",
            subtitle: "We’d love to know you better 😍",
            key: "director1",
            link: `/float/new-request/director1?amountType=${amountType}`,
        },
        {
            number: 3,
            title: "Director (Guarantor 2)",
            subtitle: "We are rooting for you at CreditChek 🙌",
            key: "director2",
            link: `/float/new-request/director2?amountType=${amountType}`,
        },
        {
            number: 4,
            title: "Mandate & Consent",
            subtitle: "Final Step",
            key: "consent",
            link: `/float/new-request/mandate-consent`,
        },
    ]

    return(
        <div className="py-[30px] px-10 md:px-[20px]">
            <div>
                <Back to={()=> navigate("/float")}/>
                <div className="relative md:flex md:justify-start md:mt-5 md:-mb-[50px]">
                    <div className='md:hidden h-1 -z-[3] w-[calc(100%-260px)] absolute top-[15px] mx-[130px] rounded-[4px] bg-cc-blue-5'>
                        <div className={`bg-cc-blue-4 h-1 rounded-[4px] ${path==='details' ? 'w-0' : path==='director1' ? 'w-[33%]' : path==='director2' ? 'w-[66%]' : 'w-full'}`}></div>
                    </div>
                    <div className="flex justify-between items-center mx-[130px] md:mx-0 md:block">
                        {
                            steps.map((item, idx) => (
                                <div 
                                    key={item.key}
                                    onClick={()=>{
                                        navigate(item.link);
                                        setPath(item.key)
                                    }}
                                    className={clsx(
                                        "flex items-center justify-center w-[30px] h-[30px] shrink-0 rounded-full border-4 md:mb-[40px] md:mr-[10px]",
                                        steps?.find(x => x.key===path)?.number > idx ? "bg-cc-primary border-cc-blue-3":"border-[#E0EAFF] bg-white",
                                    )}
                                >
                                    {
                                        steps?.find(x => x.key===path)?.number>(idx+1) ?
                                        <img src={tick} alt="tick" />
                                        :
                                        <p 
                                            className={clsx(
                                                "text-center font-semibold -mt-[1px]",
                                                path===item.key ? 'text-white':'text-cc-primary'
                                            )}
                                        >{idx+1}</p>
                                    }
                                </div>
                            ))
                        }
                    </div>
                    <div className="flex justify-between md:block">
                        {
                            steps.map(item => (
                                <div key={item.key} className="w-1/4 text-center md:w-full md:text-left h-[70px]">
                                    <h5 
                                        className={clsx(
                                            "font-semibold m-0 text-[1.1em]",
                                            path===item.key?"text-cc-primary":'text-cc-grey-7'
                                        )}
                                    >{item.title}</h5>
                                    <p className="m-0 text-[0.95em]">{item.subtitle}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <Routes>
                    <Route 
                        path="/" 
                        element={
                            <FloatDetails 
                                verification={verification} 
                                triggerPriceModal={(action)=>getPrice(action)}
                                data={data}
                                ngBanks={ngBanks}
                                setPath={setPath}
                                setData={setData}
                                amountType={amountType}
                            />}
                    />
                    <Route 
                        path="/director1" 
                        element={
                            <Director1 
                                verification={verification} 
                                triggerPriceModal={(action)=>getPrice(action)}
                                data={data}
                                ngBanks={ngBanks}
                                setPath={setPath}
                                handleDirectorConsent={handleDirectorConsent}
                                setData={setData}
                            />} 
                    />
                    <Route 
                        path="/director2" 
                        element={
                            <Director2 
                                verification={verification} 
                                triggerPriceModal={(action)=>getPrice(action)}
                                data={data}
                                setPath={setPath}
                                ngBanks={ngBanks}
                                handleDirectorConsent={handleDirectorConsent}
                                setData={setData}
                            />
                        } 
                    />
                    <Route 
                        path="/mandate-consent" 
                        element={
                            <MandateConsent 
                                data={data}
                                submitApplication={()=>getPrice("float request")}
                            />
                        } 
                    />
                </Routes>
            </div>
            <ConfirmAction
                show={showPriceModal}
                onCancel={()=>setShowPriceModal(false)}
                onAction={()=>{
                    actionType==="floatRequest"?submitApplication():doSubmit();
                    setDisableAction(true);
                }}
                loading={disableAction}
                title={
                    actionType==="floatRequest" ? "Float Submission"
                    :`${actionType==='bvnFloat'?"BVN" : actionType==='accFloat'?"Bank Account" : "RC Number"} verification`
                }
                content={
                    actionType==="floatRequest" ? `Your wallet will be charged ${currencyFormatter(price, 0)} for credit bureau checks.`
                    :`Using the ${actionType==='bvnFloat'?"BVN" : actionType==='accFloat'?"Bank Account" : "RC Number"} service would attract a charge of ${currencyFormatter(price, 0)}`
                }
            />
             <Modal show={showSuccess} aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="float_modal">
                    <img src={floatSuccess} alt='icon' />
                    <h5 className="font-medium text-[1.3em] my-2">Application Sent</h5>
                    <p style={{color:"#667085"}} className="mb-[15px]">Your application has been sent to the Creditchek team for review</p>
                    <button 
                        className="float_modal-action"
                        onClick={()=>{
                            navigate("/float/status")
                            showFeedback("Float Request")
                        }}
                    >Go Back</button>
                </div>
            </Modal>
        </div>
    )
}

export default NewRequest;