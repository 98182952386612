import { DragToUpload } from "components/Form/DragToUpload";
import Button from "components/shared/Button";
import { FormikProvider, useFormik } from "formik";
import AgreementFile from "./components/AgreementFile";
import PDFView from "components/PDFView";
import pdfIcon from "images/svg/float/pdf.svg";
import { generatePDF, handleRequestErrors } from "utils/helpers";
import { useState } from "react";
import { FloatService } from "services/float.service";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import dwnld from "images/svg/identity/download.svg";
import clsx from "clsx";


export default function MandateConsent ({data, submitApplication}) {
    const [isDownloading, setIsDownloading] = useState(false)
    const [nextLoading, setNextLoading] = useState(false)
    const businessId = useSelector(state => state?.loginData?.data?.user?.businessId)


    let floatId = '';
    try {
        floatId = JSON.parse(sessionStorage.getItem('floatId'))
    } catch (error) {
    }

    const saveFile = (file) => {
        setNextLoading(true)
        let value = new FormData();
        value.append('businessId', businessId);
        value.append('floatId', floatId);
        value.append('file', file);
        FloatService.uploadAgreementConsent(value)
            .then((response) => response.data)
            .then((res) => {
                if (res.status === 'success') {
                    submitApplication()
                } else {
                    setNextLoading(false)
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setNextLoading(false)
                handleRequestErrors(err)
            });
    }

    const form = useFormik({
        initialValues: {
            agreementFile: null,
        },
        onSubmit: (values) => {
            saveFile(values.agreementFile)
        }
    })
    return (
        <div className="max-w-[800px] block mx-auto mt-10">
            <p>Download a copy of our PDF agreement Consent, sign, upload and submit</p>
            <FormikProvider value={form}>
                <form onSubmit={form.handleSubmit} className="flex items-end gap-6">
                    <div className="max-w-[350px] flex flex-col gap-6">
                        <div className="flex items-center gap-1.5 border-[1px] border-[#D0D5DD] rounded p-2.5 mt-[15px]">
                            <img src={pdfIcon} alt="" />
                            <div className="overflow-hidden">
                                <h6 className="text-sm">Agreement Consent</h6>
                            </div>
                            <div 
                                className={clsx(
                                    "ml-auto rounded border-[1px] border-[#D0D5DD] p-1 shrink-0",
                                    isDownloading ? "cursor-wait":"cursor-pointer"
                                )}
                                onClick={()=>{
                                    if(!isDownloading){
                                        const downloadToast = toast.loading("Please wait... Agreement consent may take a while to download.")
                                        setIsDownloading(true)
                                        generatePDF(
                                            "mandate-consent_agreement", 
                                            "float-agreement",
                                            ()=>{
                                                setIsDownloading(false)
                                                toast.update(downloadToast, { render: "Agreement downloaded!", type: "success", isLoading: false, autoClose: 3000, });
                                            }
                                        )
                                    }
                                }}
                            >
                                <img src={dwnld} alt="" />
                            </div>
                        </div>
                        <div>
                            {
                                !!form.values.agreementFile && 
                                <p>{form.values.agreementFile.name}</p>
                            }
                            <DragToUpload
                                name="agreementFile"
                                fileType="pdf"
                                message="Signed copy of agreement contract"
                                onChange={(file)=>form.setFieldValue("agreementFile", file)}
                            />
                        </div>
                    </div>
                    <Button
                        fullwidth={false}
                        isValid={!!form.values.agreementFile}
                        isLoading={nextLoading}
                        type="submit"
                        text="Submit"
                        className="whitespace-nowrap px-3 w-[100px] mb-[26px]"
                    />
                </form>
            </FormikProvider>
            <div className=" opacity-20">
                <p className="mt-10 mb-2.5">Open or share link to setup mandate consent to allow us collect repayment in the event of a default</p>
                {
                    ["Obligor (Guarantor 1)", "Obligor (Guarantor 2)"].map(item => (
                        <div className="">
                            <p>{item}</p>
                            <div className="flex gap-[10px] mt-2.5 mb-6">
                                <div className="border-[1px] border-[#CCDCFF] rounded py-1 px-3">
                                    <p>https://www.creditchek.recovamandate.com</p>
                                </div>
                                <Button
                                    isValid={true}
                                    type="button"
                                    text="Continue"
                                    className="whitespace-nowrap px-3"
                                />
                                <Button
                                    isValid={true}
                                    type="button"
                                    text="Send Consent"
                                    className="whitespace-nowrap px-3"
                                />
                            </div>
                        </div>
                    ))
                }
            </div>
            <PDFView id={"mandate-consent_agreement"}>
                <div className="bg-white text-black p-10 min-h-[275mm]">
                    <AgreementFile data={data} />
                </div>
            </PDFView>
        </div>
    )
}