import { createSlice } from '@reduxjs/toolkit';

export const businessSlice = createSlice({
  name: 'business',
  initialState: {},
  reducers: {
    setBusinessData: (state, action) => {
      return { 
        ...state, 
        ...action.payload
      }
    },
  },
})

export const { setBusinessData } = businessSlice.actions

export default businessSlice.reducer