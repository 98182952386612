import jsPDF from "jspdf";
import { currencyFormatter } from "utils/helpers";


const months=['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export const jsPDFGenerator = ( data) => {
    const doc = new jsPDF({
        // orientation: "landscape",
        unit: "mm",
        format: [210, 297]
    });


    doc.setFont("DMSans-Regular", "normal", "bold");
    doc.setFillColor('#0046E6');
    doc.roundedRect(55, 13, 100, 10, 1, 1, 'F');
    doc.setTextColor("#ffffff")
    doc.setFont("DMSans-Regular", "normal", "normal");
    doc.text(`${data?.businessName}`, 105, 20, {align:"center"});
    doc.setTextColor("#000")
    doc.text(`Creditchek invoice - ${new Date().getDate()} ${months[new Date().getMonth()]}, ${new Date().getFullYear()}`, 105, 30, {align:"center"});
    

    
        doc.setFont("Inter", "normal", "bold");
        doc.setFontSize(20)
        doc.text(`Invoice #${data?.invoiceReference}`, 20, 50);
    

        //invoice recipient
        doc.setFont("Inter", "normal", "normal");
        doc.setFontSize(20)
        doc.text(`Invoice To: `, 20, 74);

        doc.setFont("Inter", "normal", "normal")
        doc.setFontSize(14)
        doc.text(`${data?.ownerFullName} `, 20, 82);

        doc.setFont("Inter", "normal", "normal")
        doc.setFontSize(14)
        doc.text(`${data?.phoneNumber} `, 20, 90);

        doc.setFont("Inter", "normal", "normal")
        doc.setFontSize(14)
        doc.text(`${data?.email} `, 20, 98);
       
        //issue details
        doc.setFont("Inter", "normal", "normal");
        doc.setFontSize(14)
        doc.text(`Issue Date: ${new Date(data.created_at).getDate()}/${new Date(data.created_at).getMonth() < 10 && '0'}${new Date(data.created_at).getMonth()}/${new Date(data.created_at).getFullYear()} - ${formatAMPM(new Date(data?.created_at))}`, 120, 74);

        doc.setFont("Inter", "normal", "normal")
        doc.setFontSize(14)
        doc.text(`Paid Date: ${new Date(data.created_at).getDate()}/${new Date(data.created_at).getMonth() < 10 && '0'}${new Date(data.created_at).getMonth()}/${new Date(data.created_at).getFullYear()} - ${formatAMPM(new Date(data?.created_at))}`, 120, 82);

        doc.setFont("Inter", "normal", "normal")
        doc.setFontSize(14)
        doc.text(`Invoice Status: Paid `, 120, 90);


        //amount
        doc.setFont("Inter", "normal", "normal")
        doc.setFontSize(16)
        doc.text(`Wallet Funding: `, 20, 130);

        doc.setFont("Inter", "normal", "normal")
        doc.setFontSize(16)
        doc.text(`${currencyFormatter(data?.amount)} `, 180, 130);

        doc.setFont("Inter", "normal", "normal")
        doc.setFontSize(16)
        doc.text(`Total: `, 20, 138);

        doc.setFont("Inter", "normal", "normal")
        doc.setFontSize(16)
        doc.text(`${currencyFormatter(data?.amount)} `, 180, 138);
       

    doc.save(`${data?.businessName}_invoice.pdf`);
}