import { currencyFormatter } from "utils/helpers";

import tableIcon from "images/svg/loan-performance.svg";


export function SalaryHighlight ({sweep, highlight}) {
    return (
        <div className="income_highlight">
            <div className="income_highlight_top">
                <h4 className="font-semibold flex items-center"><img src={tableIcon} alt="table" /><span>Salary History</span></h4>
            </div>
            <table className="">
                <thead>
                    <tr>
                        <th>Narration</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        highlight?.amount?.map((item, idx)=>(
                            <tr key={idx}>
                                <td>{highlight?.narration?.[idx]}</td>
                                <td>{currencyFormatter(item/100||0, 0, "NGN")}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <div style={{marginTop:'40px'}}>
                <div className="income_highlight_top">
                    <h4 className="font-semibold flex items-center"><img src={tableIcon} alt="table" /><span>Sweeping Summary</span></h4>
                </div>
                <table className="">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Highlight</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="first" style={{width:'45%'}}>Average Sweep Monthly Credit</td>
                            <td>{Math.round(sweep || 0)} days</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}