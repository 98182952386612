import Table from "components/Table";
import { IdentityService } from "services/identity.service";
import { currencyFormatter, dateFormatter, handleRequestErrors, timeFormatter } from "utils/helpers";
import Button from "components/shared/Button";
import view from "images/svg/income/view.svg";
import reload from "images/svg/income/reload.svg";
import { Mixpanel } from "lib/mixpanel";
import ConfirmAction from "components/Modal/ConfirmAction";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";


export default function HistoryTable ({
    price,
    openView,
    path
}) {
    const [loading, setLoading] = useState(false)
    const userData = useSelector(state => state?.loginData?.data?.user)
    const businessName = useSelector(state => state?.loginData?.data?.businessName)
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState([])
    const [reloadItem, setReloadItem] = useState(null)
    const [reloading, setReloading] = useState(false)
    let history = useNavigate();
    const [searchTerm, setSearchTerm] = useState("")

    useEffect(()=>{
        if(!!path && path!=="main" && path!=="radar"){
            getHistory()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path, searchTerm])

    const getHistory = () => {
        setLoading(true)
        IdentityService.getVerifiedHistory(
            path==='license'?'drivers_license'
            :path==='pvc'?'voters_card'
            :path==='bank'?'bankAccount'
            :path==='rc'?'cac'
            :path==='passport'?'passport_number'
            :path
        )
          .then((response) => response.data)
          .then((res) => {
                setLoading(false)
                const filteredData = 
                    path==="passport" ? res.data?.filter((item) => (
                        item.first_name.toLowerCase().includes(searchTerm.toLowerCase()) || 
                        item.last_name.toLowerCase().includes(searchTerm.toLowerCase()) || 
                        item.passport_number.toLowerCase().includes(searchTerm.toLowerCase())
                    ))
                    : res?.data;
                const sortedData = filteredData.sort(function(a,b){
                    return new Date(b.updatedAt) - new Date(a.updatedAt);
                })
                if (res.status === true) {
                    setData(sortedData)
                } else {
                    toast.error(res.message)
                }
          })
        .catch((err) => {
            setLoading(false)
            handleRequestErrors(err)
        });
    }

    const reloadPassport = () => {
        setReloading(true)
        IdentityService.doPassportVerification(
            reloadItem.passport_number,
            reloadItem.last_name,
        )
            .then((response) => response.data)
            .then((res) => {
                setReloading(false)
                setShowModal(false)
                if (res.status === true) {
                    sessionStorage.setItem("identity", JSON.stringify(res.data))
                    toast.success("Request Successful!!!")
                    setTimeout( function (){
                        history(`/identity/international-passport`)
                    }, 100);
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setReloading(false)
                setShowModal(false)
                handleRequestErrors(err)
            });
    }

    return (
        <div>
            <Table
                tableTitle={
                    path==="passport"? "International Pasport"
                    :""
                }
                data={data}
                loading={loading}
                hideSearch={false}
                searchPlaceHolder="Search with Name or Passport Number"
                onSearchChange={(e)=>setSearchTerm(e.target.value)}
                searchContainerClass="xl_min:min-w-[400px] min-w-[300px] sm:!w-full"
                searchInputClass="!text-sm !text-cc-grey-4"
                searchIconFirst={true}
                tableClassName="xl:min-w-[1000px]"
                trClassName="text-sm"
                rows={[
                    {
                        header:'NAME',
                        view: (item) => (
                            <div className="py-[10px] pl-[10px]">
                                {item.first_name} {item.last_name}
                            </div>
                        ),
                    },
                    {
                        header:'MOBILE',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {item?.phone||"Not available"}
                            </p>
                        ),
                    },
                    {
                        header:'PASSPORT NUMBER',
                        view: (item) => (
                            <div className="py-[10px] pl-[10px]">
                                {item.passport_number}
                            </div>
                        ),
                    },
                    {
                        header:'DATE',
                        view: (item) => (
                            <div className="py-[10px] pl-[10px]">
                                {dateFormatter(item.createdAt, 'dd/mm/yyyy')} {timeFormatter(item.createdAt)}
                            </div>
                        ),
                    },
                    {
                        header:'ACTIONS',
                        view: (item) => (
                            <div className="my-2.5 flex">
                              <Button
                                isValid={true}
                                text={"View"}
                                className="text-xs px-1.5 py-0.5 text-white border rounded-[4px] flex items-center justify-center bg-cc-green-4 mr-2.5 w-[80px] h-8"
                                fullwidth={false}
                                onClick={()=>{
                                  Mixpanel.track(`View ${data?.type} identity button was clicked`, {
                                    name: `${userData?.firstName} ${userData?.lastName}`,
                                    bussinessName: businessName
                                  });
                                  openView(item)
                                }} 
                                icon={<img src={view} alt="view" className="w-[16px] mr-1" />}
                                iconPosition="left"
                              />
                              <Button
                                isValid={true}
                                text={"Reload Data"}
                                className="text-xs px-1.5 py-0.5 text-white border rounded-[4px] flex items-center justify-center bg-cc-primary w-[120px] h-8"
                                fullwidth={false}
                                onClick={()=>{
                                    setShowModal(true)
                                    setReloadItem(item)
                                }} 
                                icon={<img src={reload} alt="reload" className="w-[16px] mr-1" />}
                                iconPosition="left"
                              />
                            </div>
                        ),
                    },
                ]}
            />
            <ConfirmAction
              show={showModal}
              onCancel={()=>setShowModal(false)}
              onAction={()=>{
                reloadPassport()
              }}
              loading={reloading}
              title={path==="passport"?`International Passport Verification`:""}
              content={`Using the ${path} verification service would attract a charge of ${currencyFormatter(price)}.`}
            />
        </div>
    )
}