import { currencyFormatter } from "utils/helpers";

function edtiTable ({data, changeSee, creditBreakdown, filterOpt}) {
    const convertObject = (data_) => {
        const objKeys = creditBreakdown?.monthlyCredit ? Object.keys(creditBreakdown[`${data_}`]) : null;
        const objVals = creditBreakdown?.monthlyCredit ? Object.values(creditBreakdown[`${data_}`]) : null;
        let result = {
            monthYear: objKeys, 
            amount: objVals
        };
        return result;
    }

    const sumArrayItems = (arrayItems) => {
        var sum = 0;
        arrayItems.forEach(x => {
            sum += x;
        });

        return currencyFormatter(sum/100);
    }

    const findSittingBal = (period) => {
        let val = 0;
        data.items.forEach(item=>{
            if(item[0]===period){
                val = item[1]
            }
        })
        return currencyFormatter(val/100);
    }

    return(
        <div className={`table ${changeSee==="sitting"?'table_scroll-tabPort':'table_scroll-none'}`}>
            {
                data?.items?.length > 0 ?
                <div>
                    <table>
                        <thead>
                            <tr>
                                { 
                                    data.headings.map((item,idx)=>(
                                        <th 
                                            style={{textAlign: changeSee==="expense"&&idx===1 ?'center':''}}
                                            key={idx}
                                        >
                                            {item}
                                        </th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                changeSee==='sitting' && filterOpt==='quarter'?
                                <>
                                    { // for first quarter - first year
                                        convertObject('creditFirstQuarter1')?.monthYear?.length>0 &&
                                        <tr>
                                            {
                                                convertObject('creditFirstQuarter1').monthYear.length===1 ?
                                                <td>{convertObject('creditFirstQuarter1').monthYear[0]}</td>
                                                :
                                                <td>
                                                    {convertObject('creditFirstQuarter1').monthYear[0]?.replace(/ .*/,'')} {" "}
                                                    - {" "}
                                                    {convertObject('creditFirstQuarter1').monthYear[convertObject('creditFirstQuarter1').monthYear.length - 1]}
                                                </td>
                                            }
                                            <td>{findSittingBal(convertObject('creditFirstQuarter1').monthYear[convertObject('creditFirstQuarter1').monthYear.length - 1])}</td>
                                            <td>{sumArrayItems(convertObject('debitFirstQuarter1').amount)}</td>
                                            <td>{sumArrayItems(convertObject('creditFirstQuarter1').amount)}</td>
                                        </tr>
                                    }
                                    { // for second quarter - first year
                                        convertObject('creditSecondQuarter1')?.monthYear?.length>0 &&
                                        <tr>
                                            {
                                                convertObject('creditSecondQuarter1').monthYear.length===1 ?
                                                <td>{convertObject('creditSecondQuarter1').monthYear[0]}</td>
                                                :
                                                <td>
                                                    {convertObject('creditSecondQuarter1').monthYear[0]?.replace(/ .*/,'')} {" "}
                                                    - {" "}
                                                    {convertObject('creditSecondQuarter1').monthYear[convertObject('creditSecondQuarter1').monthYear.length - 1]}
                                                </td>
                                            }
                                            <td>{findSittingBal(convertObject('creditSecondQuarter1').monthYear[convertObject('creditSecondQuarter1').monthYear.length - 1])}</td>
                                            <td>{sumArrayItems(convertObject('debitSecondQuarter1').amount)}</td>
                                            <td>{sumArrayItems(convertObject('creditSecondQuarter1').amount)}</td>
                                        </tr>
                                    }
                                    { // for third quarter - first year
                                        convertObject('creditThirdQuarter1')?.monthYear?.length>0 &&
                                        <tr>
                                            {
                                                convertObject('creditThirdQuarter1').monthYear.length===1 ?
                                                <td>{convertObject('creditThirdQuarter1').monthYear[0]}</td>
                                                :
                                                <td>
                                                    {convertObject('creditThirdQuarter1').monthYear[0]?.replace(/ .*/,'')} {" "}
                                                    - {" "}
                                                    {convertObject('creditThirdQuarter1').monthYear[convertObject('creditThirdQuarter1').monthYear.length - 1]}
                                                </td>
                                            }
                                            <td>{findSittingBal(convertObject('creditThirdQuarter1').monthYear[convertObject('creditThirdQuarter1').monthYear.length - 1])}</td>
                                            <td>{sumArrayItems(convertObject('debitThirdQuarter1').amount)}</td>
                                            <td>{sumArrayItems(convertObject('creditThirdQuarter1').amount)}</td>
                                        </tr>
                                    }
                                    { // for fourth quarter - first year
                                        convertObject('creditFourthQuarter1')?.monthYear?.length>0 &&
                                        <tr>
                                            {
                                                convertObject('creditFourthQuarter1').monthYear.length===1 ?
                                                <td>{convertObject('creditFourthQuarter1').monthYear[0]}</td>
                                                :
                                                <td>
                                                    {convertObject('creditFourthQuarter1').monthYear[0]?.replace(/ .*/,'')} {" "}
                                                    - {" "}
                                                    {convertObject('creditFourthQuarter1').monthYear[convertObject('creditFourthQuarter1').monthYear.length - 1]}
                                                </td>
                                            }
                                            <td>{findSittingBal(convertObject('creditFourthQuarter1').monthYear[convertObject('creditFourthQuarter1').monthYear.length - 1])}</td>
                                            <td>{sumArrayItems(convertObject('debitFourthQuarter1').amount)}</td>
                                            <td>{sumArrayItems(convertObject('creditFourthQuarter1').amount)}</td>
                                        </tr>
                                    }
                                    { // for first quarter - second year
                                        convertObject('creditFirstQuarter0')?.monthYear?.length>0 &&
                                        <tr>
                                            {
                                                convertObject('creditFirstQuarter0').monthYear.length===1 ?
                                                <td>{convertObject('creditFirstQuarter0').monthYear[0]}</td>
                                                :
                                                <td>
                                                    {convertObject('creditFirstQuarter0').monthYear[0]?.replace(/ .*/,'')} {" "}
                                                    - {" "}
                                                    {convertObject('creditFirstQuarter0').monthYear[convertObject('creditFirstQuarter0').monthYear.length - 1]}
                                                </td>
                                            }
                                            <td>{findSittingBal(convertObject('creditFirstQuarter0').monthYear[convertObject('creditFirstQuarter0').monthYear.length - 1])}</td>
                                            <td>{sumArrayItems(convertObject('debitFirstQuarter0').amount)}</td>
                                            <td>{sumArrayItems(convertObject('creditFirstQuarter0').amount)}</td>
                                        </tr>
                                    }
                                    { // for second quarter - first year
                                        convertObject('creditSecondQuarter0')?.monthYear?.length>0 &&
                                        <tr>
                                            {
                                                convertObject('creditSecondQuarter0').monthYear.length===1 ?
                                                <td>{convertObject('creditSecondQuarter0').monthYear[0]}</td>
                                                :
                                                <td>
                                                    {convertObject('creditSecondQuarter0').monthYear[0]?.replace(/ .*/,'')} {" "}
                                                    - {" "}
                                                    {convertObject('creditSecondQuarter0').monthYear[convertObject('creditSecondQuarter0').monthYear.length - 1]}
                                                </td>
                                            }
                                            <td>{findSittingBal(convertObject('creditSecondQuarter0').monthYear[convertObject('creditSecondQuarter0').monthYear.length - 1])}</td>
                                            <td>{sumArrayItems(convertObject('debitSecondQuarter0').amount)}</td>
                                            <td>{sumArrayItems(convertObject('creditSecondQuarter0').amount)}</td>
                                        </tr>
                                    }
                                    { // for third quarter - second year
                                        convertObject('creditThirdQuarter0')?.monthYear?.length>0 &&
                                        <tr>
                                            {
                                                convertObject('creditThirdQuarter0').monthYear.length===1 ?
                                                <td>{convertObject('creditThirdQuarter0').monthYear[0]}</td>
                                                :
                                                <td>
                                                    {convertObject('creditThirdQuarter0').monthYear[0]?.replace(/ .*/,'')} {" "}
                                                    - {" "}
                                                    {convertObject('creditThirdQuarter0').monthYear[convertObject('creditThirdQuarter0').monthYear.length - 1]}
                                                </td>
                                            }
                                            <td>{findSittingBal(convertObject('creditThirdQuarter0').monthYear[convertObject('creditThirdQuarter0').monthYear.length - 1])}</td>
                                            <td>{sumArrayItems(convertObject('debitThirdQuarter0').amount)}</td>
                                            <td>{sumArrayItems(convertObject('creditThirdQuarter0').amount)}</td>
                                        </tr>
                                    }
                                    { // for fourth quarter - second year
                                        convertObject('creditFourthQuarter0')?.monthYear?.length>0 &&
                                        <tr>
                                            {
                                                convertObject('creditFourthQuarter0').monthYear.length===1 ?
                                                <td>{convertObject('creditFourthQuarter0').monthYear[0]}</td>
                                                :
                                                <td>
                                                    {convertObject('creditFourthQuarter0').monthYear[0]?.replace(/ .*/,'')} {" "}
                                                    - {" "}
                                                    {convertObject('creditFourthQuarter0').monthYear[convertObject('creditFourthQuarter0').monthYear.length - 1]}
                                                </td>
                                            }
                                            <td>{findSittingBal(convertObject('creditFourthQuarter0').monthYear[convertObject('creditFourthQuarter0').monthYear.length - 1])}</td>
                                            <td>{sumArrayItems(convertObject('debitFourthQuarter0').amount)}</td>
                                            <td>{sumArrayItems(convertObject('creditFourthQuarter0').amount)}</td>
                                        </tr>
                                    }
                                </>
                                :
                                changeSee==='sitting' && filterOpt==='bi-annual'?
                                <>
                                    { // for first half - first year
                                        convertObject('creditFirstHalf1')?.monthYear?.length>0 &&
                                        <tr>
                                            {
                                                convertObject('creditFirstHalf1').monthYear.length===1 ?
                                                <td>{convertObject('creditFirstHalf1').monthYear[0]}</td>
                                                :
                                                <td>
                                                    {convertObject('creditFirstHalf1').monthYear[0]?.replace(/ .*/,'')} {" "}
                                                    - {" "}
                                                    {convertObject('creditFirstHalf1').monthYear[convertObject('creditFirstHalf1').monthYear.length - 1]}
                                                </td>
                                            }
                                            <td>{findSittingBal(convertObject('creditFirstHalf1').monthYear[convertObject('creditFirstHalf1').monthYear.length - 1])}</td>
                                            <td>{sumArrayItems(convertObject('debitFirstHalf1').amount)}</td>
                                            <td>{sumArrayItems(convertObject('creditFirstHalf1').amount)}</td>
                                        </tr>
                                    }
                                    { // for second half - first year
                                        convertObject('creditSecondHalf1')?.monthYear?.length>0 &&
                                        <tr>
                                            {
                                                convertObject('creditSecondHalf1').monthYear.length===1 ?
                                                <td>{convertObject('creditSecondHalf1').monthYear[0]}</td>
                                                :
                                                <td>
                                                    {convertObject('creditSecondHalf1').monthYear[0]?.replace(/ .*/,'')} {" "}
                                                    - {" "}
                                                    {convertObject('creditSecondHalf1').monthYear[convertObject('creditSecondHalf1').monthYear.length - 1]}
                                                </td>
                                            }
                                            <td>{findSittingBal(convertObject('creditSecondHalf1').monthYear[convertObject('creditSecondHalf1').monthYear.length - 1])}</td>
                                            <td>{sumArrayItems(convertObject('debitSecondHalf1').amount)}</td>
                                            <td>{sumArrayItems(convertObject('creditSecondHalf1').amount)}</td>
                                        </tr>
                                    }
                                    { // for first half - second year
                                        convertObject('creditFirstHalf0')?.monthYear?.length>0 &&
                                        <tr>
                                            {
                                                convertObject('creditFirstHalf0').monthYear.length===1 ?
                                                <td>{convertObject('creditFirstHalf0').monthYear[0]}</td>
                                                :
                                                <td>
                                                    {convertObject('creditFirstHalf0').monthYear[0]?.replace(/ .*/,'')} {" "}
                                                    - {" "}
                                                    {convertObject('creditFirstHalf0').monthYear[convertObject('creditFirstHalf0').monthYear.length - 1]}
                                                </td>
                                            }
                                            <td>{findSittingBal(convertObject('creditFirstHalf0').monthYear[convertObject('creditFirstHalf0').monthYear.length - 1])}</td>
                                            <td>{sumArrayItems(convertObject('debitFirstHalf0').amount)}</td>
                                            <td>{sumArrayItems(convertObject('creditFirstHalf0').amount)}</td>
                                        </tr>
                                    }
                                    { // for second half - first year
                                        convertObject('creditSecondHalf0')?.monthYear?.length>0 &&
                                        <tr>
                                            {
                                                convertObject('creditSecondHalf0').monthYear.length===1 ?
                                                <td>{convertObject('creditSecondHalf0').monthYear[0]}</td>
                                                :
                                                <td>
                                                    {convertObject('creditSecondHalf0').monthYear[0]?.replace(/ .*/,'')} {" "}
                                                    - {" "}
                                                    {convertObject('creditSecondHalf0').monthYear[convertObject('creditSecondHalf0').monthYear.length - 1]}
                                                </td>
                                            }
                                            <td>{findSittingBal(convertObject('creditSecondHalf0').monthYear[convertObject('creditSecondHalf0').monthYear.length - 1])}</td>
                                            <td>{sumArrayItems(convertObject('debitSecondHalf0').amount)}</td>
                                            <td>{sumArrayItems(convertObject('creditSecondHalf0').amount)}</td>
                                        </tr>
                                    }
                                </>
                                :
                                data?.items?.map((item, idx) =>{
                                    const monthly_Credit = convertObject('monthlyCredit')?.amount?.reverse()?.[idx];
                                    const monthly_Debit = convertObject('monthlyDebit')?.amount?.reverse()?.[idx];
                                    return(
                                        <tr key={idx}>
                                            <td style={{width: (changeSee==="expense"||changeSee==="recurrent")&&'50%'}}>{item[0]}</td>
                                            <td style={{textAlign: changeSee==="expense"&&'center'}}>{changeSee==="expense"?`${item[1]}%`:currencyFormatter(item[1]/100)}</td>
                                            {(changeSee==="sitting"&&creditBreakdown?.monthlyCredit) && <td>{currencyFormatter(monthly_Debit/100)}</td>}
                                            {(changeSee==="sitting"&&creditBreakdown?.monthlyCredit) && <td>{currencyFormatter(monthly_Credit/100)}</td>}
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                :
                <h4 style={{textAlign:"center"}}>No data to show</h4>
            }    
        </div>  

    )
}

export default edtiTable;