import Name from "components/shared/Name";
import Back from "components/shared/back";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import CreditEnquiries from "../components/CreditEnquiries";
import {jsPDFGenerator} from '../../../../lib/pdfGenerate';
import ShareReport from "components/Modal/ShareReport";
import { Mixpanel } from "../../../../lib/mixpanel";
import Creditors from "../components/Creditors";
import { useSelector } from "react-redux";
import EmploymentHistory from "../components/EmploymentHistory";
import LoanPerformance from "../components/LoanPerformance";
import LoanHistory from "../components/LoanHistory";

import share from "../../../../images/svg/credit/share.svg";
import download from "../../../../images/svg/credit/download.svg";
import plug from "../../../../images/svg/credit/plug.svg";
import credit_grantors from "../../../../images/svg/credit/data-icons/credit_grantors.svg";
import closed_credit from "../../../../images/svg/credit/data-icons/closed_credit.svg";
import credit_facilities from "../../../../images/svg/credit/data-icons/credit_facilities.svg";
import delinquent from "../../../../images/svg/credit/data-icons/delinquent.svg";
import open_credit from "../../../../images/svg/credit/data-icons/open_credit.svg";
import total_due from "../../../../images/svg/credit/data-icons/total_due.svg";
import max_days from "../../../../images/svg/credit/data-icons/max_days.svg";
import total_loan from "../../../../images/svg/credit/data-icons/total_loan.svg";
import highest_loan from "../../../../images/svg/credit/data-icons/highest_loan.svg";
import outstanding from "../../../../images/svg/credit/data-icons/outstanding.svg";
import last_reported from "../../../../images/svg/credit/data-icons/last_reported.svg";
import { useBilledPageExit } from "hooks/feedback.hook";
import { currencyFormatter } from "utils/helpers";



export default function Details ({setDetailName}) {
    const navigate = useNavigate();
    const params = useParams();
    const [showShare, setShowShare] = useState(false);
    const b2b_data = useSelector(state => state?.loginData?.data);
    const [searchParams] = useSearchParams();
    const [currentPath, setCurrentPath] = useState("FIRST_CENTRAL")

    useBilledPageExit("Credit Insight")

    let report = {};
    let crcStatus = "processing";
    let firstCentralStatus = "processing";
    let creditRegistryStatus = "processing";
    console.log(crcStatus, firstCentralStatus, creditRegistryStatus)

    try{
        let details = sessionStorage.getItem("report");
        report = JSON.parse(details)
        crcStatus = report.data.bureauStatus.crc ;
        firstCentralStatus = report.data.bureauStatus.firstCentral ;
        creditRegistryStatus = report.data.bureauStatus.creditRegistry ;
    } catch(e) {}

    useEffect(()=>{
        setDetailName(`
            ${params.type==="first-central" ? "First Central"
            : params.type==="credit-registry" ? "Credit Registry"
            :params.type==="classic" ? "Classic"
            :params.type==="premium" ? "Premium"
            : params.type}
        `)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setDetailName])

    return (
        <div className="mx-10 mb-10 md:mx-[20px]">
            <div>
                <Back to={()=> navigate(-1)} />
            </div>
            <div className="credit_classic">
                <Name data={report.name}/>
                <div className="credit_boxes-title">
                    <div className="credit_boxes-title-info">
                        <div className="credit_boxes-title-info-flex">
                            <p>Date of Birth: {report.dob===""?"Not available":report.dob}</p>
                            <p>Gender: {report.gender===""?"Not available":report.gender}</p>
                        </div>
                        <p>Phone Number: {report.phone===""?"Not available":report.phone}</p>
                        <p>Bank Verification Number: {report.bvn===""?"Not available":report.bvn}</p>
                        <p>Address: {report.address===""?"Not available":report.address}</p>
                    </div>
                    <div className="credit_boxes-title-buttons flex items-flex-start">
                        <button className="share flex h-[40px] items-center" onClick={()=>{
                            setShowShare(true);
                            Mixpanel.track(`Share ${params} report button was clicked`, {
                                name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
                                bussinessName: b2b_data?.businessName
                            });
                        }}>
                            <img src={share} alt="" />
                            <p>Share</p>
                        </button>
                        <button className="download flex h-[40px] items-center" onClick={()=>{
                                jsPDFGenerator(report, params, currentPath, searchParams.get('type'));
                                Mixpanel.track(`Download ${params} report button was clicked`, {
                                    name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
                                    bussinessName: b2b_data?.businessName
                                });
                            }}
                        > 
                            <img src={download} alt="" />
                            <p>Download Report</p>
                        </button>
                    </div>
                </div>
            </div>
            
            {params.type==="advanced"||params.type==="premium" ?
                <div style={{margin:"-30px 0 30px"}}>
                    <div className="settings_navMain">
                        <p className={currentPath==="FIRST_CENTRAL" ? "active":""} 
                            onClick={()=> { 
                                setCurrentPath("FIRST_CENTRAL")
                            }}
                        >First Central</p>
                        {
                            params.type==="advanced" &&  
                            <p className={currentPath==="CRC" ? "active":""} 
                                onClick={()=> {
                                    setCurrentPath("CRC")
                                }}
                            >CRC</p>
                        }
                        <p className={currentPath==="CREDIT_REGISTRY" ? "active":""} 
                            onClick={()=> {
                                setCurrentPath("CREDIT_REGISTRY")
                            }}
                        >Credit Registry</p> 
                    </div> 
                </div>
                :<></>
            }
            <div className="box-border">
                {
                    params.type === "advanced" || params.type === "premium" ?
                        true ?
                        <Row>
                            <Col md={6} lg={4} xl={3}>
                                <div className="credit_boxes-singleNew">
                                    <div className="credit_boxes-singleNew-top">
                                        <img src={credit_grantors} alt="" />
                                        <p>Total no of credit facilities</p>
                                    </div>
                                    <p className="credit_boxes-singleNew-text">
                                        {report?.data?.totalNoOfLoans?.find(x => x?.source===currentPath)?.value||0}
                                    </p>
                                </div>
                            </Col>
                            <Col md={6} lg={4} xl={3}>
                                <div className="credit_boxes-singleNew">
                                    <div className="credit_boxes-singleNew-top">
                                        <img src={open_credit} alt="" />
                                        <p>Total no of open credit facilities</p>
                                    </div>
                                    <p className="credit_boxes-singleNew-text">
                                        {report?.data?.totalNoOfActiveLoans?.find(x => x?.source===currentPath)?.value||0}
                                    </p>
                                </div>
                            </Col>
                            <Col md={6} lg={4} xl={3}>
                                <div className="credit_boxes-singleNew">
                                    <div className="credit_boxes-singleNew-top">
                                        <img src={closed_credit} alt="" />
                                        <p>Total no of closed credit facilities</p>
                                    </div>
                                    <p className="credit_boxes-singleNew-text">
                                        {report?.data?.totalNoOfClosedLoans?.find(x => x?.source===currentPath)?.value || 0}
                                    </p>
                                </div>
                            </Col>
                            <Col md={6} lg={4} xl={3}>
                                <div className="credit_boxes-singleNew">
                                    <div className="credit_boxes-singleNew-top">
                                        <img src={delinquent} alt="" />
                                        <p>Total Number of delinquent Facilities</p>
                                    </div>
                                    <p className="credit_boxes-singleNew-text">
                                        {report?.data?.totalNoOfDelinquentFacilities?.find(x => x?.source===currentPath)?.value||0}
                                    </p>
                                </div>
                            </Col>
                            <Col md={6} lg={4} xl={3}>
                                <div className="credit_boxes-singleNew">
                                    <div className="credit_boxes-singleNew-top">
                                        <img src={credit_facilities} alt="" />
                                        <p>Total no of credit grantor</p>
                                    </div>
                                    <p className="credit_boxes-singleNew-text">
                                        {report?.data?.totalNoOfInstitutions?.find(x => x?.source===currentPath)?.value||0}
                                    </p>
                                </div>
                            </Col>
                            <Col md={6} lg={4} xl={3}>
                                <div className="credit_boxes-singleNew">
                                    <div className="credit_boxes-singleNew-top">
                                        <img src={max_days} alt="" />
                                        <p>Max no of days due(over due days)</p>
                                    </div>
                                    <p className="credit_boxes-singleNew-text">
                                        {report?.data?.maxNoOfDays?.find(x => x?.source===currentPath)?.value===null||report?.data?.maxNoOfDays?.find(x => x?.source===currentPath)?.value===""?"0":report?.data?.maxNoOfDays?.find(x => x?.source===currentPath)?.value}
                                    </p>
                                </div>
                            </Col>
                            <Col md={6} lg={4} xl={3}>
                                <div className="credit_boxes-singleNew">
                                    <div className="credit_boxes-singleNew-top">
                                        <img src={total_due} alt="" />
                                        <p>Total Due (overdue)</p>
                                    </div>
                                    <p className="credit_boxes-singleNew-text">
                                        {currentPath==="CRC"?`${currencyFormatter(report?.data?.totalOverdue?.find(x => x?.source===currentPath)?.value || 0, 2, "NGN")}`:`₦${report?.data?.totalOverdue?.find(x => x?.source===currentPath)?.value||0}`}
                                    </p>
                                </div>
                            </Col>
                            <Col md={6} lg={4} xl={3}>
                                <div className="credit_boxes-singleNew">
                                    <div className="credit_boxes-singleNew-top">
                                        <img src={total_loan} alt="" />
                                        <p>Total Loan Value</p>
                                    </div>
                                    <p className="credit_boxes-singleNew-text">
                                        {`${currencyFormatter(report?.data?.totalBorrowed?.find(x => x?.source===currentPath)?.value, 2, "NGN")}`}
                                    </p>
                                </div>
                            </Col>
                            <Col md={6} lg={4} xl={3}>
                                <div className="credit_boxes-singleNew">
                                    <div className="credit_boxes-singleNew-top">
                                        <img src={highest_loan} alt="" />
                                        <p>Highest Loan Request</p>
                                    </div>
                                    <p className="credit_boxes-singleNew-text">
                                        {`${currencyFormatter(report?.data?.highestLoanAmount?.find(x => x?.source===currentPath)?.value, 2, "NGN")}`}
                                    </p>
                                </div>
                            </Col>
                            <Col md={6} lg={4} xl={3}>
                                <div className="credit_boxes-singleNew">
                                    <div className="credit_boxes-singleNew-top">
                                        <img src={outstanding} alt="" />
                                        <p>Total Outstanding Value</p>
                                    </div>
                                    <p className="credit_boxes-singleNew-text">
                                        {currentPath==="CRC"||currentPath==="CREDIT_REGISTRY"?`${currencyFormatter(report?.data?.totalOutstanding?.find(x => x?.source===currentPath)?.value, 2, "NGN")}`:`₦${report?.data?.totalOutstanding?.find(x => x?.source===currentPath)?.value}`}
                                    </p>
                                </div>
                            </Col>
                            {   currentPath==="CRC" &&
                                <Col md={6} lg={4} xl={3}>
                                    <div className="credit_boxes-singleNew">
                                        <div className="credit_boxes-singleNew-top">
                                        <img src={last_reported} alt="" />
                                            <p>Last reported date</p>
                                        </div>
                                        <p className="credit_boxes-singleNew-text">
                                            {report?.data?.lastReportedDate[0]?.value?.slice(0,2)}-{report?.data?.lastReportedDate[0]?.value?.slice(3,6)}-{report?.data?.lastReportedDate[0]?.value?.slice(7,11)}
                                        </p>
                                    </div>
                                </Col>
                            }
                        </Row>
                        :
                        <div className="flex justify-center my-[70px] sm:block">
                            <img src={plug} alt="" className="w-[200px] mr-[30px] sm:mr-0" />
                            <div className="max-w-[350px] text-center mt-10">
                                <h4 className="text-xl font-semibold">This credit bureau is currently down</h4>
                                <p className="text-sm text-cc-grey-17">The data is still processing, please check back later from your  history tab</p>
                            </div>
                        </div>
                    :
                    <Row>
                        <Col md={6} lg={4} xl={3}>
                            <div className="credit_boxes-singleNew">
                                <div className="credit_boxes-singleNew-top">
                                    <img src={credit_grantors} alt="" />
                                    <p>Total no of credit facilities</p>
                                </div>
                                <p className="credit_boxes-singleNew-text">
                                    {report.data.totalNoOfLoans||0}
                                </p>
                            </div>
                        </Col>
                        <Col md={6} lg={4} xl={3}>
                            <div className="credit_boxes-singleNew">
                                <div className="credit_boxes-singleNew-top">
                                    <img src={open_credit} alt="" />
                                    <p>Total no of open credit facilities</p>
                                </div>
                                <p className="credit_boxes-singleNew-text">
                                    {report.data.totalNoOfActiveLoans||0}
                                </p>
                            </div>
                        </Col>
                        <Col md={6} lg={4} xl={3}>
                            <div className="credit_boxes-singleNew">
                                <div className="credit_boxes-singleNew-top">
                                    <img src={closed_credit} alt="" />
                                    <p>Total no of closed credit facilities</p>
                                </div>
                                <p className="credit_boxes-singleNew-text">
                                    {report.data.totalNoOfClosedLoans||0}
                                </p>
                            </div>
                        </Col>
                        <Col md={6} lg={4} xl={3}>
                            <div className="credit_boxes-singleNew">
                                <div className="credit_boxes-singleNew-top">
                                    <img src={delinquent} alt="" />
                                    <p>Total Number of delinquent Facilities</p>
                                </div>
                                <p className="credit_boxes-singleNew-text">
                                    {report.data.totalNoOfDelinquentFacilities||0}
                                </p>
                            </div>
                        </Col>
                        <Col md={6} lg={4} xl={3}>
                            <div className="credit_boxes-singleNew">
                                <div className="credit_boxes-singleNew-top">
                                    <img src={credit_facilities} alt="" />
                                    <p>Total no of credit grantor</p>
                                </div>
                                <p className="credit_boxes-singleNew-text">
                                    {report.data.totalNoOfInstitutions||0}
                                </p>
                            </div>
                        </Col>
                        <Col md={6} lg={4} xl={3}>
                            <div className="credit_boxes-singleNew">
                                <div className="credit_boxes-singleNew-top">
                                    <img src={max_days} alt="" />
                                    <p>Max no of days due(over due days)</p>
                                </div>
                                <p className="credit_boxes-singleNew-text">
                                    {report?.data?.maxNoOfDays||0}
                                </p>
                            </div>
                        </Col>
                        <Col md={6} lg={4} xl={3}>
                            <div className="credit_boxes-singleNew">
                                <div className="credit_boxes-singleNew-top">
                                    <img src={total_due} alt="" />
                                    <p>Total Due (overdue)</p>
                                </div>
                                <p className="credit_boxes-singleNew-text">
                                    {
                                        params.type==="first-central" ?
                                        `₦${report?.data?.totalOverdue||0}`
                                        :
                                        currencyFormatter(report?.data?.totalOverdue||0, 2, "NGN")
                                    }
                                </p>
                            </div>
                        </Col>
                        <Col md={6} lg={4} xl={3}>
                            <div className="credit_boxes-singleNew">
                                <div className="credit_boxes-singleNew-top">
                                    <img src={total_loan} alt="" />
                                    <p>Total Loan Value</p>
                                </div>
                                <p className="credit_boxes-singleNew-text">
                                    {
                                        params.type==="first-central" || params.type==="credit-registry" ?
                                        currencyFormatter(report.data.totalBorrowed, 2, "NGN")
                                        :
                                        `₦${report.data.totalBorrowed}`
                                    }
                                </p>
                            </div>
                        </Col>
                        <Col md={6} lg={4} xl={3}>
                            <div className="credit_boxes-singleNew">
                                <div className="credit_boxes-singleNew-top">
                                    <img src={outstanding} alt="" />
                                    <p>Total Outstanding Value</p>
                                </div>
                                <p className="credit_boxes-singleNew-text">
                                    {
                                        params.type==="credit-registry" ?
                                        currencyFormatter(report.data.totalOutstanding, 2, "NGN")
                                        :
                                        `₦${report.data.totalOutstanding}`
                                    }
                                </p>
                            </div>
                        </Col>
                        {
                            params.type==="CRC" && 
                            <Col md={6} lg={4} xl={3}>
                                <div className="credit_boxes-singleNew">
                                    <div className="credit_boxes-singleNew-top">
                                    <img src={last_reported} alt="" />
                                        <p>Last reported date</p>
                                    </div>
                                    <p className="credit_boxes-singleNew-text">
                                        {report.data.lastReportedDate}
                                    </p>
                                </div>
                            </Col>
                        }
                    </Row>
                }
            </div>

            {
                (params.type==="advanced"||params.type==="premium") ?
                <div>
                    <div className="grid grid-cols-2 gap-x-5 xl:grid-cols-1">
                        <div className="xl:w-2/3 md:w-full">
                            <CreditEnquiries 
                                data={
                                    report?.data?.creditEnquiries?.find(x => x?.source===currentPath)?.value || []
                                }
                                currentPath={currentPath}
                            />
                        </div>
                        <div className="xl:w-2/3 md:w-full">
                            <EmploymentHistory
                                data={
                                    report?.data?.employmentHistory?.find(x => x?.source===currentPath)?.value || []
                                }
                            />
                        </div>
                    </div>
                    {
                        currentPath==="CREDIT_REGISTRY" && 
                        <Creditors
                            data={
                                report?.data?.creditors?.find(x => x?.source===currentPath)?.value || []
                            } 
                        />
                    }
                    <LoanPerformance
                        data={
                            report?.data?.loanPerformance?.find(x => x?.source===currentPath)?.value
                        } 
                        currentPath={currentPath}
                    />
                    <LoanHistory
                        loanHistory={
                            report?.data?.loanHistory.find(x => x?.source===currentPath)?.value || []
                        } 
                        currentPath={currentPath}
                    />
                </div> 
                :
                searchParams.get('type')==="full" && (params.type==="CRC"||params.type==="first-central"||params.type==="credit-registry") ?
                <div>
                    <div className="grid grid-cols-2 gap-x-5 xl:grid-cols-1">
                        <div className="xl:w-2/3 md:w-full">
                            <CreditEnquiries 
                                data={report?.data?.creditEnquiries} 
                                currentPath={
                                    params.type==="CRC" ? "CRC" : params.type==="first-central" ? "FIRST_CENTRAL" : "CREDIT_REGISTRY"
                                } 
                            />
                        </div>
                        <div className="xl:w-2/3 md:w-full">
                            <EmploymentHistory data={report?.data?.employmentHistory} />
                        </div>
                    </div>
                    {
                        params.type==="credit-registry" && 
                        <Creditors
                            data={
                                report?.data?.creditors
                            } 
                        />
                    }
                    <LoanPerformance
                        data={report?.data?.loanPerformance} 
                        currentPath={
                            params.type==="CRC" ? "CRC" : params.type==="first-central" ? "FIRST_CENTRAL" : "CREDIT_REGISTRY"
                        }
                    />
                    <LoanHistory
                        loanHistory={report?.data?.loanHistory} 
                        currentPath={
                            params.type==="CRC" ? "CRC" : params.type==="first-central" ? "FIRST_CENTRAL" : "CREDIT_REGISTRY"
                        }
                    />
                </div>
                : <div></div>
            }
            <ShareReport show={showShare} cancel={()=>setShowShare(false)} bvn={report.bvn} />
        </div>
    )
}