import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { store } from './redux/store'
import { Provider } from 'react-redux'

if (window.top !== window.self) {
  window.top.location = window.self.location;
}

// const firebaseConfig = {
//   apiKey: "AIzaSyCLVJ7E3HUs884OdFw96-3AEQBAATeGkHU",
//   authDomain: "creditchekb2bdash.firebaseapp.com",
//   projectId: "creditchekb2bdash",
//   storageBucket: "creditchekb2bdash.appspot.com",
//   messagingSenderId: "847570055941",
//   appId: "1:847570055941:web:95d32918db254b5c3e7d6c",
//   measurementId: "G-YRZ7GBBDLF"

// };

// const app = initializeApp(firebaseConfig);

// const analytics = getAnalytics(app);

// console.info(analytics)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
