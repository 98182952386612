import { useEffect } from 'react';
import { toast } from "react-toastify";
import failed from "../../../../images/svg/float/verificationFailed.svg";
import success from "../../../../images/svg/float/verificationSuccess.svg";
import process from "../../../../images/svg/float/verificationProcess.gif";
import avatar from "../../../../images/svg/float/avatar.svg";
import { useNavigate,  } from 'react-router-dom';
import { FloatService } from 'services/float.service';
import { useSelector } from "react-redux";
import { DateField } from 'components/Form/DateField';
import { FormikProvider, useFormik } from 'formik';
import { TextField } from 'components/Form/TextField';
import Button from 'components/shared/Button';
import clsx from 'clsx';
import { CurrencyField } from 'components/Form/CurrencyField';
import { SelectField } from 'components/Form/SelectField';
import { useState } from 'react';
import { DragToUpload } from 'components/Form/DragToUpload';
import { floatDetailsSchema } from 'utils/validationSchemas';
import { handleRequestErrors } from 'utils/helpers';


function FloatDetails (
    {
        verification, 
        triggerPriceModal, 
        data, 
        ngBanks, 
        setData, 
        setPath,
        amountType
    }) {
    let navigate = useNavigate();
    const [nextLoading, setNextLoading] = useState(false)
    const businessId = useSelector(state => state?.loginData?.data?.user?.businessId)

    let floatId = '';
    let floatRequest;
    try {
        floatId = JSON.parse(sessionStorage.getItem('floatId'))
        floatRequest = JSON.parse(sessionStorage.getItem('floatRequest'))
    } catch (error) {
    }

    const options = [
        { value: "4 months", label: "4 months" },
        { value: "5 months", label: "5 months" },
        { value: "6 months", label: "6 months" },
        { value: "7 months", label: "7 months" },
        { value: "8 months", label: "8 months" },
        { value: "9 months", label: "9 months" },
        { value: "10 months", label: "10 months" },
        { value: "11 months", label: "11 months" },
    ];

    useEffect(() => {
        sessionStorage.setItem('floatRequest', JSON.stringify(data))
        form.setFieldValue("rcNumber", data.rcNumber||"")
        form.setFieldValue("dateOfRegistration", data.dateOfRegistration||"")
        form.setFieldValue("loanAmount", data.loanAmount||"")
        form.setFieldValue("loanDuration", data.loanDuration||"")
        form.setFieldValue("bankAccountNumber", data.bankAccountNumber||"")
        form.setFieldValue("bankName", data.bankName||"")
        form.setFieldValue("password", data.password||"")
        form.setFieldValue("bankStatement", data.bankStatement||null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const saveDetails = (password, statement) => {
        setNextLoading(true)
        let value = new FormData();
        value.append('floatId', floatId);
        value.append('businessId', businessId);
        value.append('identityUser', 'pdfStatement');
        value.append('file', statement);
        value.append('password', password ? password : floatRequest?.password);
        sessionStorage.setItem("float_upload", statement)
        FloatService.accountStatementVerification(value)
            .then((response) => response.data)
            .then((res) => {
                setNextLoading(false)
                if (res.status === 'success') {
                    toast.success('Successful')
                    setPath('director1')
                    navigate(`/float/new-request/director1`)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setNextLoading(false)
                handleRequestErrors(err)
            });
    }

    const form = useFormik({
        initialValues: {
            rcNumber: "",
            dateOfRegistration: "",
            loanAmount: "",
            loanDuration: "",
            bankName: "",
            bankAccountNumber: '',
            password: "",
            bankStatement: null,
        },
        validationSchema: floatDetailsSchema,
        onSubmit: (values) => {
            saveDetails(values.password, values.bankStatement)
            saveValues()
        }
    })

    const saveValues = () => {
        setData({
            ...data,
            rcNumber: form.values.rcNumber,
            dateOfRegistration: form.values.dateOfRegistration,
            loanAmount: form.values.loanAmount,
            loanDuration: form.values.loanDuration,
            bankName: form.values.bankName,
            bankAccountNumber: form.values.bankAccountNumber,
            password: form.values.password,
            bankStatement: form.values.bankStatement,
        })
    }

    return (
        <div className="w-[600px] block mt-[70px] mx-auto mb-[50px] md:w-full">
            <h5 className="font-medium text-[1.2em]">Float Details</h5>
            <div className='border-[1px] border-solid border-[#EAECF0] shadow-[0px_1px_3px_rgba(16,24,40,0.1),0px_1px_2px_rgba(16,24,40,0.06)] rounded-[10px] mt-2.5 box-border'>
                <FormikProvider value={form}>
                    <form onSubmit={form.handleSubmit}>
                        <div className='p-[20px]'>
                            <div className='flex items-center relative gap-x-2.5'>
                                <div 
                                    className={clsx(
                                        (!!data?.rcNumber && verification.rc==="success")?'w-full':'w-[calc(100%-110px)]'
                                    )}
                                >
                                <TextField 
                                    label="RC Number"
                                    name='rcNumber'
                                    type='text'
                                    placeholder="e.g 1234567"
                                    extraClassName={clsx(
                                        verification.rc==='processing'?'!border-cc-primary'
                                            : verification.rc==='success'?'!border-[#12B76A]'
                                            : verification.rc==='failed'?'!border-[#FDA29B]'
                                            : ''
                                    )}
                                    disabled={verification.rc==="success"}
                                    readOnly={verification?.rc==='processing'}
                                />
                                </div>
                                {
                                    !(!!data?.rcNumber && verification.rc==="success") && 
                                    <div className='w-[100px] mt-0.5'>
                                        <Button
                                            isValid={true}
                                            type="button"
                                            text="Verify"
                                            isLoading={verification?.rc==='processing'}
                                            className="h-10 !rounded-[8px]"
                                            onClick={()=>{
                                                triggerPriceModal('identity cac verification')
                                                saveValues()
                                            }}
                                        />
                                    </div>
                                }   
                                {
                                    verification.rc !== "none" &&
                                    <p
                                        className={clsx(
                                            "absolute bottom-0 text-[0.85em]",
                                            verification.rc==='processing' ? "text-[#7C7C7C]" 
                                                : verification.rc==='success' ? "text-[#12B76A]"
                                                : "text-[#F04438]"
                                        )}
                                    >
                                        {
                                            verification.rc==='processing'?'Processing...'
                                            :verification.rc==='success'?'Verification Successful'
                                            :'RC Number is incorrect'
                                        }
                                    </p>
                                }
                                {
                                    verification.rc!=="none" &&
                                    <img 
                                        src={verification.rc==='processing'?process:verification.rc==='success'?success:failed} 
                                        alt=""
                                        className={clsx(
                                            'absolute w-4 bottom-[36px]',
                                            (!!data?.rcNumber && verification.rc==="success") ?'right-[10px]':'right-[120px]'
                                        )}
                                    />
                                }
                            </div>
                            <DateField
                                name='dateOfRegistration'
                                placeholder="Select date"
                                onChange={(value)=>{
                                    form.setFieldValue("dateOfRegistration", value)
                                }}
                                label="Date of Registration"
                                value={form?.values?.dateOfRegistration}
                            />
                            <CurrencyField
                                onChange={(val)=>{
                                    form.setFieldValue("loanAmount", val.value )
                                }}
                                value={form.values.loanAmount}
                                name='loanAmount*'
                                label={`Loan Amount (in ${amountType})`}
                            />
                            <SelectField
                                name='loanDuration'
                                options={options}
                                label="Loan Duration"
                                placeholder="Loan Duration"
                                onChange={(selected)=>{
                                    form.setFieldValue( "loanDuration", selected.value )
                                }}
                                value={form?.values?.loanDuration}
                            />
                            <SelectField
                                searchable
                                name='bankName'
                                options={ngBanks?.map(item=>({
                                    label: item.name,
                                    value: item.name
                                }))}
                                label="Select Bank"
                                placeholder={form.values.bankName || "Select Bank"}
                                onChange={(selected)=>{
                                    form.setFieldValue( "bankName", selected.value )
                                }}
                                value={form?.values?.bankName}
                            />
                            <div>
                                <label className='font-semibold text-[#344054]'>Corporate Bank Account Number (recommended to use the most operational)</label>
                            </div>
                            <div className='flex items-center relative gap-x-2.5'>
                                <div 
                                    className={clsx(
                                        !!data?.rcNumber?'w-full':'w-[calc(100%-110px)]'
                                    )}
                                >
                                    <TextField
                                        name='bankAccountNumber'
                                        type='text'
                                        placeholder="Enter Bank Account Number"
                                        extraClassName={clsx(
                                            verification.acc==='processing'?'!border-cc-primary'
                                                : verification.acc==='success'?'!border-[#12B76A]'
                                                : verification.acc==='failed'?'!border-[#FDA29B]'
                                                : ''
                                        )}
                                        readOnly={verification?.acc==='processing'}
                                    />
                                </div>
                                <div className='w-[100px] -mt-[20px]'>
                                    <Button
                                        isValid={true}
                                        type="button"
                                        text="Verify"
                                        className="h-10 !rounded-[8px]"
                                        onClick={()=>{
                                            saveValues()
                                            triggerPriceModal('account number basic verification')
                                        }}
                                        isLoading={verification?.acc==='processing'}
                                    />
                                </div>   
                                {
                                    verification.acc !== "none" &&
                                    <p
                                        className={clsx(
                                            "absolute bottom-0 text-[0.85em]",
                                            verification.acc==='processing' ? "text-[#7C7C7C]" 
                                                : verification.acc==='success' ? "text-[#12B76A]"
                                                : "text-[#F04438]"
                                        )}
                                    >
                                        {
                                            verification.acc==='processing'?'Processing...'
                                            :verification.acc==='success'?'Verification Successful'
                                            :'Bank Account Number is incorrect'
                                        }
                                    </p>
                                }
                                {
                                    verification.acc!=="none" &&
                                    <img 
                                        src={verification.acc==='processing'?process:verification.acc==='success'?success:failed} 
                                        alt=""
                                        className='absolute w-4 right-[110px] bottom-[36px]'
                                    />
                                }
                            </div>
                            <div className='box-border mb-3'>
                                <label className='font-semibold text-[#344054]'>Upload recent 6 Months Bank Statement</label>
                                <div className="mt-[15px] items-start flex box-border">
                                    <img src={avatar} alt="" className="w-[60px]" />
                                    <div className="box-border w-full ml-[15px] text-ellipsis overflow-hidden whitespace-nowrap">
                                        <DragToUpload
                                            name="bankStatement"
                                            fileType="pdf"
                                            message={<span>PDF files ONLY &#40;max. 10MB&#41;</span>}
                                            onChange={(file)=>form.setFieldValue("bankStatement", file)}
                                        />
                                        <p className='-mt-2.5'>{form?.values?.bankStatement?.name||""}</p>
                                    </div>
                                </div>  
                            </div>
                            <TextField
                                label="PDF Password(if available)"
                                name='password'
                                type='password'
                                placeholder="Enter PDF password (optional)"
                            />
                        </div>
                        <hr/>
                        <div className='p-[20px] flex'>
                            <div className='w-[120px] ml-auto'>
                                <Button
                                    isValid={verification.rc==='success' && verification.acc==='success'}
                                    text="Next Step"
                                    type="submit"
                                    isLoading={nextLoading}
                                />
                            </div>
                        </div>
                    </form>
                </FormikProvider>
            </div>
        </div>
    )
}

export default FloatDetails;