import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';

import perf from "../../images/pdf-export/perf.png";
import crc from "../../images/pdf-export/crc.png";
import first_central from "../../images/pdf-export/first_central.png";
import credit_registry from "../../images/pdf-export/credit_registry.png";
import { currencyFormatter } from "utils/helpers";

const months=['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const jsPDFGenerator = (report, params, arrayIndex) => {
    const doc = new jsPDF({
        // orientation: "landscape",
        unit: "mm",
        format: [210, 297]
    });

    doc.setFont("DMSans-Regular", "normal", "bold");
    doc.setFillColor('#0046E6');
    doc.roundedRect(55, 115, 100, 10, 1, 1, 'F');
    doc.setTextColor("#ffffff")
    doc.setFont("DMSans-Regular", "normal", "normal");
    doc.text(`${report.name}`, 105, 122, {align:"center"});
    doc.setTextColor("#000")
    doc.text(`${params.type==="CRC"?"CRC":params.type==="first-central"?"First Central":"Premium"} Business Credit Report - ${new Date().getDate()} ${months[new Date().getMonth()]}, ${new Date().getFullYear()}`, 105, 135, {align:"center"});

    
    var imgCrc = new Image();
    imgCrc.src = crc;
    var imgFC = new Image();
    imgFC.src = first_central;
    var imgCR = new Image();
    imgCR.src = credit_registry;

    if(params.type==="CRC" || params.type==="first-central" ){
        // crc or first central report page
        doc.addPage()
        doc.setFont("DMSans-Regular", "normal", "bold")
        doc.setFontSize(20)
        doc.addImage(params.type==="CRC"?imgCrc:imgFC, 'png', 20, 15, 15, 15);
        doc.text(`${params.type==="CRC"?"CRC":"First Central"}`, 40, 20);
        doc.setFontSize(14)
        doc.setFont("DMSans-Regular", "normal", "normal")
        doc.text(`Credit Overview`, 40, 28);

        doc.setFontSize(11.5)
        doc.setTextColor("#667085")
        doc.setFont("Inter", "normal", "normal")
        doc.text(`Total no of credit facilities`, 20, 50);
        doc.setFont("Inter", "normal", "bold");
        doc.setTextColor("#101828")
        doc.setFontSize(16)
        doc.text(`${report.data.totalNoOfLoans}`, 20, 60);

        doc.setFontSize(11.5)
        doc.setTextColor("#667085")
        doc.setFont("Inter", "normal", "normal")
        doc.text(`Total no of open credit facilities`, 77, 50);
        doc.setFont("Inter", "normal", "bold");
        doc.setTextColor("#101828")
        doc.setFontSize(16)
        doc.text(`${report.data.totalNoOfActiveLoans}`, 77, 60);
        
        doc.setFontSize(11.5)
        doc.setTextColor("#667085")
        doc.setFont("Inter", "normal", "normal")
        doc.text(`Total no of closed credit facilities`, 134, 50);
        doc.setFont("Inter", "normal", "bold");
        doc.setTextColor("#101828")
        doc.setFontSize(16)
        doc.text(`${report.data.totalNoOfClosedLoans}`, 134, 60);

        
        doc.setDrawColor("#D6D6D6");
        doc.line(20,65, 190,65)

        doc.setFontSize(11.5)
        doc.setTextColor("#667085")
        doc.setFont("Inter", "normal", "normal")
        doc.text(`Total no of delinquent facilities`, 20, 80);
        doc.setFont("Inter", "normal", "bold");
        doc.setTextColor("#101828")
        doc.setFontSize(16)
        doc.text(`${report.data.totalNoOfDelinquentFacilities}`, 20, 90);

        doc.setFontSize(11.5)
        doc.setTextColor("#667085")
        doc.setFont("Inter", "normal", "normal")
        doc.text(`Total no of credit grantors`, 77, 80);
        doc.setFont("Inter", "normal", "bold");
        doc.setTextColor("#101828")
        doc.setFontSize(16)
        doc.text(`${report.data.totalNoOfInstitutions}`, 77, 90);

        doc.setFontSize(11.5)
        doc.setTextColor("#667085")
        doc.setFont("Inter", "normal", "normal")
        doc.text(`Total Performing Loans`, 134, 80);
        doc.setFont("Inter", "normal", "bold");
        doc.setTextColor("#101828")
        doc.setFontSize(16)
        doc.text(`${report.data.totalNoOfPerformingLoans}`, 134, 90);
        
        doc.line(20,95, 190,95)
        
        doc.setFontSize(11.5)
        doc.setTextColor("#667085")
        doc.setFont("Inter", "normal", "normal")
        doc.text(`Total Due (overdue)`, 20, 110);
        doc.setFont("Inter", "normal", "bold");
        doc.setTextColor("#101828")
        doc.setFontSize(16)
        doc.text(`${currencyFormatter(report.data.totalOverdue, 2, "NGN")}`, 20, 120);

        doc.setFontSize(11.5)
        doc.setTextColor("#667085")
        doc.setFont("Inter", "normal", "normal")
        doc.text(`Total Loan Value`, 77, 110);
        doc.setFont("Inter", "normal", "bold");
        doc.setTextColor("#101828")
        doc.setFontSize(16)
        doc.text(`${currencyFormatter(report.data.totalBorrowed, 2, "NGN")}`, 77, 120);

        doc.setFontSize(11.5)
        doc.setTextColor("#667085")
        doc.setFont("Inter", "normal", "normal")
        doc.text(`Total Oustanding Value`, 134, 110);
        doc.setFont("Inter", "normal", "bold");
        doc.setTextColor("#101828")
        doc.setFontSize(16)
        doc.text(`${currencyFormatter(report.data.totalOutstanding, 2, "NGN")}`, 134, 120);
        
        doc.line(20,125, 190,125)

        doc.setFontSize(11.5)
        doc.setTextColor("#667085")
        doc.setFont("Inter", "normal", "normal")
        doc.text(`Highest Loan Request`, 20, 140);
        doc.setFont("Inter", "normal", "bold");
        doc.setTextColor("#101828")
        doc.setFontSize(16)
        doc.text(`${currencyFormatter(report.data.highestLoanAmount, 2, "NGN")}`, 20, 150);

        if(params.type==="CRC"){
            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Last Reported Date`, 77, 140);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report.data.lastReportedDate}`, 77, 150);
        }

        if(params.type==="first-central"){
            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Overdue Accounts`, 77, 140);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report.data.totalNoOfOverdueAccounts}`, 77, 150);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Monthly Installment`, 134, 140);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${currencyFormatter(report.data.totalMonthlyInstallment, 2, "NGN")}`, 134, 150);
        }

    } else {
        
        var img2 = new Image()
        img2.src = perf

        if(true){
            doc.addPage()
            doc.setFont("DMSans-Regular", "normal", "bold")
            doc.setFontSize(20)
            doc.addImage(imgCrc, 'png', 20, 15, 15, 15);
            doc.text(`CRC`, 40, 20);
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Credit Overview`, 40, 28);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.text(`Total no of credit facilities`, 20, 50);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfLoans[0].value}`, 20, 60);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of open credit facilities`, 77, 50);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfActiveLoans[0]?.value}`, 77, 60);
            
            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of closed credit facilities`, 134, 50);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfClosedLoans[0].value}`, 134, 60);

            
            doc.setDrawColor("#D6D6D6");
            doc.line(20,65, 190,65)

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of delinquent facilities`, 20, 80);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfDelinquentFacilities[0].value}`, 20, 90);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of credit grantors`, 77, 80);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfInstitutions[0].value}`, 77, 90);
            
            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Performing Loans`, 134, 80);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfPerformingLoans[0].value}`, 134, 90);
            
            doc.line(20,95, 190,95)
            
            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Due (overdue)`, 20, 110);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${currencyFormatter(report?.data?.totalOverdue[0].value, 2, "NGN")}`, 20, 120);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Loan Value`, 77, 110);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${currencyFormatter(report?.data?.totalBorrowed[0].value, 2, "NGN")}`, 77, 120);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Highest Loan Request`, 134, 110);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${currencyFormatter(report?.data?.highestLoanAmount[0].value, 2, "NGN")}`, 134, 120);
            
            doc.line(20,125, 190,125)

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Oustanding Value`, 20, 140);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${currencyFormatter(report?.data?.totalOutstanding[0].value, 2, "NGN")}`, 20, 150);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Monthly Installment`, 77, 140);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${currencyFormatter(report?.data?.totalMonthlyInstallment[0].value, 2, "NGN")}`, 77, 150);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Last Reported Date`, 134, 140);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.lastReportedDate[0].value}`, 134, 150);
    
    
            // credit enquiries
            doc.addPage();
            doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Credit Enquiries`, 22, 20);
            doc.setFont("Inter", "normal", "normal")
            autoTable(doc, {
                styles: {textColor: "#667085"},
                theme: 'grid',
                margin: { top: 25 },
                bodyStyles: {lineColor: "#EAECF0"},
                headStyles: { fillColor: "#0046E6", textColor: "white" },
                columnStyles: { 0: { textColor: "black" } },
                  head: [['Facility Type', 'Loan Type', 'Inquiry Date']],
                  body: report?.data?.creditEnquiries?.[0]?.value.map((item)=>[
                    item.institutionType, item.loanType, item.date
                  ])
            })

            // credit profile overview
            doc.addPage();
            doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Credit Profile Overview`, 22, 20);
            doc.setFont("Inter", "normal", "normal")
            autoTable(doc, {
                styles: {textColor: "#667085"},
                theme: 'grid',
                margin: { top: 25 },
                bodyStyles: {lineColor: "#EAECF0"},
                headStyles: { fillColor: "#0046E6", textColor: "white" },
                columnStyles: { 0: { textColor: "black" } },
                  head: [['Indicator', 'Value']],
                  body: report?.data?.creditProfileOverview?.[0]?.value.map((item)=>[
                    item.indicator, item.value
                  ])
            })
    
    
    
            // loan performance
            doc.addPage();
            doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Loan Performance`, 22, 20);
            doc.setFont("Inter", "normal", "normal")
            autoTable(doc, {
                styles: {textColor: "#667085"},
                theme: 'grid',
                margin: { top: 25 },
                bodyStyles: {lineColor: "#EAECF0"},
                headStyles: { fillColor: "#0046E6", textColor: "white" },
                columnStyles: { 0: { textColor: "black" } },
                head: [['Loan Provider', 'Loan Count', 'Loan Amount', 'Oustanding Balance', 'Overdue Amount', 'Status', 'Performance Status']],
                body: report?.data?.loanPerformance?.[0]?.value.map((item)=>[
                    item.loanProvider, item.loanCount, item.loanAmount, `NGN ${item.outstandingBalance}`, `NGN ${item.overdueAmount}`, item.status, item.performanceStatus
                ])
            })
    
            // loan history
            doc.addPage();
            doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Loan History`, 22, 20);
            doc.setFont("Inter", "normal", "normal")
            autoTable(doc, {
                styles: {textColor: "#667085"},
                theme: 'grid',
                margin: { top: 25 },
                bodyStyles: {lineColor: "#EAECF0"},
                headStyles: { fillColor: "#0046E6", textColor: "white" },
                columnStyles: { 0: { textColor: "black" } },
                head: [['Loan Provider',  'Account Number', 'Loan Amount', 'Outstanding Balance', 'Overdue Amount', 'Type', 'Date Reported']],
                body: report?.data?.loanHistory?.[0]?.value.map((item)=>[
                    item.loanProvider, item.accountNumber,  `${currencyFormatter(item.loanAmount, 2, "NGN")}`, `${currencyFormatter(item.outstandingBalance, 2, "NGN")}`, `${currencyFormatter(item.overdueAmount, 2, "NGN")}`, item.type, item.dateReported
                ])
            })
        }
        
        if (true){
            doc.addPage()
            doc.setFont("DMSans-Regular", "normal", "bold")
            doc.setFontSize(20)
            doc.addImage(imgFC, 'png', 20, 15, 15, 15);
            doc.text(`First Central`, 40, 20);
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Credit Overview`, 40, 28);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.text(`Total no of credit facilities`, 20, 50);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfLoans[1].value}`, 20, 60);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of open credit facilities`, 77, 50);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfActiveLoans?.[1]?.value}`, 77, 60);
            
            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of closed credit facilities`, 134, 50);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfClosedLoans[1].value}`, 134, 60);

            
            doc.setDrawColor("#D6D6D6");
            doc.line(20,65, 190,65)

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of delinquent facilities`, 20, 80);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfDelinquentFacilities[1].value}`, 20, 90);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of credit grantors`, 77, 80);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfInstitutions[1].value}`, 77, 90);
            
            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Performing Loans`, 134, 80);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfPerformingLoans[1].value}`, 134, 90);
            
            doc.line(20,95, 190,95)
            
            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Due (overdue)`, 20, 110);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${currencyFormatter(report?.data?.totalOverdue[1].value, 2, "NGN")}`, 20, 120);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Loan Value`, 77, 110);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${currencyFormatter(report?.data?.totalBorrowed[1].value, 2, "NGN")}`, 77, 120);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Highest Loan Request`, 134, 110);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${currencyFormatter(report?.data?.highestLoanAmount[1].value, 2, "NGN")}`, 134, 120);
            
            doc.line(20,125, 190,125)

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Oustanding Value`, 20, 140);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${currencyFormatter(report?.data?.totalOutstanding[1].value, 2, "NGN")}`, 20, 150);
            
            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Monthly Installments`, 77, 140);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalMonthlyInstallment[1].value}`, 77, 150);
    
    
        //     // credit enquiries
        //     doc.addPage();
        //     doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
        //     doc.setFontSize(14)
        //     doc.setFont("DMSans-Regular", "normal", "normal")
        //     doc.text(`Credit Enquiries`, 22, 20);
        //     doc.setFont("Inter", "normal", "normal")
        //     autoTable(doc, {
        //         styles: {textColor: "#667085"},
        //         theme: 'grid',
        //         margin: { top: 25 },
        //         bodyStyles: {lineColor: "#EAECF0"},
        //         headStyles: { fillColor: "#0046E6", textColor: "white" },
        //         columnStyles: { 0: { textColor: "black" } },
        //           head: [['Loan Provider', 'Inquiry Date']],
        //           body: report?.data?.creditEnquiries?.[1]?.value.map((item)=>[
        //             item.loanProvider, item.date
        //           ])
        //     })
    
    
    
            // loan performance
            doc.addPage();
            doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Loan Performance`, 22, 20);
            doc.setFont("Inter", "normal", "normal")
            autoTable(doc, {
                styles: {textColor: "#667085"},
                theme: 'grid',
                margin: { top: 25 },
                bodyStyles: {lineColor: "#EAECF0"},
                headStyles: { fillColor: "#0046E6", textColor: "white" },
                columnStyles: { 0: { textColor: "black" } },
                head: [['Loan Provider', 'Loan Count', 'Loan Amount', 'Oustanding Balance', 'Overdue Amount', 'Status', 'Performance Status']],
                body: report?.data?.loanPerformance?.[1]?.value.map((item)=>[
                    item.loanProvider, item.loanCount, `${currencyFormatter(item.loanAmount, 2, "NGN")}`, `NGN ${item.outstandingBalance}`, `NGN ${item.overdueAmount}`, item.status, item.performanceStatus
                ])
            })
    
            // loan history
            doc.addPage();
            doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Loan History`, 22, 20);
            doc.setFont("Inter", "normal", "normal")
            autoTable(doc, {
                styles: {textColor: "#667085"},
                theme: 'grid',
                margin: { top: 25 },
                bodyStyles: {lineColor: "#EAECF0"},
                headStyles: { fillColor: "#0046E6", textColor: "white" },
                columnStyles: { 0: { textColor: "black" } },
                head: [['Loan Provider', 'Account Number', 'Loan Amount', 'Outstanding Balance', 'Overdue Amount', 'Type','Status', 'Date Reported']],
                body: report?.data?.loanHistory?.[1]?.value.map((item)=>[
                    item.loanProvider, item.accountNumber,  `NGN ${item.loanAmount}`, `NGN ${item.outstandingBalance}`, `NGN ${item.overdueAmount}`, item.type, item.status, item.dateReported
                ])
            })
        }
    }

    doc.save(`${report.name}_${params.type}_report.pdf`);

}