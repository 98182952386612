import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { IncomeService } from "services/income.service";
import { currencyFormatter } from "utils/helpers";


function LineChartSuper({item, month}) {
  const [monoData, setMonoData] = useState({})
  
  useEffect(()=>{
    const payload = JSON.stringify({
        businessId : item.businessId,
        borrowerId: item.borrowerId,
        accountNumber: item.accountNumber
    })
    IncomeService.getChartData(payload)
      .then((response) => response.data)
      .then((res) => {
          if (res.status === "success" ) {
            setMonoData(res.data);
          } else {
            // err
          }
      })
      .catch((err) => {
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const data = [
    {
      name: month[0].substring(0, 3),
      debit: monoData?.debit?.[0]/100,
      credit: monoData?.credit?.[0]/100,
    },
    {
      name: month[1].substring(0, 3),
      debit: monoData?.debit?.[1]/100,
      credit: monoData?.credit?.[1]/100,
    },
    {
      name: month[2].substring(0, 3),
      debit: monoData?.debit?.[2]/100,
      credit: monoData?.credit?.[2]/100,
    },
    {
      name: month[3].substring(0, 3),
      debit: monoData?.debit?.[3]/100,
      credit: monoData?.credit?.[3]/100,
    },
    {
      name: month[4].substring(0, 3),
      debit: monoData?.debit?.[4]/100,
      credit: monoData?.credit?.[4]/100,
    },
    {
      name: month[5].substring(0, 3),
      debit: monoData?.debit?.[5]/100,
      credit: monoData?.credit?.[5]/100,
    },
    
  ];

  
  

  return (
    <div style={{ marginLeft: "-7.7px" }}>
      <div style={{ height: "6px" }}></div>
      <LineChart
        width={390}
        height={220}
        data={data}
        margin={{
          top: 0,
          right: 40,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid
          strokeDasharray="3 3"
          horizontal={true}
          vertical={false}
        />
        <XAxis dataKey="name" stroke="white" />
        <YAxis stroke="white" />
        <Tooltip 
          formatter={
            (value, name) => 
              (name === "debit" || name === "credit") && `${currencyFormatter(value, 2, "NGN")}`
          }
          contentStyle={{backgroundColor:"#fff", color: '#000', textTransform:"capitalize"}} 
          itemStyle={{color:"#000"}} 
        />
        <Legend />
        <Line
          type="monotone"
          dataKey="credit"
          stroke="#6DFF64"
          activeDot={{ r: 8 }}
          dot={false}
        />
        <Line
          type="monotone"
          dataKey="debit"
          stroke="red"
          activeDot={{ r: 8 }}
          dot={false}
        />
      </LineChart>
    </div>
  );
}

export default LineChartSuper;
