
import { useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { TextField } from 'components/Form/TextField';
import Button from 'components/shared/Button';
import clsx from 'clsx';
import { SelectField } from 'components/Form/SelectField';

import greenCheck from "../../../../images/svg/float/green-check.svg";
import failed from "../../../../images/svg/float/verificationFailed.svg";
import success from "../../../../images/svg/float/verificationSuccess.svg";
import process from "../../../../images/svg/float/verificationProcess.gif";
import { useEffect, useState } from 'react';
import { UploadFile } from 'components/Form/UploadFile';
import { FloatService } from 'services/float.service';
import { handleRequestErrors } from 'utils/helpers';
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";


function Director1 ({setPath, verification, data, setData, ngBanks, triggerPriceModal, handleDirectorConsent}) {
    let navigate = useNavigate();
    const [nextLoading, setNextLoading] = useState(false)
    const businessId = useSelector(state => state?.loginData?.data?.user?.businessId)

    useEffect(() => {
        form.setFieldValue("director1Fullname", data.director1Fullname||"")
        form.setFieldValue("director1BVN", data.director1BVN||"")
        form.setFieldValue("director1BankName", data.director1BankName||"")
        form.setFieldValue("director1BankAccountNumber", data.director1BankAccountNumber||"")
        form.setFieldValue("director1Position", data.director1Position||"")
        form.setFieldValue("director1Phone", data.director1Phone||"")
        form.setFieldValue("director1Email", data.director1Email||"")
        form.setFieldValue("director1Address", data.director1Address||"")
        form.setFieldValue("director1Passport", data.director1Passport||null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    let floatId = '';
    try {
        floatId = JSON.parse(sessionStorage.getItem('floatId'))
    } catch (error) {
    }
    
    const nextSection = () => {
        setPath('director2')
        navigate(`/float/new-request/director2`)
    }

    const handleBack = () => {
        setPath('details')
        navigate(`/float/new-request`)
    }
    
    const positionOptions = [
        "Guarantor",
        "Proprietor",
        "Shareholder",
        "Managing Director",
        "Executive Director",
        "Alternate Director",
        "Trustee"
    ];

    const savePassport = (passport) => {
        setNextLoading(true)
        let value = new FormData();
        value.append('businessId', businessId);
        value.append('floatId', floatId);
        value.append('file', passport);
        FloatService.uploadDirectorPassport("director1", value)
            .then((response) => response.data)
            .then((res) => {
                setNextLoading(false)
                if (res.status === 'success') {
                    nextSection()
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setNextLoading(false)
                handleRequestErrors(err)
            });
    }

    const form = useFormik({
        initialValues: {
            director1Fullname: "",
            director1BVN: "",
            director1BankName: "",
            director1BankAccountNumber: "",
            director1Position: "",
            director1Phone: '',
            director1Email: "",
            director1Address: "",
            director1Passport: null,
        },
        onSubmit: (values) => {
            saveValues()
            savePassport(values.director1Passport)
        }
    })

    const saveValues = () => {
        setData({
            ...data,
            director1Fullname: form.values?.director1Fullname,
            director1BVN: form.values?.director1BVN,
            director1BankName: form.values?.director1BankName,
            director1BankAccountNumber: form.values?.director1BankAccountNumber,
            director1Position: form.values?.director1Position,
            director1Phone: form.values?.director1Phone,
            director1Email: form.values?.director1Email,
            director1Address: form.values?.director1Address,
            director1Passport: form.values?.director1Passport,
        })
    }
    
    return (
        <div className="w-[600px] block mt-[70px] mx-auto mb-[50px] md:w-full">
            <h5 className="font-medium text-[1.2em]">Director 1</h5>
            <div className='border-[1px] border-solid border-[#EAECF0] shadow-[0px_1px_3px_rgba(16,24,40,0.1),0px_1px_2px_rgba(16,24,40,0.06)] rounded-[10px] mt-2.5 box-border'>
                <FormikProvider value={form}>
                    <form onSubmit={form.handleSubmit}>
                        <div className='p-[20px]'>
                            <div className="relative">
                                <TextField
                                    label="Full Name"
                                    name='director1Fullname'
                                    type='text'
                                    placeholder="Enter full name"
                                />
                                <p className='flex items-center right-0 absolute bottom-0 text-xs text-cc-grey-8'>
                                    <img src={greenCheck} alt="" className="w-[10px] mr-1" />
                                    <span>Name must match with <span className='font-bold text-black'>BVN</span> registered name.</span>
                                </p>
                            </div>
                            <div className='flex items-center relative gap-x-2.5'>
                                <div className='w-[calc(100%-110px)]'>
                                <TextField
                                    label="Bank Verification Number"
                                    name='director1BVN'
                                    type='text'
                                    placeholder="Enter BVN"
                                    extraClassName={clsx(
                                        verification.bvn1==='processing'?'!border-cc-primary'
                                            : verification.bvn1==='success'?'!border-[#12B76A]'
                                            : verification.bvn1==='failed'?'!border-[#FDA29B]'
                                            : ''
                                    )}
                                    readOnly={verification?.bvn1==='processing'}
                                />
                                </div>
                                <div className='w-[100px] mt-0.5'>
                                    <Button
                                        isValid={true}
                                        type="button"
                                        text="Verify"
                                        className="h-10 !rounded-[8px]"
                                        onClick={()=>{
                                            saveValues()
                                            triggerPriceModal('identity bvn verification')
                                        }}
                                        isLoading={verification?.bvn1==='processing'}
                                    />
                                </div>
                                {
                                    verification.bvn1 !== "none" &&
                                    <p
                                        className={clsx(
                                            "absolute bottom-0 text-[0.85em]",
                                            verification.bvn1==='processing' ? "text-[#7C7C7C]" 
                                                : verification.bvn1==='success' ? "text-[#12B76A]"
                                                : "text-[#F04438]"
                                        )}
                                    >
                                        {
                                            verification.bvn1==='processing'?'Processing...'
                                            :verification.bvn1==='success'?'Verification Successful'
                                            :'BVN is incorrect'
                                        }
                                    </p>
                                }
                                {
                                    verification.bvn1!=="none" &&
                                    <img 
                                        src={verification.bvn1==='processing'?process:verification.bvn1==='success'?success:failed} 
                                        alt=""
                                        className='absolute w-4 right-[120px] bottom-[36px]'
                                    />
                                }
                            </div>
                            <SelectField
                                searchable
                                name='director1BankName'
                                options={ngBanks?.map(item=>({
                                    label: item.name,
                                    value: item.name
                                }))}
                                label="Select Bank"
                                placeholder={form.values.director1BankName || "Select Bank"}
                                onChange={(selected)=>{
                                    form.setFieldValue( "director1BankName", selected.value )
                                }}
                                value={form?.values?.director1BankName}
                            />
                            <div className='flex items-center relative gap-x-2.5'>
                                <div className='w-[calc(100%-110px)]'>
                                <TextField
                                    label="Director's Bank Account Number"
                                    name='director1BankAccountNumber'
                                    type='text'
                                    placeholder="Enter Bank Account Number"
                                    extraClassName={clsx(
                                        verification.acc1==='processing'?'!border-cc-primary'
                                            : verification.acc1==='success'?'!border-[#12B76A]'
                                            : verification.acc1==='failed'?'!border-[#FDA29B]'
                                            : ''
                                    )}
                                    readOnly={verification?.acc1==='processing'}
                                />
                                </div>
                                <div className='w-[100px] mt-0.5'>
                                    <Button
                                        isValid={true}
                                        type="button"
                                        text="Verify"
                                        className="h-10 !rounded-[8px]"
                                        onClick={()=>{
                                            triggerPriceModal('account number basic verification')
                                            saveValues()
                                        }}
                                        isLoading={verification?.acc1==='processing'}
                                    />
                                </div>
                                {
                                    verification.acc1 !== "none" &&
                                    <p
                                        className={clsx(
                                            "absolute bottom-0 text-[0.85em]",
                                            verification.acc1==='processing' ? "text-[#7C7C7C]" 
                                                : verification.acc1==='success' ? "text-[#12B76A]"
                                                : "text-[#F04438]"
                                        )}
                                    >
                                        {
                                            verification.acc1==='processing'?'Processing...'
                                            :verification.acc1==='success'?'Verification Successful'
                                            :'No account match'
                                        }
                                    </p>
                                }
                                {
                                    verification.acc1!=="none" &&
                                    <img 
                                        src={verification.acc1==='processing'?process:verification.acc1==='success'?success:failed} 
                                        alt=""
                                        className='absolute w-4 right-[120px] bottom-[36px]'
                                    />
                                }
                            </div>
                            <SelectField
                                name='director1Position'
                                options={positionOptions.map(item=>({
                                    label: item,
                                    value: item
                                }))}
                                label="Position/Role"
                                placeholder={form.values.director1Position || "Select Role"}
                                onChange={(selected)=>{
                                    form.setFieldValue( "director1Position", selected.value )
                                }}
                                value={form?.values?.director1Position}
                            />
                            <TextField
                                label="Phone Number"
                                name='director1Phone'
                                type='text'
                                placeholder="Enter phone number"
                            />
                            <TextField
                                label="Residential Address"
                                name='director1Address'
                                type='text'
                                placeholder="Enter residential address"
                            />
                            <div className='flex items-center relative gap-x-2.5'>
                                <div className='w-[calc(100%-130px)]'>
                                <TextField
                                    label="Email Address"
                                    name='director1Email'
                                    type='email'
                                    placeholder="Enter email address"
                                    extraClassName={clsx(
                                        verification.cons1==='processing'?'!border-cc-primary'
                                            : verification.cons1==='success'?'!border-[#12B76A]'
                                            : verification.cons1==='failed'?'!border-[#FDA29B]'
                                            : ''
                                    )}
                                />
                                </div>
                                <div className='w-[120px] mt-0.5'>
                                    <Button
                                        isValid={true}
                                        type="button"
                                        text="Get Consent"
                                        className="h-10 !rounded-[8px]"
                                        onClick={()=>{
                                            handleDirectorConsent(form.values)
                                        }}
                                        isLoading={verification?.cons1==='processing'}
                                    />
                                </div>
                                {
                                    verification.cons1 !== "none" &&
                                    <p
                                        className={clsx(
                                            "absolute bottom-0 text-[0.85em]",
                                            verification.cons1==='processing' ? "text-[#7C7C7C]" 
                                                : verification.cons1==='success' ? "text-[#12B76A]"
                                                : "text-[#F04438]"
                                        )}
                                    >
                                        {
                                            verification.cons1==='processing'?'Processing...'
                                            :verification.cons1==='success'?'Consent sent successfully'
                                            :'Consent request failed'
                                        }
                                    </p>
                                }
                                {
                                    verification.cons1!=="none" &&
                                    <img 
                                        src={verification.cons1==='processing'?process:verification.cons1==='success'?success:failed} 
                                        alt=""
                                        className='absolute w-4 right-[140px] bottom-[36px]'
                                    />
                                }
                            </div>
                            <UploadFile 
                                label="Upload International Passport"
                                name="director1Passport"
                                onChange={(file)=>form.setFieldValue("director1Passport", file)}
                                value={form.values.director1Passport}
                            />
                        </div>
                        <hr/>
                        <div className='p-[20px] flex'>
                            <div className="w-[250px] ml-auto flex gap-x-2.5">
                                <div className='w-[120px]'>
                                    <Button
                                        isValid={true}
                                        text="Go Back"
                                        isLoading={false}
                                        type="button"
                                        className="bg-[#A0A0A0]"
                                        onClick={handleBack}
                                    />
                                </div>
                                <div className='w-[120px]'>
                                    <Button
                                        isValid={verification.bvn1==='success' && verification.acc1==='success' && verification.cons1==='success' && form.isValid}
                                        text="Next Step"
                                        isLoading={nextLoading}
                                        type="submit"
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </FormikProvider>
            </div>
        </div>
    )
}

export default Director1;