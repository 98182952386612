import {useEffect, useState} from "react";
import Back from "../../../components/shared/back";
import { FormikProvider, useFormik } from "formik";
import { TextField } from "components/Form/TextField";
import { DateField } from "components/Form/DateField";
import { SelectField } from "components/Form/SelectField";
import { dateFormatter, handleRequestErrors } from "utils/helpers";
import Button from "components/shared/Button";
import { complianceProfile } from "utils/validationSchemas";
import { useSelector } from "react-redux";
import { IdentityService } from "services/identity.service";
import FormLoader from "./components/FormLoader";


function Profile ({prevButton, nextButton}) {
    const [isForm, setIsForm] = useState(true)
    const isNigerianBusiness = useSelector(state => state?.walletData?.wallet?.currency)==="naira";
    const b2b_data = useSelector(state => state?.loginData?.data);
    const [isLoading, setIsLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true)

    useEffect(()=>{
        IdentityService.getComplianceProfile(b2b_data?.businessId||b2b_data?.user?.businessId)
        .then((response) => response.data)
        .then((res) => {
            if(res.status === true){
                if(res?.data?.complianceProfileStatus) {
                    nextButton()
                }
                form.setFieldValue("businessName", res?.data?.businessName || "")
                form.setFieldValue("ownersName", res?.data?.ownerFullName || "")
                form.setFieldValue("dob", res?.data?.dateOfBirth || "")
                form.setFieldValue("registrationType", res?.data?.registrationType || "")
                form.setFieldValue("size", res?.data?.size || "")
                form.setFieldValue("industry", res?.data?.industry || "")
                form.setFieldValue("incorporationDate", res?.data?.incorporationDate || "")
                form.setFieldValue("directorsBVN", res?.data?.directorBVN || "")
                setInitialLoading(false)
            }
        })
        .catch((err) => {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const form = useFormik({
        initialValues: {
            businessName: "",
            ownersName: "",
            dob: "",
            registrationType: "",
            size: "",
            industry: "",
            incorporationDate: "",
            directorsBVN: "",
        },
        validationSchema: complianceProfile(isNigerianBusiness), 
        onSubmit: (values) => {
            setIsForm(false)
        }
    })

    const submitProfile = () => {
        const {values} = form
        const payload = JSON.stringify({
            businessName: values.businessName,
            ownerFullName: values.ownersName,
            dateOfBirth: values.dob,
            ...(!!values.registrationType ? {registrationType: values.registrationType}:{}),
            size: values.size,
            industry: values.industry,
            incorporationDate: values.incorporationDate,
            ...(!!values.directorsBVN ? {directorBVN: values.directorsBVN}:{}),
        })
        setIsLoading(true)
        IdentityService.sendComplianceProfile(payload)
            .then((response) => response.data)
            .then((res) => {
                setIsLoading(false)
                if(res.status === true){
                    nextButton()
                }
            })
            .catch((err) => {
                setIsLoading(false)
                handleRequestErrors(err)
            });
    }

    const regTypeOptions = ["Limited Liability Limited", "Business Name","Private Entity", "Incorporated Trustee", "Free Zone Entity", "Government Entity", "Cooperative Society"]
    const sizeOptions = ["1-5", "6-10", "11-20", "21-100", "101-250", "251 and above"]
    const industryOptions = ["Finance", "Retail & Distribution", "Agriculture", "Chemical (manufacturing)", "Pharmaceuticals", "Computing (software)", "Computinng (Hardware)", "Construction", "Education", "Energy", "Entertainment", "Food", "Health Care", "Hospitality", "Information", "Manufacturing", "Telecommunication", "Transport", "Sports Management & Event Promotion" ]

    return(
        <div>
            {
                initialLoading ?
                <FormLoader title="Loading form content" message="Please wait while we load your form content" />
                :
                <div className="box-border">
                    <div className="-mb-[30px]" style={{marginBottom:"-30px"}}>
                        <Back  to={()=> isForm ? prevButton():setIsForm(true)} />
                    </div>
                    <h5 className="text-xl font-medium">Profile</h5>
                    {isForm ?
                        <FormikProvider value={form}>
                            <form onSubmit={form.handleSubmit}>
                                <p className="mb-[20px]">
                                    { 
                                        isNigerianBusiness ?
                                        "Your Full Name and Date Of Birth should match your bvn records"
                                        :
                                        "Your Personal and Business information must match your uploaded records"
                                    }
                                </p>
                                <TextField
                                    label="Business Name"
                                    name='businessName'
                                    type='text'
                                    placeholder="Business Name"
                                    required
                                />
                                <TextField
                                    label="Business Owner Full Name"
                                    name='ownersName'
                                    type='text'
                                    placeholder="Full Name"
                                    required
                                />
                                <DateField
                                    name='incorporationDate'
                                    label="Incorporation Date"
                                    required
                                    placeholder="DD-MM-YYYY"
                                    onChange={(value)=>{
                                        console.log(value)
                                        form.setFieldValue("incorporationDate", dateFormatter(value, 'yyyy-mm-dd'))
                                    }}
                                    value={form?.values?.incorporationDate}
                                />
                                {
                                    isNigerianBusiness && 
                                    <TextField
                                        label="Directors Bvn"
                                        name='directorsBVN'
                                        type='text'
                                        placeholder="Enter directors BVN"
                                        required
                                    />
                                }
                                <DateField
                                    name='dob'
                                    label="Owner's Date of Birth"
                                    required
                                    placeholder="DD-MM-YYYY"
                                    onChange={(value)=>{
                                        form.setFieldValue("dob", dateFormatter(value, 'yyyy-mm-dd'))
                                    }}
                                    value={form?.values?.dob}
                                />
                                <SelectField
                                    name='registrationType'
                                    options={regTypeOptions?.map(item=>({
                                        label: item,
                                        value: item
                                    }))}
                                    required
                                    label="Registration Type"
                                    onChange={(selected)=>{
                                        form.setFieldValue( "registrationType", selected.value )
                                    }}
                                    value={form?.values?.registrationType}
                                    placeholder="Registration Type"
                                />
                                <SelectField
                                    name='size'
                                    options={sizeOptions?.map(item=>({
                                        label: item,
                                        value: item
                                    }))}
                                    required
                                    label="Size"
                                    onChange={(selected)=>{
                                        form.setFieldValue( "size", selected.value )
                                    }}
                                    value={form?.values?.size}
                                    placeholder="Size"
                                />
                                <SelectField
                                    name='industry'
                                    options={industryOptions?.map(item=>({
                                        label: item,
                                        value: item
                                    }))}
                                    required
                                    label="Business Sector"
                                    onChange={(selected)=>{
                                        form.setFieldValue( "industry", selected.value )
                                    }}
                                    placeholder="Business Sector"
                                    value={form?.values?.industry}
                                />
                                <Button
                                    isValid={true}
                                    isLoading={false}
                                    type="submit"
                                    text="Save"
                                    className="mt-10 bg-cc-primary"
                                />
                            </form>
                        </FormikProvider>
                        :
                        <div>
                            <div className="mb-3">
                                <h6 className="text-[#5B5B5B] text-[1em]">Business Name</h6>
                                <p className="text-[#353535]">{form.values?.businessName}</p>
                            </div>
                            <div className="mb-3">
                                <h6 className="text-[#5B5B5B] text-[1em]">Business Owner Full Name</h6>
                                <p className="text-[#353535]">{form.values?.ownersName}</p>
                            </div>
                            <div className="mb-3">
                                <h6 className="text-[#5B5B5B] text-[1em]">Incorporation Date</h6>
                                <p className="text-[#353535]">{form.values?.incorporationDate}</p>
                            </div>
                            {
                                !!form.values?.directorsBVN &&
                                <div className="mb-3">
                                    <h6 className="text-[#5B5B5B] text-[1em]">Directors BVN</h6>
                                    <p className="text-[#353535]">{form.values?.directorsBVN}</p>
                                </div>
                            }
                            <div className="mb-3">
                                <h6 className="text-[#5B5B5B] text-[1em]">Owner's Date of Birth</h6>
                                <p className="text-[#353535]">{form.values?.dob}</p>
                            </div>
                            <div className="mb-3">
                                <h6 className="text-[#5B5B5B] text-[1em]">Registration Type</h6>
                                <p className="text-[#353535]">{form.values?.registrationType}</p>
                            </div>
                            <div className="mb-3">
                                <h6 className="text-[#5B5B5B] text-[1em]">Size</h6>
                                <p className="text-[#353535]">{form.values?.size}</p>
                            </div>
                            <div className="mb-6">
                                <h6 className="text-[#5B5B5B] text-[1em]">Industry</h6>
                                <p className="text-[#353535]">{form.values?.industry}</p>
                            </div>
                            <div className="grid grid-cols-2 gap-6">
                                <Button
                                    isValid={true}
                                    isLoading={false}
                                    type="button"
                                    text="Edit"
                                    className="bg-white !text-[#0C0C0C] !border-[1px] !border-[#D0D5DD]"
                                    onClick={()=>setIsForm(true)}
                                />
                                <Button
                                    isValid={true}
                                    isLoading={isLoading}
                                    type="button"
                                    text="Next"
                                    className="bg-cc-primary"
                                    onClick={()=>submitProfile()}
                                />
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}


export default Profile;